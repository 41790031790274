import { FC, MouseEvent, ReactNode, createElement } from "react";
import cl from "classnames";
import styles from "./Typography.module.scss";
interface ITypography {
  children: ReactNode;
  className?: string;
  id?: string;
  onMouseDown?: (e: MouseEvent<any>) => void;
  disabled?: boolean;
  includeMediaText?: boolean;
  as?: HTMLElement['tagName'];
}
export const H1: FC<ITypography> = ({ children, className, as }) => {
  if (as){
    return createElement(as, {className: cl(className, styles.h1), suppressHydrationWarning: true}, children)
  }
  return (
    <h1 className={cl(className, styles.h1)} suppressHydrationWarning>
      {children}
    </h1>
  );
};
export const H2: FC<ITypography> = ({ children, className, as }) => {
  if (as){
    return createElement(as, {className: cl(className, styles.h2), suppressHydrationWarning: true}, children)
  }
  return (
    <h2 className={cl(className, styles.h2)} suppressHydrationWarning>
      {children}
    </h2>
  );
};

export const H24: FC<ITypography> = ({ children, className }) => {
  return (
    <h4 className={cl(className, styles.h24)} suppressHydrationWarning>
      {children}
    </h4>
  );
};
export const H3: FC<ITypography> = ({ children, className, as }) => {
  if (as){
    return createElement(as, {className: cl(className, styles.h3), suppressHydrationWarning: true}, children)
  }
  return (
    <h3 className={cl(className, styles.h3)} suppressHydrationWarning>
      {children}
    </h3>
  );
};

export const H4: FC<ITypography> = ({ children, className, as }) => {
  if (as){
    return createElement(as, {className: cl(className, styles.h4), suppressHydrationWarning: true}, children)
  }
  return (
    <h4 className={cl(className, styles.h4)} suppressHydrationWarning>
      {children}
    </h4>
  );
};


export const P1: FC<ITypography> = ({ children, className }) => {
  return (
    <p className={cl(className, styles.p1)} suppressHydrationWarning>
      {children}
    </p>
  );
};
export const P1Text: FC<ITypography> = ({ children, className }) => {
  return <p className={cl(className, styles.p1text)}>{children}</p>;
};
export const P15: FC<ITypography> = ({ children, className }) => {
  return <p className={cl(className, styles.p15)}>{children}</p>;
};
export const P2: FC<ITypography> = ({
  children,
  className,
  id,
  disabled,
  includeMediaText = true,
}) => {
  return (
    <p
      id={id}
      className={cl(className, includeMediaText ? styles.p2 : styles["p2--nomedia"])}
      data-disabled={disabled}
      suppressHydrationWarning
    >
      {children}
    </p>
  );
};
export const P3: FC<ITypography> = ({ children, className, includeMediaText = true}) => {
  return <p className={cl(className, includeMediaText ? styles.p3 : styles["p3--nomedia"])} suppressHydrationWarning>{children}</p>;
};
export const P4: FC<ITypography> = ({ children, className }) => {
  return <p className={cl(className, styles.p4)}>{children}</p>;
};
export const P45: FC<ITypography> = ({ children, className }) => {
  return <p className={cl(className, styles.p45)}>{children}</p>;
};
