import {
  DetailedHTMLProps,
  FC,
  InputHTMLAttributes,
  forwardRef,
  useEffect,
  useState,
} from "react";
import cl from "classnames";
import styles from "./Input.module.scss";
import { ICurrency } from "@/lib/types/currency";
import { BahtSign, DollarSign, RubSign } from "../../icons/currency";

interface IPriceInput
  extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  currency: ICurrency["value"];
}

const currencyIcon = {
  usd: <DollarSign className={styles.price__icon} />,
  rub: <RubSign className={styles.price__icon} />,
  thb: <BahtSign className={styles.price__icon} />,
};
export const PriceInput = forwardRef<any, IPriceInput>(
  ({ currency = "usd", className, ...props }, ref) => {
    const [isClient, setIsClient] = useState(false);
    useEffect(() => {
      setIsClient(true);
    }, []);
    return (
      <div className={styles.price__wrapper} suppressHydrationWarning>
        <input
          ref={ref}
          name="q"
          className={cl(styles.price__input, className)}
          type="number"
          {...props}
          inputMode="numeric"
        />
        {isClient && currencyIcon[currency]}
      </div>
    );
  },
);

PriceInput.displayName = "PriceInput";

export default PriceInput;
