import cl from "classnames";
import styles from "./socials.module.scss";
import { FC, useId } from "react";
import { LinearGradient } from "./LinearGradient";
import { ISocialIcon } from "@/lib/types/social-icons";

export const SvgFBMessengerFill: FC<ISocialIcon> = ({ className, isGradient }) => {
  const id = useId();
  const fill = isGradient ? `url('#blueGradient${id}')` : "#848C98";

  return (
    <svg className={cl(styles.socials, className)} xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
        <LinearGradient id={id} />
        <rect width="48" height="48" rx="24" fill={fill}/>
        <rect x="7" y="7.00195" width="34" height="34" rx="17" fill={fill}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M12.6465 23.6613C12.6465 17.3346 17.6051 12.6484 24 12.6484C30.3948 12.6484 35.3534 17.3374 35.3534 23.6641C35.3534 29.9909 30.3948 34.677 24 34.677C22.8504 34.677 21.7491 34.5237 20.7131 34.2399C20.5116 34.186 20.2959 34.2002 20.1057 34.2853L17.8521 35.2787C17.2617 35.5399 16.5975 35.1198 16.5776 34.4755L16.5152 32.4546C16.5095 32.2048 16.396 31.972 16.2115 31.8074C14.0032 29.8319 12.6465 26.9708 12.6465 23.6613ZM20.5171 21.5921L17.182 26.8829C16.8613 27.3909 17.4857 27.9614 17.9625 27.5981L21.5446 24.879C21.7858 24.6945 22.1207 24.6945 22.3648 24.8761L25.0187 26.8658C25.8163 27.4619 26.9516 27.2547 27.4824 26.4117L30.8203 21.1238C31.1382 20.6157 30.5138 20.0424 30.0369 20.4057L26.4549 23.1249C26.2137 23.3094 25.8787 23.3094 25.6346 23.1277L22.9808 21.138C22.1832 20.5419 21.0478 20.7491 20.5171 21.5921Z" 
        fill="white"
        />
    </svg>
  );
};