import { useTranslation } from "next-i18next";
import { FieldError } from "react-hook-form";
import { getWordEnding } from "@/lib/utils/getWordEnding";

const useValidationErrorTranslation = () => {
  const {
    t: validationT,
    i18n: { language },
  } = useTranslation("index", { keyPrefix: "validation" });

  return (error: FieldError) => {
    if (!language) return;
    const symbolWords = [
      validationT("symbol"),
      validationT("symbols"),
      validationT("symbolMore"),
    ];
    if (error?.type === "minLength") {
      const minValue = error.message as string;
      const word = getWordEnding(+minValue, symbolWords, language as "ru" | "en");

      return `${validationT("minimum", { value: minValue })} ${word}`;
    } else if (error?.type === "maxLength") {
      const maxValue = error.message as string;
      const word = getWordEnding(+maxValue, symbolWords, language as "ru" | "en");

      return `${validationT("maximum", { value: maxValue })} ${word} `;
    }
    if (error?.type === "required") {
      if (error?.ref?.name === "date") {
        return validationT("errorRequiredDate");
      } else {
        return validationT("errorRequired");
      }
    }
    return validationT(error?.message || "");
  };
};

export default useValidationErrorTranslation;
