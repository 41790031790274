import React from "react";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import styles from "./FeedBackModalNew.module.scss";
import { RequestCallNew } from "@/lib/ui/components/Forms/RequestCallNew";
import { H4, P2 } from "@/lib/ui/components/Typography";
import { PDF } from "../../../types/pdf";
import { Messengers } from "../Messengers";
import { LeadType } from "../../../types/lead-type";

interface Props {
  title?: string;
  subTitle?: string;
  agreementText?: string;
  buttonLabel?: string;
  leadType?: LeadType;
  url?: string;
  comment?: string;
  sourceForm?: string;
  pdf?: PDF;
  eventName: string[];
  eventAction?: string;
  messengerText?: string;
  excludeMessengerText?: boolean
}

export const FeedBackModalNew = ({
  title = "getExpert",
  subTitle = "getRequest",
  agreementText = "byClickingTheSendRequest",
  buttonLabel = "requestACall",
  leadType = "NOT_SPECIFIED",
  messengerText = "inMessengers",
  url,
  sourceForm,
  comment,
  pdf,
  eventName,
  excludeMessengerText = false,
  eventAction
}: Props) => {
  const { t } = useTranslation("index", { keyPrefix: "form" });
  return (
    <div className={styles.feedBackModal}>
      <div className={styles.feedBackModal__leaf}>
        <Image
          sizes="494px"
          src={"/leaves/feedbackLeaf.webp"}
          alt={""}
          className={styles.feedBackModal__leafImg}
          fill
        />
      </div>
      <H4 className={styles.feedBackModal__title}>
            <span>{t(`titles.${title}`)}</span>
            {!excludeMessengerText && <span>{t(messengerText)}</span>}
        </H4>
      <div className={styles.feedBackModal__content}>
        <Messengers className={styles.feedBackModal__messengers} />
        <P2 className={styles.feedBackModal__text}>{t(subTitle)}</P2>
        <RequestCallNew
            theme="white"
            sourceForm={sourceForm}
            className={styles.feedBackModal__form}
            agreementText={agreementText}
            buttonLabel={buttonLabel}
            leadType={leadType}
            url={url}
            comment={comment}
            pdf={pdf}
            eventName={eventName}
            eventAction={eventAction}
            buttonClass={styles.feedBackModal__button}
            isFeedBackModal
        />
      </div>
    </div>
  );
};
