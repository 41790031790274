import { FC, ReactNode } from "react";
import cl from "classnames";
import styles from "./SettingsButton.module.scss";
import { SettingsIcon } from "../../../icons";
import { IClassname } from "@/lib/types/classname";

interface ISettingsButton extends IClassname {
  onClick: () => void;
  hasDot?: boolean;
  replacementIcon?: ReactNode;
}
export const SettingsButton: FC<ISettingsButton> = ({
  hasDot,
  onClick,
  className,
  replacementIcon,
}) => {
  return (
    <button
      name="search button"
      aria-label="search button"
      className={cl(styles.button, hasDot && styles["button--dot"], className)}
      onClick={onClick}
    >
      {replacementIcon || <SettingsIcon />}
    </button>
  );
};
