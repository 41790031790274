import { contentTypes, regexList } from "../types/forms/common";

export const validations: {
  [key in contentTypes]?: any;
} = {
  name: {
    minLength: {
      value: 2,
      message: 2,
    },
    maxLength: {
      value: 40,
      message: 40,
    },
    pattern: {
      value: regexList.name,
      message: "errorName",
    },
  },
  phone: {
    minLength: {
      value: 4,
      message: 4,
    },
    maxLength: {
      value: 20,
      message: 20,
    },
    pattern: {
      value: regexList.phone,
      message: "errorPhone",
    },
  },
  email: {
    pattern: {
      value: regexList.email,
      message: "errorEmail",
    },
  },
  question: {
    minLength: {
      value: 4,
      message: 4,
    },
    maxLength: {
      value: 500,
      message: 500,
    },
  },
};

export const validateRequired = (validations: object) => ({
  ...validations,
  required: {
    value: true,
    message: "errorRequired",
  },
});
