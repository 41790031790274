export type DropMenuItem = {
  label: string;
  path: string;
  key: string;
  type: "old" | "new";
}

export type IMobileMenuItem = {
  text: string;
  link?: string;
  isNavSales?: boolean;
  isNavService?: boolean;
  isNavAboutCompany?: boolean;
  subItems?: DropMenuItem[];
}

export const oldUrl = "https://villacarte.com"

export const dropMenuItemsSale: DropMenuItem[] = [
  { label: "propertyForSale", path: "/sale/catalog", key: "1", type: "new" },
  { label: "hotelsForSale", path: "/hotel", key: "2", type: "old" },
  { label: "saleOfLand", path: "/land", key: "3", type: "old" },
];

export const dropMenuItemsService: DropMenuItem[] = [
  { label: "serviceCatalog", path: "/concierge", key: "1", type: "old" },
  { label: "rentACar", path: "/car", key: "2", type: "old" },
  { label: "yachtCharter", path: "/yacht", key: "3", type: "old" },
  { label: "entertainment", path: "/entertainment", key: "4", type: "old" },
];

export const dropMenuItemsAbout: DropMenuItem[] = [
  { label: "aboutTheCompany", path: "/about", key: "1", type: "new" },
  { label: "contacts", path: "/contacts", key: "2", type: "new" },
  { label: "blog", path: "/blog", key: "3", type: "old" },
  { label: "clubVillaCarte", path: "/company/loyalty-program", key: "4", type: "old" },
];
export const mobileMenuNav: IMobileMenuItem[] = [
  {
    text: "sale",
    isNavSales: true,
    subItems: dropMenuItemsSale,
  },
  {
    text: "rent",
    link: "/rent",
  },
  {
    text: "concierge",
    isNavService: true,
    subItems: dropMenuItemsService,
  },
  {
    text: "propertyManagement",
    link: "/propertymanagement",
  },
  {
    text: "aboutTheCompany",
    isNavAboutCompany: true,
    subItems: dropMenuItemsAbout,
  },
];
