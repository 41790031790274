import cl from "classnames";
import styles from "./socials.module.scss";
import { ISocialIcon } from "@/lib/types/social-icons";
import { FC, useId } from "react";
import { LinearGradient } from "./LinearGradient";

export const SvgWhatsapp: FC<ISocialIcon> = ({ className, isGradient }) => {
  const id = useId();

  const fill = isGradient ? `url('#blueGradient${id}')` : "#848C98";

  return (
    <svg
      className={cl(styles.socials, className)}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <LinearGradient id={id} />
      <rect width="20" height="20" rx="10" fill={fill} />
      <g clipPath={`url(#clip0_113_15090${id})`}>
        <path
          d="M5 14.5833L5.70678 12.0168C5.26982 11.2634 5.04059 10.4103 5.04298 9.53816C5.04298 6.80527 7.27794 4.58331 10.0215 4.58331C11.3539 4.58331 12.6051 5.099 13.5435 6.03531C14.4842 6.97162 15.0024 8.21687 15 9.54054C15 12.2734 12.765 14.4954 10.0191 14.4954H10.0167C9.18338 14.4954 8.36437 14.2863 7.6361 13.8918L5 14.5833ZM7.76265 12.9959L7.91308 13.0862C8.54823 13.4616 9.2765 13.6589 10.0191 13.6613H10.0215C12.3018 13.6613 14.1595 11.8148 14.1595 9.54291C14.1595 8.44263 13.7297 7.40888 12.9489 6.62942C12.1681 5.84995 11.127 5.42219 10.0215 5.42219C7.74116 5.41982 5.88348 7.2663 5.88348 9.53816C5.88348 10.3153 6.10076 11.0733 6.51624 11.7292L6.61413 11.8861L6.19627 13.4046L7.76265 12.9959Z"
          fill="white"
        />
        <path
          d="M5.17383 14.4098L5.85673 11.9312C5.4341 11.2064 5.21203 10.3818 5.21203 9.54052C5.21442 6.90269 7.37058 4.75677 10.021 4.75677C11.308 4.75677 12.5138 5.25582 13.4212 6.15887C14.3285 7.06191 14.8276 8.26438 14.8276 9.5429C14.8276 12.1807 12.669 14.3266 10.021 14.3266H10.0186C9.21394 14.3266 8.42359 14.1246 7.72158 13.7444L5.17383 14.4098Z"
          fill={fill}
        />
        <path
          d="M5 14.5833L5.70678 12.0168C5.26982 11.2634 5.04059 10.4103 5.04298 9.53816C5.04298 6.80527 7.27794 4.58331 10.0215 4.58331C11.3539 4.58331 12.6051 5.099 13.5435 6.03531C14.4842 6.97162 15.0024 8.21687 15 9.54054C15 12.2734 12.765 14.4954 10.0191 14.4954H10.0167C9.18338 14.4954 8.36437 14.2863 7.6361 13.8918L5 14.5833ZM7.76265 12.9959L7.91308 13.0862C8.54823 13.4616 9.2765 13.6589 10.0191 13.6613H10.0215C12.3018 13.6613 14.1595 11.8148 14.1595 9.54291C14.1595 8.44263 13.7297 7.40888 12.9489 6.62942C12.1681 5.84995 11.127 5.42219 10.0215 5.42219C7.74116 5.41982 5.88348 7.2663 5.88348 9.53816C5.88348 10.3153 6.10076 11.0733 6.51624 11.7292L6.61413 11.8861L6.19627 13.4046L7.76265 12.9959Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.77681 7.46589C8.68369 7.25914 8.58579 7.25439 8.49744 7.25201C8.42581 7.24963 8.34223 7.24963 8.25866 7.24963C8.17509 7.24963 8.04138 7.28053 7.92676 7.4041C7.81215 7.52768 7.49219 7.82711 7.49219 8.43785C7.49219 9.04621 7.9387 9.63557 8.00078 9.71874C8.06287 9.80192 8.86277 11.0923 10.1259 11.589C11.1765 12.0025 11.3914 11.9193 11.6183 11.8979C11.8451 11.8765 12.3537 11.5985 12.4588 11.3086C12.5614 11.0186 12.5614 10.7715 12.5304 10.7192C12.4993 10.6669 12.4158 10.636 12.2916 10.5743C12.1674 10.5125 11.5562 10.213 11.4416 10.1703C11.327 10.1299 11.2434 10.1085 11.1622 10.232C11.0786 10.3556 10.8398 10.6337 10.7682 10.7168C10.6966 10.8 10.6226 10.8095 10.4984 10.7477C10.3742 10.6859 9.97308 10.5552 9.49792 10.1322C9.12781 9.80429 8.8771 9.39792 8.80546 9.27435C8.73383 9.15077 8.7983 9.08423 8.86038 9.02245C8.9153 8.96779 8.98455 8.87749 9.04663 8.80619C9.10871 8.7349 9.1302 8.68262 9.17079 8.59944C9.21138 8.51627 9.19228 8.44498 9.16124 8.38319C9.1302 8.32378 8.88665 7.71066 8.77681 7.46589Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id={"clip0_113_15090" + id}>
          <rect width="10" height="10" fill="white" transform="translate(5 4.58331)" />
        </clipPath>
      </defs>
    </svg>
  );
};
