import { FC } from "react";
import { IClassname } from "@/lib/types/classname";

export const SvgPenthhouse: FC<IClassname> = ({ className }) => {
  return (
    <svg className={className} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M27.7918 25.3333V14.7931H6.53168V25.3333" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M27.7919 14.7931V7.33334H14.3644V14.7931" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M6.53169 14.7931H4.66669V10.6902H14.3644" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M14.3643 7.33334H9.70197" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M7.83722 10.6902V14.7931" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M11.3804 10.6902V14.7931" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M19.3334 9.33334H16.6667V12.6667H19.3334V9.33334Z" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M24.6667 9.33334H22V12.6667H24.6667V9.33334Z" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M11.567 17.031H8.95605V20.7609H11.567V17.031Z" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M18.4672 17.031H15.8563V20.7609H18.4672V17.031Z" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M25.3675 17.031H22.7566V20.7609H25.3675V17.031Z" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

  );
};
