import React, { ReactNode, forwardRef, useId } from "react";
import Select, { InputProps, OptionProps, components } from "react-select";
import cn from "classnames";
import { IValueLabel } from "@villa/types/mainPage";
import styles from "@/lib/ui/components/Selects/CustomSelect.module.scss";
import { Checkbox } from "../Input";

export interface ICustomSelect {
  options: IValueLabel[];
  theme?: "dark" | "light";
  className?: string;
  onChange?: any;
  defaultValue?: unknown | IValueLabel[];
  value?: unknown | IValueLabel[];
  menuStyles?: string;
  isMulti?: boolean;
  placeholder?: string;
  menuIsOpen?: boolean;
  shouldHaveIndicator?: boolean;
  size?: "md" | "lg" | "sm";
  disableMulti?: boolean;
  isMobile?: boolean;
  label?: string;
  isMenuSelect?: boolean;
}

export const CustomSelect = forwardRef<any, ICustomSelect>(
  (
    {
      options,
      className,
      theme,
      onChange,
      defaultValue,
      menuStyles,
      isMulti,
      value,
      menuIsOpen,
      shouldHaveIndicator,
      placeholder,
      size,
      disableMulti,
      isMobile,
      label,
      isMenuSelect,
    },
    ref,
  ) => {
    const multiOptions = isMulti
      ? {
          isMulti: !disableMulti,
          controlShouldRenderValue: false,
          hideSelectedOptions: false,
          isClearable: false,
          backspaceRemovesValue: false,
          closeMenuOnSelect: false,
          // blurInputOnSelect: false,
          components: {
            Option: (props: OptionProps) => (
              <InputOption
                {...props}
                themeColor={theme}
                size={size === "md" || size === "lg" ? size : undefined}
              />
            ),
          },
        }
      : {
          components: {
            ...(isMenuSelect && {
              SingleValue: ({ children }: { children: ReactNode }) => {
                return (
                  <div
                    className={cn(styles.singleValue, "css-1dimb5e-singleValue")}
                    suppressHydrationWarning
                  >
                    {children}
                  </div>
                );
              },
            }),
          },
        };
    return (
      <Select
        id={label}
        ref={ref}
        className={className}
        instanceId={useId()}
        aria-label={label}
        options={options}
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        {...multiOptions}
        menuIsOpen={menuIsOpen}
       /*  components={{
          SingleValue: ({ children, isMulti }) => {
            if (!isMulti)
              return (
                <div
                  className={cn(styles.singleValue, "css-1dimb5e-singleValue")}
                  suppressHydrationWarning
                >
                  {children}
                </div>
              );
          },
        }} */
        classNames={{
          control: () => cn(styles.control, !isMulti && styles[`theme-${theme}`]),
          dropdownIndicator: (props) =>
            cn(
              styles.dropdownIndicator,
              props.selectProps.menuIsOpen && styles.open,
              isMulti && theme === "light" && styles["dropdown__indicator--light"],
            ),
          indicatorSeparator: () => styles.indicatorSeparator,
          valueContainer: () =>
            cn(styles.valueContainer, isMulti && styles.dropdown__valueContainer),
          placeholder: () =>
            cn(
              styles.placeholder,
              isMulti && styles.dropdown__placeholder,
              isMulti && styles[`dropdown__placeholder--${theme}`],
              styles[`dropdown__placeholder--${size}`],
            ),
          singleValue: () => styles.singleValue,
          menu: () =>
            cn(
              isMobile ? styles.mobileMenu : styles.menu,
              menuStyles,
              isMulti && styles.dropdown__menu,
              isMulti && styles[`dropdown__menu--${theme}`],
            ),
          container: (state) =>
            cn(
              isMulti && styles.dropdown__container,
              isMulti &&
                state.hasValue &&
                shouldHaveIndicator &&
                styles["dropdown__container--selected"],
              isMulti && styles[`dropdown__container--${theme}`],
              isMulti &&
                theme === "dark" &&
                state.isFocused &&
                styles["dropdown__container--open"],
              styles[`dropdown__container--${size}`],
            ),
          option: (state) =>
            cn(
              isMobile ? styles.mobileMenuOption : styles.option,
              state.isSelected && styles.selected,
              isMulti && styles.dropdown__option,
              state.isDisabled && styles["dropdown__option--disabled"],
              theme === "light" && styles["mobileMenuOption--light"],
            ),
          menuList: () => cn(isMulti && styles.dropdown__menuList),
        }}
      />
    );
  },
);
CustomSelect.displayName = "CustomSelect";

export default CustomSelect;
const InputOption = ({
  getStyles,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  themeColor,
  size,
  ...rest
}: OptionProps & { themeColor?: "dark" | "light"; size?: "lg" | "md" }) => {
  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={innerProps}
    >
      <Checkbox
        isChecked={isSelected}
        disableInteraction
        label={children}
        theme={themeColor}
        size={size}
        includeMediaText={false}
      />
    </components.Option>
  );
};
