import { FC } from 'react';
import { IClassname } from '@/lib/types/classname';

export const SvgBigFamily: FC<IClassname> = ({ className }) => {
    return (
        <svg className={className} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.5216 15.7607C16.4598 15.8359 16.3839 15.8962 16.2991 15.9374C16.2143 15.9787 16.1225 16 16.0295 16C15.9366 16 15.8447 15.9787 15.7599 15.9374C15.6751 15.8962 15.5993 15.8359 15.5374 15.7607L12.5849 12.5553C12.2897 12.2536 12.0935 11.8555 12.026 11.4215C11.9585 10.9876 12.0234 10.5416 12.2109 10.1513C12.3648 9.85343 12.5792 9.59739 12.8376 9.40257C13.0961 9.20776 13.392 9.07925 13.7029 9.02677C14.0138 8.97428 14.3317 8.99919 14.6324 9.09961C14.9332 9.20004 15.209 9.37334 15.439 9.60644L16 10.1834L16.561 9.60644C16.791 9.37334 17.0668 9.20004 17.3676 9.09961C17.6683 8.99919 17.9862 8.97428 18.2971 9.02677C18.608 9.07925 18.9039 9.20776 19.1624 9.40257C19.4208 9.59739 19.6352 9.85343 19.7891 10.1513C19.9766 10.5416 20.0415 10.9876 19.974 11.4215C19.9065 11.8555 19.7103 12.2536 19.4151 12.5553L16.5216 15.7607Z" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M4 14V12.7366C4.00359 12.5705 4.04612 12.4075 4.12411 12.2611C4.2021 12.1147 4.31332 11.9891 4.4487 11.8944L15.4052 4.18777C15.5799 4.06551 15.7874 4 16 4C16.2126 4 16.4201 4.06551 16.5948 4.18777L27.5513 11.8944C27.6895 11.9911 27.8025 12.1202 27.8806 12.2705C27.9588 12.4208 27.9997 12.5879 28 12.7577V14" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M6 17.5C6 17.9596 6.09053 18.4148 6.26642 18.8394C6.44231 19.264 6.70012 19.6499 7.02513 19.9749C7.35013 20.2999 7.73597 20.5577 8.16061 20.7336C8.58525 20.9095 9.04037 21 9.5 21C9.95963 21 10.4148 20.9095 10.8394 20.7336C11.264 20.5577 11.6499 20.2999 11.9749 19.9749C12.2999 19.6499 12.5577 19.264 12.7336 18.8394C12.9095 18.4148 13 17.9596 13 17.5C13 17.0404 12.9095 16.5852 12.7336 16.1606C12.5577 15.736 12.2999 15.3501 11.9749 15.0251C11.6499 14.7001 11.264 14.4423 10.8394 14.2664C10.4148 14.0905 9.95963 14 9.5 14C9.04037 14 8.58525 14.0905 8.16061 14.2664C7.73597 14.4423 7.35013 14.7001 7.02513 15.0251C6.70012 15.3501 6.44231 15.736 6.26642 16.1606C6.09053 16.5852 6 17.0404 6 17.5Z" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M6 15C6.5021 15.6363 7.10179 16.1411 7.76363 16.4847C8.42547 16.8282 9.13603 17.0034 9.85331 17C10.5915 17.0031 11.3223 16.817 12 16.4533" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M28 21C26.4109 20.53 25.9595 18.6902 25.9889 17.9295V17.0463C25.9889 16.2384 25.6272 15.4635 24.9834 14.8922C24.3396 14.321 23.4664 14 22.556 14C21.6455 14 20.7723 14.321 20.1285 14.8922C19.4847 15.4635 19.123 16.2384 19.123 17.0463L19 18C19 19 18.5817 19.1742 18.5 19.5" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M20 17C20.5697 16.9994 21.1325 16.9098 21.6501 16.7372C22.1678 16.5647 22.6282 16.3132 23 16C23.3718 16.3132 23.8322 16.5647 24.3499 16.7372C24.8675 16.9098 25.4303 16.9994 26 17" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M19 18C19.0756 18.8186 19.4771 19.5809 20.1251 20.1364C20.7731 20.6918 21.6206 21 22.5 21C23.3794 21 24.2269 20.6918 24.8749 20.1364C25.5229 19.5809 25.9244 18.8186 26 18" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M11 22.43C10.3693 22.1528 9.6889 22.0065 9 22C7.67392 22 6.40215 22.5268 5.46447 23.4645C4.52678 24.4021 4 25.6739 4 27" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M21 22.43C21.6307 22.1528 22.3111 22.0065 23 22C24.3261 22 25.5979 22.5268 26.5355 23.4645C27.4732 24.4021 28 25.6739 28 27" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M14 23C14 23.5304 14.2107 24.0391 14.5858 24.4142C14.9609 24.7893 15.4696 25 16 25C16.5304 25 17.0391 24.7893 17.4142 24.4142C17.7893 24.0391 18 23.5304 18 23C18 22.4696 17.7893 21.9609 17.4142 21.5858C17.0391 21.2107 16.5304 21 16 21C15.4696 21 14.9609 21.2107 14.5858 21.5858C14.2107 21.9609 14 22.4696 14 23Z" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M12 28C12.3699 27.3993 12.9403 26.8939 13.647 26.5405C14.3537 26.1872 15.1686 26 16 26C16.8314 26 17.6463 26.1872 18.353 26.5405C19.0597 26.8939 19.6301 27.3993 20 28" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
)}
