import { IClassname } from "@/lib/types/classname";
import { FC, useId } from "react";
import { LinearGradient } from "./socials/LinearGradient";
import cl from "classnames";
import styles from "./icons.module.scss";

export const NextArrowIcon: FC<
  IClassname & { isGradient?: boolean; isUpsideDown?: boolean }
> = ({ className, isGradient, isUpsideDown }) => {
  const id = useId();
  const stroke = isGradient ? `url('#blueGradient${id}')` : "currentColor";
  return (
    <svg
      className={cl(className, isUpsideDown && styles.rotate)}
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
    >
      <LinearGradient id={id} />
      <path
        d="M14.4 8C14.4 8.52966 14.9132 9.32114 15.4322 9.98616C16.1004 10.8424 16.8982 11.5906 17.8133 12.1616C18.4986 12.5891 19.3311 13 20 13M20 13C19.3311 13 18.4986 13.4109 17.8133 13.8384C16.8982 14.4094 16.1004 15.1576 15.4322 16.0139C14.9132 16.6789 14.4 17.4704 14.4 18M20 13H6"
        stroke={stroke}
        strokeWidth="1.6"
      />
    </svg>
  );
};
