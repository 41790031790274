import { FC } from "react";
import { IClassname } from "@/lib/types/classname";

export const SvgSwimming: FC<IClassname> = ({ className }) => {
  return (
    <svg
      className={className}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 41C8.12717 41 9.20817 40.5603 10.0052 39.7775C10.8022 38.9947 11.25 37.9331 11.25 36.8261C11.25 37.9331 11.6978 38.9947 12.4948 39.7775C13.2918 40.5603 14.3728 41 15.5 41C16.6272 41 17.7082 40.5603 18.5052 39.7775C19.3022 38.9947 19.75 37.9331 19.75 36.8261C19.75 37.9331 20.1978 38.9947 20.9948 39.7775C21.7918 40.5603 22.8728 41 24 41C25.1272 41 26.2082 40.5603 27.0052 39.7775C27.8022 38.9947 28.25 37.9331 28.25 36.8261C28.25 37.9331 28.6978 38.9947 29.4948 39.7775C30.2918 40.5603 31.3728 41 32.5 41C33.6272 41 34.7082 40.5603 35.5052 39.7775C36.3022 38.9947 36.75 37.9331 36.75 36.8261C36.75 37.9331 37.1978 38.9947 37.9948 39.7775C38.7918 40.5603 39.8728 41 41 41M11.25 12.4783V11.7826C11.25 11.0446 11.5485 10.3368 12.0799 9.81501C12.6112 9.29317 13.3319 9 14.0833 9C14.8348 9 15.5554 9.29317 16.0868 9.81501C16.6182 10.3368 16.9167 11.0446 16.9167 11.7826V25.6957C16.9167 28.1819 17.4833 31.2247 19.1196 32.3405C19.5049 32.6035 19.9894 32.6522 20.4583 32.6522M25.4167 12.4783V11.7826C25.4167 11.0446 25.7152 10.3368 26.2465 9.81501C26.7779 9.29317 27.4986 9 28.25 9C29.0014 9 29.7221 9.29317 30.2535 9.81501C30.7848 10.3368 31.0833 11.0446 31.0833 11.7826V25.6957C31.0833 28.1819 31.65 31.2247 33.2862 32.3405C33.6716 32.6035 34.1561 32.6522 34.625 32.6522M16.9167 21.5217H31.0833M16.9167 15.9565H31.0833M16.9747 27.087H31.1414"
        stroke="currentColor"
        strokeWidth="2"
      />
    </svg>
  );
};
