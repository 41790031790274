import React, { ReactNode } from "react";
import Link, { LinkProps } from "next/link";
import css from "./Button.module.scss";
import cl from "classnames";

// dark - темная, ховер - белый с темным бордером, есть disabled
// dark-invert - белый с темным бордером, ховер- темная, есть disabled
// light - белая, ховер - матовая с белым бордером, есть disabled,
// light-invert - матовая с белым бордером, ховер - белая, есть disabled,
// outlined - темная без бордера, ховер - подчеркивание, есть disabledб
// outlined-invert - белая без бордера, ховер - подчеркивание, есть disabled
// grayBorder - серый бордер + белый фон, ховер - темный бордер
// secondary - серая без бордера, ховер - серый бордер + белый фон, нет disabled

export type ButtonTheme = "dark" |  "dark-invert" | "light" | "light-invert" | "outlined" | "outlined-invert" | "grayBorder" | "secondary"

interface ButtonProps<T extends "a" | "button" | typeof Link> {
  as: T;
  label: string;
  theme: ButtonTheme;
  icon?: ReactNode;
  isIconLeft?: boolean;
  disabled?: boolean;
  size: "md" | "lg";
  className?: string;
  children?: ReactNode;
  target?: HTMLAnchorElement['target'];
  shouldHaveIndicator?: boolean;
}

export function Button<T extends "a" | "button" | typeof Link>({
  as: Component,
  label,
  theme,
  icon,
  disabled,
  size,
  isIconLeft,
  className,
  children,
  shouldHaveIndicator,
  ...props
}: ButtonProps<T> &
  Omit<
    T extends "a" | "button" ? JSX.IntrinsicElements[T] : LinkProps,
    keyof ButtonProps<T>
  >) {    
  return (
    // @ts-ignore
    <Component
      {...props}
      {...(Component === "button" ? { disabled } : {})}
      className={cl(
        css.button,
        size === "md" ? css.buttonMedium : css.buttonLarge,
        (theme === "outlined" || theme === "outlined-invert") && css.buttonOutlined,
        theme === "outlined" && css.buttonOutlinedDark,
        theme === "outlined-invert" && css.buttonOutlinedWhite,
        theme === "dark" && css.darkToWhiteButton,
        theme === "dark-invert" && css.whiteToDarkButton,
        theme === "light" && css.lightToWhiteButton,
        theme === "light-invert" && css.whiteToLightButton,
        theme === "grayBorder" && css.grayBorder,
        theme === "secondary" && css.buttonSecondary,
        disabled && css.disabledButton,
        icon && css.buttonWithIcon,
        className
      )}
    >
      {shouldHaveIndicator && <div className={css.indicator}/>}
      {icon && isIconLeft && <div suppressHydrationWarning className={cl(css.buttonIcon, css.buttonIcon_left)}>{icon}</div>}
      {label && <p suppressHydrationWarning>{label}</p>}
      {children}
      {icon && !isIconLeft && <div suppressHydrationWarning className={cl(css.buttonIcon, css.buttonIcon_right)}>{icon}</div>}
    </Component>
  );
}

export default Button;
