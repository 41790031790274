import { FC } from "react";
import { IClassname } from "@/lib/types/classname";

export const SvgOneBedroom: FC<IClassname> = ({ className }) => {
  return (
    <svg className={className} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22.5 28.0002H4.5V9.80664H20.7581" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M28.5002 24.129V7.87097C28.5002 5.73323 26.767 4 24.6292 4H23.0808V20.2581H24.6292C26.767 20.2581 28.5002 21.9913 28.5002 24.129ZM28.5002 24.129C28.5002 26.2668 26.767 28 24.6292 28H21.9196" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M10.3066 16.0001H8.37109V24.1291H23.5066C24.2256 24.1291 24.9146 23.8436 25.4227 23.3356L25.7904 22.9678" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M20.7584 13.6774H14.9519V16H13.0164" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M14.9515 20.2583V24.1293" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M17.6609 17.5485H20.7577" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M8.37109 20.2583H12.6292" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M14.9515 16.0001V17.5485" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

  );
};
