import { ReactNode } from "react";
import Link from "next/link";
import styles from "./OurMessengers.module.scss";
import cl from "classnames";
import { P2 } from "@/lib/ui/components/Typography";
import { sendMessClick } from "@/lib/utils/sendGTM";

interface IProps {
  url: string;
  icon: ReactNode;
  text: string;
  hasIconPadding?: boolean;
}

const MessengerCard = ({ url, icon, text, hasIconPadding = true }: IProps) => {
  return (
    <div>
      <Link href={url} className={styles.messenger__card} target="_blank" onClick={sendMessClick}>
        <span className={cl({ [styles.messenger__iconWrapper]: hasIconPadding })}>
          {icon}
        </span>
        <P2>{text}</P2>
      </Link>
    </div>
  );
};

export default MessengerCard;
