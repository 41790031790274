import { IClassname } from "@/lib/types/classname";
import { FC, useId } from "react";
import { LinearGradient } from "./socials/LinearGradient";

export const CurrencyIcon: FC<IClassname & {isGradient?: boolean, isDark?: boolean}> = ({className, isGradient, isDark}) => {
  const id = useId();
  const stroke = isGradient ? `url('#blueGradient${id}')` : "currentColor";
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
        {isGradient && <LinearGradient id={id} isDark={isDark} />}
        <g id="Currency Euro Dollar Exchange">
        <path id="Vector" d="M19.1381 18.4316C19.1381 21.1982 20.2372 23.8515 22.1935 25.8078C24.1498 27.7641 26.8031 28.8631 29.5697 28.8631C32.3363 28.8631 34.9896 27.7641 36.9459 25.8078C38.9022 23.8515 40.0013 21.1982 40.0013 18.4316C40.0013 15.6649 38.9022 13.0116 36.9459 11.0553C34.9896 9.09904 32.3363 8 29.5697 8C26.8031 8 24.1498 9.09904 22.1935 11.0553C20.2372 13.0116 19.1381 15.6649 19.1381 18.4316Z" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_2" d="M19.1741 19.1632C17.0531 19.0124 14.9367 19.5133 13.1083 20.5989C11.28 21.6845 9.82707 23.303 8.94421 25.2374C8.06135 27.1718 7.79072 29.3298 8.16859 31.4223C8.54645 33.5148 9.55476 35.4419 11.0584 36.9453C12.5621 38.4488 14.4893 39.4568 16.5819 39.8343C18.6744 40.2119 20.8324 39.941 22.7667 39.0578C24.7009 38.1747 26.3192 36.7215 27.4045 34.893C28.4898 33.0645 28.9905 30.948 28.8393 28.8271" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_3" d="M8.67999 14.3077L11.4701 17.0797L14.2435 14.291" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_4" d="M18.3996 8.71338C17.4863 8.71611 16.5825 8.89871 15.7398 9.25073C14.8971 9.60275 14.132 10.1173 13.4882 10.765C12.8444 11.4127 12.3344 12.1809 11.9874 13.0257C11.6405 13.8705 11.4633 14.7754 11.4661 15.6886V17.0795" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_5" d="M39.3322 33.6824L36.5421 30.9104L33.7686 33.6991" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_6" d="M29.6129 39.2765C30.5262 39.2738 31.4299 39.0912 32.2726 38.7392C33.1153 38.3872 33.8805 37.8726 34.5243 37.2249C35.1681 36.5772 35.6781 35.809 36.025 34.9642C36.372 34.1194 36.5492 33.2145 36.5464 32.3013V30.9104" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_7" d="M19.1325 34.4266C18.5688 34.4917 17.9976 34.4287 17.4617 34.242C16.9258 34.0554 16.439 33.75 16.0377 33.3487C15.6364 32.9474 15.3311 32.4607 15.1444 31.9247C14.9578 31.3888 14.8947 30.8177 14.9599 30.2539C14.8947 29.6902 14.9578 29.119 15.1444 28.5831C15.3311 28.0472 15.6364 27.5604 16.0377 27.1592C16.439 26.7579 16.9258 26.4525 17.4617 26.2659C17.9976 26.0792 18.5688 26.0161 19.1325 26.0813" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_8" d="M13.5746 28.863H19.1382" stroke="whicurrentColorte" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_9" d="M13.5746 31.6448H17.7473" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_10" d="M31.6504 13.5635H29.2859C28.8955 13.5605 28.5146 13.684 28.2002 13.9154C27.8857 14.1468 27.6547 14.4738 27.5415 14.8475C27.4283 15.2211 27.4391 15.6214 27.5723 15.9884C27.7055 16.3554 27.9538 16.6694 28.2803 16.8836L30.8395 18.5888C31.1654 18.8025 31.4134 19.1158 31.5468 19.482C31.6801 19.8481 31.6916 20.2476 31.5795 20.6208C31.4674 20.994 31.2377 21.3211 30.9246 21.5531C30.6116 21.7852 30.2319 21.91 29.8423 21.9088H27.4778" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_11" d="M30.2651 13.5635V12.1726" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_12" d="M28.8742 23.2996V21.9087" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
    </svg>
  );
};
