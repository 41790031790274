import { FC } from 'react';
import { IClassname } from '@/lib/types/classname';

export const SvgTerrace: FC<IClassname> = ({ className }) => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.38522 13.4785L9.45504 20.9659C9.64658 22.3083 9.74276 23.6625 9.74286 25.0184C9.74268 27.2742 9.49594 29.5231 9.00702 31.7252L8.38522 34.5219" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M9.74377 25.0176H16.532C16.892 25.0176 17.2374 25.1606 17.492 25.4152C17.7466 25.6698 17.8896 26.0151 17.8896 26.3752V34.5211" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M23.999 33.8429V20.9453" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M15.8531 20.9453H32.1448" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M20.604 34.5215H27.3922" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M38.2542 25.0176H31.466C31.1059 25.0176 30.7606 25.1606 30.506 25.4152C30.2514 25.6698 30.1084 26.0151 30.1084 26.3752V34.5211" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M39.6119 13.4785L38.542 20.9659C38.3505 22.3083 38.2543 23.6625 38.2542 25.0184C38.2544 27.2742 38.5011 29.5231 38.9901 31.7252L39.6119 34.5219" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
)}
