import { FC } from "react";
import { IClassname } from "@/lib/types/classname";

interface ISearchIcon extends IClassname {
  onClick?: () => void;
}
export const SearchIcon: FC<ISearchIcon> = ({ className, onClick }) => {
  return (
    <svg
      onClick={onClick}
      className={className}
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 17L13.5714 13.5714M10.1429 15.2857C7.30254 15.2857 5 12.9832 5 10.1429C5 7.30254 7.30254 5 10.1429 5C12.9832 5 15.2857 7.30254 15.2857 10.1429C15.2857 12.9832 12.9832 15.2857 10.1429 15.2857Z"
        stroke="currentColor"
        strokeWidth="1.6"
      />
    </svg>
  );
};
