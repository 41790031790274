import { FC } from "react";
import { IClassname } from "@/lib/types/classname";

export const ComfortIcon: FC<IClassname> = ({ className }) => {
  return (
    <svg
      className={className}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.8571 18.2953C34.5993 18.2953 36.0116 16.883 36.0116 15.1408C36.0116 13.3986 34.5993 11.9863 32.8571 11.9863C31.1149 11.9863 29.7026 13.3986 29.7026 15.1408C29.7026 16.883 31.1149 18.2953 32.8571 18.2953Z"
        stroke="currentColor"
        strokeWidth="2"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.8574 9.28573V8"
        stroke="currentColor"
        strokeWidth="2"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.8574 22.286V20.8574"
        stroke="currentColor"
        strokeWidth="2"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.8569 11L27.8569 10"
        stroke="currentColor"
        strokeWidth="2"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.0005 20.1406L37.0005 19.1406"
        stroke="currentColor"
        strokeWidth="2"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.1429 15.1426H25.7144"
        stroke="currentColor"
        strokeWidth="2"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.0006 15.1426H38.7148"
        stroke="currentColor"
        strokeWidth="2"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.8569 19.1406L27.8569 20.1406"
        stroke="currentColor"
        strokeWidth="2"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.0005 10L37.0005 11"
        stroke="currentColor"
        strokeWidth="2"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 21.5703L18.0001 35.1419H35.7146"
        stroke="currentColor"
        strokeWidth="2"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.9911 35.1426L16.5625 39.9998"
        stroke="currentColor"
        strokeWidth="2"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.4287 35.1426L32.8573 39.9998"
        stroke="currentColor"
        strokeWidth="2"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9162 20.1439C14.5589 20.1439 15.8905 18.8123 15.8905 17.1696C15.8905 15.5269 14.5589 14.1953 12.9162 14.1953C11.2735 14.1953 9.94189 15.5269 9.94189 17.1696C9.94189 18.8123 11.2735 20.1439 12.9162 20.1439Z"
        stroke="currentColor"
        strokeWidth="2"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.2294 28.3697C22.4005 30.5977 20.9129 31.371 20.2246 31.2707C19.5362 31.1706 19.1475 30.8094 18.8793 30.4073L15.2031 25.2862C14.3987 24.2136 14.8749 22.237 16.1349 21.4771C17.437 20.6921 18.9674 21.2449 19.6377 22.0493L22.6665 26.2881L25.7946 25.152C27.2693 24.6158 28.8782 25.018 29.9508 26.0905L32.6592 28.8487C33.5978 29.7871 33.3471 30.8764 32.5427 31.5468C31.7382 32.2171 30.5562 31.9604 29.7518 31.156L27.2294 28.3697Z"
        stroke="currentColor"
        strokeWidth="2"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
