import { FC } from "react";
import { IClassname } from "@/lib/types/classname";

export const SvgPerfectInvest: FC<IClassname> = ({ className }) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 3V17.5H17.5M6 13.5L9 8.5L12 12.5L16.5 6.5"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="square"
      />
    </svg>
  );
};
