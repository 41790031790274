import { useState, useEffect, useMemo, useCallback } from "react";
import { useThrottle } from "./useThrottle";


export const useWindowSize = (mobileWidth: number = 996) => {
  const [windowSize, setWindowSize] = useState<{ width?: number; height?: number }>({
    width: undefined,
    height: undefined,
  });
  const isMobile = useMemo(
    () => !!windowSize.width && windowSize.width <= mobileWidth,
    [mobileWidth, windowSize.width],
  );

  const handleResize = useCallback(() => {
    
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }, []);
  const throttledResize = useThrottle(() => handleResize(), 50);

  useEffect(() => {
    window.addEventListener("resize", throttledResize);
    // screen.orientation.addEventListener("change", throttledResize);

    throttledResize();

    return () => {
      window.removeEventListener("resize", throttledResize);
      // screen.orientation.removeEventListener("change", throttledResize);
    };
  }, [throttledResize]);
  return { ...windowSize, isMobile };
};
