import { useTranslation } from "next-i18next";
import image from "@/public/image.webp";
import Image from "next/image";
import styles from "./Error500.module.scss";
import React, { FC } from "react";
import { SocialNetworks } from "@/lib/ui/components/SocialNetworks";
import { H1, P3 } from "@/lib/ui/components/Typography";

interface Error500Props {
  error?: Error | null;
  errorInfo?: any;
}
export const Error500: FC<Error500Props> = ({ error, errorInfo }) => {
  const { t } = useTranslation("index", { keyPrefix: "errors" });
  return (
    <section className={styles.errorPage}>
      <div className={styles.errorPage_bg_noise} />
      <div className={styles.errorPage_ellipse} />
      <div
        className={styles.errorPage_leaves}
        style={{ backgroundImage: `url(${image.src})` }}
      />
      <div className={styles.errorPage_leaf}>
        <Image
          sizes="(max-width: 1200px) 0vw, 50vw"
          src={"/images/complex/leaf/white-blue-leaf.webp"}
          alt={""}
          fill
        />
      </div>
      <div className={styles.container}>
        <div className={styles.errorInfo}>
          <H1>500</H1>
          <P3>
            {t("siteUnavailable")} <br />
            {t("writeUs")}
          </P3>
          <div className={styles.socials}>
            <SocialNetworks size="l" />
          </div>
        </div>
      </div>
    </section>
  );
};
