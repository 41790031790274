import React, { ReactNode } from "react";
import Link, { LinkProps } from "next/link";
import css from "./ToggleButton.module.scss";
import cl from "classnames";

interface ToggleButtonProps<T extends "a" | "button" | typeof Link> {
  as: T;
  label: string;
  theme: "dark" | "light" | "outlined" | "secondary";
  icon?: ReactNode;
  isIconLeft?: boolean;
  disabled?: boolean;
  size: "md" | "lg";
  className?: string;
  children?: ReactNode;
  target?: HTMLAnchorElement['target']
  isActive?: boolean;
  isCircle?: boolean;
}

export function ToggleButton<T extends "a" | "button" | typeof Link>({
  as: Component,
  label,
  theme,
  icon,
  disabled,
  size,
  isIconLeft,
  className,
  children,
  isActive = false,
  isCircle = false,
  ...props
}: ToggleButtonProps<T> &
  Omit<
    T extends "a" | "button" ? JSX.IntrinsicElements[T] : LinkProps,
    keyof ToggleButtonProps<T>
  >) {    
  return (
    // @ts-ignore
    <Component
      {...props}
      {...(Component === "button" ? { disabled } : {})}
      className={cl(
        css.button,
        size === "md" ? css.buttonMedium : css.buttonLarge,
        isActive && css.active,
        isCircle && css.buttonCircle,
        theme === "outlined" && css.outlined,
        theme === "dark" && css.dark,
        theme === "light" && css.light,
        theme === "secondary" && css.secondary,
        disabled && css.disabled,
        icon && css.buttonWithIcon,
        className
      )}
    >
      {icon && isIconLeft && <div className={cl(css.buttonIcon, css.buttonIcon_left)}>{icon}</div>}
      {label && <p suppressHydrationWarning>{label}</p>}
      {children}
      {icon && !isIconLeft && <div className={cl(css.buttonIcon, css.buttonIcon_right)}>{icon}</div>}
    </Component>
  );
}

export default ToggleButton;
