import { IClassname } from "@/lib/types/classname";
import { FC } from "react";

export const SvgArrow: FC<IClassname> = ({ className }) => {
  return (
    <svg
      className={className}
      width="20"
      height="14"
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 0C12 0.74152 12.7331 1.8496 13.4746 2.78062C14.4291 3.9793 15.5689 5.0268 16.8762 5.8262C17.8551 6.4248 19.0444 7 20 7M20 7C19.0444 7 17.8551 7.5752 16.8762 8.1738C15.5689 8.9732 14.4291 10.0207 13.4746 11.2194C12.7331 12.1504 12 13.2585 12 14M20 7H0"
        stroke="currentColor"
        strokeWidth="1.6"
      />
    </svg>
  );
};
