import { IClassname } from "@/lib/types/classname";
import { FC } from "react";

export const VerticalLineSmall: FC<IClassname> = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g id="Frame 1000004484">
        <path id="Vector 12" d="M12 6V18.5" stroke="currentColor" strokeWidth="1.6" />
      </g>
    </svg>
  );
};
