import React from 'react';
import Link from 'next/link';
import { P2 } from '../../Typography';
import { dropMenuItemsAbout, oldUrl } from '../MobileNav/constants';
import { useTranslation } from "next-i18next";
import cl from 'classnames';
import styles from './MainMenu.module.scss';

interface IProps {
	dropBgStyle: any;
	close: () => void;
	visible: boolean;
}

export const NavAboutCompany = ( { dropBgStyle, close, visible }: IProps ) => {
	const { t,  i18n: {language}} = useTranslation("index");

	return (
		<div
			style={ dropBgStyle }
			className={ cl('dropdownContent dropSales', styles[`${visible ? '' : 'in'}visible`]) }
		>
			{ dropMenuItemsAbout?.map( ( sale, index ) =>
				<Link href={ sale.type === 'old' ? `${oldUrl}/${language}${sale.path}` : sale.path  } key={ index } onClick={ close }>
					<P2>{ t(sale.label) }</P2>
				</Link>
			) }
		</div>
	);
};

export default NavAboutCompany;