import { FC } from "react";
import { IClassname } from "@/lib/types/classname";

export const SvgWhatappTransparent: FC<IClassname> = ({ className }) => {
  return (
    <svg
      className={className}
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.2312 3.26455C15.3558 2.38478 14.3132 1.68724 13.1641 1.2126C12.015 0.737969 10.7824 0.495744 9.53819 0.500057C4.32513 0.500057 0.0763821 4.72754 0.0763821 9.91453C0.0763821 11.577 0.515578 13.192 1.33668 14.617L0 19.5L5.01256 18.189C6.39698 18.9395 7.95327 19.3385 9.53819 19.3385C14.7513 19.3385 19 15.111 19 9.92403C19 7.40654 18.0166 5.04104 16.2312 3.26455ZM9.53819 17.7425C8.12512 17.7425 6.7407 17.3625 5.52814 16.65L5.24171 16.479L2.26281 17.258L3.05528 14.37L2.86432 14.0755C2.07925 12.8281 1.66239 11.3863 1.66131 9.91453C1.66131 5.60154 5.19397 2.08655 9.52864 2.08655C11.6291 2.08655 13.6055 2.90355 15.0854 4.38555C15.8182 5.11131 16.3989 5.97457 16.7939 6.92529C17.1889 7.87601 17.3903 8.89528 17.3864 9.92403C17.4055 14.237 13.8729 17.7425 9.53819 17.7425ZM13.8538 11.8905C13.6151 11.7765 12.4502 11.2065 12.2402 11.121C12.0206 11.045 11.8678 11.007 11.7055 11.235C11.5432 11.4725 11.0945 12.0045 10.9608 12.1565C10.8271 12.318 10.6839 12.337 10.4452 12.2135C10.2065 12.0995 9.44271 11.843 8.54523 11.045C7.83869 10.418 7.37085 9.64853 7.22764 9.41103C7.09397 9.17353 7.20854 9.05003 7.33266 8.92653C7.43769 8.82203 7.57136 8.65103 7.68593 8.51803C7.8005 8.38503 7.84824 8.28053 7.92462 8.12853C8.001 7.96703 7.96281 7.83404 7.90553 7.72004C7.84824 7.60604 7.37085 6.44704 7.1799 5.97204C6.98894 5.51604 6.78844 5.57304 6.64523 5.56354H6.18693C6.02462 5.56354 5.77638 5.62054 5.55678 5.85804C5.34673 6.09554 4.73568 6.66554 4.73568 7.82453C4.73568 8.98353 5.58543 10.1045 5.7 10.2565C5.81457 10.418 7.37085 12.793 9.73869 13.8095C10.302 14.0565 10.7412 14.199 11.0849 14.3035C11.6482 14.484 12.1638 14.4555 12.5744 14.3985C13.0327 14.332 13.9779 13.8285 14.1688 13.2775C14.3693 12.7265 14.3693 12.261 14.3025 12.1565C14.2357 12.052 14.0925 12.0045 13.8538 11.8905Z"
        fill="currentColor"
      />
    </svg>
  );
};
