import { FC } from 'react';
import { IClassname } from '@/lib/types/classname';

export const SvgDuplex: FC<IClassname> = ({ className }) => {
    return (
        <svg className={className} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4 25.0833H28" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round"/>
        <path d="M4 11L16.3333 6M16.3333 6L27.6667 11M16.3333 6V25M19.6667 14C19.6667 14.3978 19.8247 14.7794 20.106 15.0607C20.3873 15.342 20.7688 15.5 21.1667 15.5C21.5645 15.5 21.946 15.342 22.2273 15.0607C22.5086 14.7794 22.6667 14.3978 22.6667 14C22.6667 13.6022 22.5086 13.2206 22.2273 12.9393C21.946 12.658 21.5645 12.5 21.1667 12.5C20.7688 12.5 20.3873 12.658 20.106 12.9393C19.8247 13.2206 19.6667 13.6022 19.6667 14ZM9.77267 14C9.77267 14.3978 9.93071 14.7794 10.212 15.0607C10.4933 15.342 10.8748 15.5 11.2727 15.5C11.6705 15.5 12.052 15.342 12.3333 15.0607C12.6146 14.7794 12.7727 14.3978 12.7727 14C12.7727 13.6022 12.6146 13.2206 12.3333 12.9393C12.052 12.658 11.6705 12.5 11.2727 12.5C10.8748 12.5 10.4933 12.658 10.212 12.9393C9.93071 13.2206 9.77267 13.6022 9.77267 14Z" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M26 11V25" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M6 11V25" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12.6146 25.0833C12.7389 25.0833 12.8581 25.028 12.946 24.9296C13.0339 24.8311 13.0833 24.6976 13.0833 24.5583V20.3583C13.0833 20.2191 13.0339 20.0856 12.946 19.9871C12.8581 19.8887 12.7389 19.8333 12.6146 19.8333H9.80206C9.67774 19.8333 9.55851 19.8887 9.47061 19.9871C9.3827 20.0856 9.33331 20.2191 9.33331 20.3583V24.5583C9.33331 24.6976 9.3827 24.8311 9.47061 24.9296C9.55851 25.028 9.67774 25.0833 9.80206 25.0833" stroke="currentColor" strokeWidth="1.6"/>
        <path d="M22.6146 25.0833C22.7389 25.0833 22.8581 25.028 22.946 24.9296C23.0339 24.8311 23.0833 24.6976 23.0833 24.5583V20.3583C23.0833 20.2191 23.0339 20.0856 22.946 19.9871C22.8581 19.8887 22.7389 19.8333 22.6146 19.8333H19.8021C19.6777 19.8333 19.5585 19.8887 19.4706 19.9871C19.3827 20.0856 19.3333 20.2191 19.3333 20.3583V24.5583C19.3333 24.6976 19.3827 24.8311 19.4706 24.9296C19.5585 25.028 19.6777 25.0833 19.8021 25.0833" stroke="currentColor" strokeWidth="1.6"/>
        </svg>
        
)}
