import { FC } from 'react';
import { IClassname } from '@/lib/types/classname';

export const SvgBalcony: FC<IClassname> = ({ className }) => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_1637_848)">
<path d="M15.786 23.6606V16.8724C15.786 12.3922 19.4516 8.72656 23.9318 8.72656C28.4121 8.72656 32.0777 12.3922 32.0777 16.8724V23.6606" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M39.2744 29.7708C39.2744 28.6847 38.3241 27.7344 37.238 27.7344H10.764C9.67792 27.7344 8.72757 28.6847 8.72757 29.7708" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M13.0717 27.7324V39.2724H34.7939V27.7324" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M18.5031 27.7324V39.2724" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M23.9318 27.7324V39.2724" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M29.3633 27.7324V39.2724" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M15.786 17.0078H32.0777" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M23.9321 17.0086L19.2889 10.1797" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M23.9318 17.0086L28.5749 10.1797" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
</g>
<defs>
<clipPath id="clip0_1637_848">
<rect width="34" height="34" fill="currentColor" transform="translate(7 7)"/>
</clipPath>
</defs>
</svg>
)}
