import { FC, forwardRef } from "react";
import cl from "classnames";
import styles from "./Input.module.scss";
import { FieldError } from "react-hook-form";
import { contentTypes } from "@/lib/types/forms/common";
import { useTranslation } from "next-i18next";
import useValidationErrorTranslation from "@/lib/hooks/useValidationErrorTranslation";

type IInputType = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

export type IInputProps = {
  placeholder?: string;
  content: contentTypes;
  viewAnnotation?: boolean;
  className?: string;
  error?: FieldError;
  theme?: "white" | "blue" | "transparent" | "white-on-blue" | "accent";
  isValid?: boolean;
  isEmpty?: boolean;
  getValue?: (arg: contentTypes) => void;
} & Pick<
  IInputType,
  | "onFocus"
  | "defaultValue"
  | "autoComplete"
  | "disabled"
  | "id"
  | "type"
  | "value"
  | "onChange"
  | "onBlur"
  | "maxLength"
  | "required"
  | "name"
  | "inputMode"
>;

export const Input: FC<IInputProps> = forwardRef<HTMLInputElement, IInputProps>(
  (
    {
      placeholder,
      content,
      className,
      disabled,
      error,
      viewAnnotation,
      theme = "white",
      isValid,
      getValue,
      isEmpty,
      required,
      ...props
    },
    ref,
  ) => {
    const { t } = useTranslation("index");
    const getErrorTranslation = useValidationErrorTranslation();

    return (
      <div
        className={cl(styles.input__wrapper, styles[`input__wrapper--${theme}`], {
          [styles["input__wrapper--error"]]: !!error?.type,
          [styles["input__wrapper--valid"]]: isValid,
        })}
      >
        <input
          placeholder={t(`form.${placeholder}` || "")}
          ref={ref}
          {...props}
          className={cl(styles.input, styles.p3, className, {
            [styles["input--error"]]: !!error?.type,
            [styles["input--blue"]]: theme === "blue" || theme === "transparent",
            [styles["input--white"]]: theme === "white",
            [styles["input--white-on-blue"]]: theme === "white-on-blue",
            [styles["input--accent"]]: theme === "accent",
          })}
        />
        {error && <p className={styles.input__error}>{getErrorTranslation(error)}</p>}
      </div>
    );
  },
);

Input.displayName = "Input";
