import React from "react";
import { CustomSelect, ICustomSelect } from "./CustomSelect";
import { useAppSelector } from "@/lib/store/app";
import { useSettingsActions } from "@/lib/store/slices/settings";
import { ICurrency } from "@/lib/types/currency";
import { useWindowSize } from "@/lib/hooks/useWindowSize";

interface IProps extends Omit<ICustomSelect, "options"> {}
const currenciesList: ICurrency[] = [
  { value: "usd", label: "USD", symbol: "$" },
  { value: "rub", label: "RUB", symbol: "₽" },
  { value: "thb", label: "THB", symbol: "฿" },
];


export const CurrencySelect = (props: IProps) => {
  const defaultCurrency = useAppSelector((state) => state.settings.currency);
  const { setCurrency } = useSettingsActions();
  const { isMobile } = useWindowSize(1199);

  const handleChange = (selectedOption: ICurrency) => {
    setCurrency(selectedOption);
  };

  return (
    <div>
      <CustomSelect
        {...props}
        options={currenciesList}
        defaultValue={defaultCurrency}
        onChange={handleChange}
        isMobile={isMobile}
        isMenuSelect
      />
    </div>
  );
};

export default CurrencySelect;
