import { FC } from "react";
import { IClassname } from "@/lib/types/classname";

export const ScrollArrow: FC<IClassname> = ({ className }) => {
  return (
    
    <svg className={className} width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 8.4C9.47034 8.4 8.67886 8.91317 8.01384 9.43222C7.15764 10.1004 6.40943 10.8982 5.83843 11.8133C5.41086 12.4986 5 13.3311 5 14M5 14C5 13.3311 4.58914 12.4986 4.16157 11.8133C3.59057 10.8982 2.84236 10.1004 1.98614 9.43222C1.32114 8.91317 0.529642 8.4 -8.1421e-07 8.4M5 14L5 -2.18557e-07" 
    stroke="currentColor" strokeWidth="1.6"/>
    </svg>
  );
};
