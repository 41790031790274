import { FC } from "react";
import { IClassname } from "@/lib/types/classname";

export const SvgCoast: FC<IClassname> = ({ className }) => {
  return (
    <svg
      className={className}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M15.291 30.7744H24.0007" stroke="currentColor" strokeWidth="2" />
      <path d="M28.3555 30.7744H34.6458" stroke="currentColor" strokeWidth="2" />
      <path d="M12.8711 34.6455H23.0324" stroke="currentColor" strokeWidth="2" />
      <path d="M27.3867 34.6455H30.7738" stroke="currentColor" strokeWidth="2" />
      <path d="M29.3223 39H33.6771" stroke="currentColor" strokeWidth="2" />
      <path d="M22.0645 39H24.9677" stroke="currentColor" strokeWidth="2" />
      <path d="M11.4199 27.3872H36.5812" stroke="currentColor" strokeWidth="2" />
      <path d="M24 9V12.871" stroke="currentColor" strokeWidth="2" />
      <path d="M34.6059 13.3936L31.8672 16.1323" stroke="currentColor" strokeWidth="2" />
      <path d="M38.9999 24H35.1289" stroke="currentColor" strokeWidth="2" />
      <path d="M12.871 24H9" stroke="currentColor" strokeWidth="2" />
      <path d="M13.3945 13.3936L16.1332 16.1323" stroke="currentColor" strokeWidth="2" />
      <path d="M19.002 11.9326L19.7423 13.7181" stroke="currentColor" strokeWidth="2" />
      <path d="M11.9316 19.002L13.7171 19.7423" stroke="currentColor" strokeWidth="2" />
      <path d="M28.9981 11.9326L28.2578 13.7181" stroke="currentColor" strokeWidth="2" />
      <path d="M36.0667 19.002L34.2812 19.7423" stroke="currentColor" strokeWidth="2" />
      <path
        d="M16.2573 26.7873C15.9428 25.9163 15.7734 24.9776 15.7734 24.0002C15.7734 19.4567 19.4557 15.7744 23.9992 15.7744C28.5428 15.7744 32.225 19.4567 32.225 24.0002C32.225 24.9776 32.0557 25.9163 31.7412 26.7873"
        stroke="currentColor"
        strokeWidth="2"
      />
    </svg>
  );
};
