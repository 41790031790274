import { ReactNode } from "react";
import {
  SvgBangTao,
  SvgBigFamily,
  SvgBuildingStage,
  SvgDuplex,
  SvgLagoon,
  SvgApartsForPhuket,
  SvgNearLake,
  SvgOneBedroom,
  SvgPenthhouse,
  SvgReady,
  SvgSeaview,
  SvgSouthCondo,
  SvgSwimming,
} from "../../../../icons/catalog-filter";
import { SvgBeach } from "../../../../icons/complex-info";
import { IFirstRowItem } from "@/lib/types/catalogPage";

export const firstRowIcons: Record<typeof firstRowItems[number]['title'], ReactNode> = {
  perfectForInvest: <SvgOneBedroom />,
  villasNearSea: <SvgBeach />,
  "apartsForPhuket": <SvgApartsForPhuket />,
  "villasWithPool": <SvgSwimming />,
  "seaViewApartments": <SvgSeaview />,
  complexesBuildingStage: <SvgBuildingStage />,
  villasInBangTao: <SvgBangTao />,
  apartmentComplexLagoon: <SvgLagoon />,
  villasForBigFamily: <SvgBigFamily />,
  apartmentsNearALake: <SvgNearLake />,
  villasOnPhuketSouth: <SvgSouthCondo />,
  penthousePrettyView: <SvgPenthhouse />,
  spaciousDuplex: <SvgDuplex />,
  apartsReadyForMoveIn: <SvgReady />,
 
}
export const firstRowItems: Omit<IFirstRowItem, 'icon'>[] = [
  {
    title: "perfectForInvest",
    query: {
      perfectInvest: "true",
      quickSelection: 'perfectForInvest'
    },
  },
  {
    title: "villasNearSea",
    query: {
      propertyType: "villa",
      beachDistance: "1",
      quickSelection: 'villasNearSea'
    },
  },
  {
    title: "apartsForPhuket",
    query: {
      propertyType: ["apartment", "penthouse", "duplex"],
      phuketDistrict: "all",
      quickSelection: 'apartsForPhuket'
    },
  },
  {
    title: "villasWithPool",
    query: {
      propertyType: "villa",
      facilities: "privatePool",
      quickSelection: 'villasWithPool'
    },
  },
  {
    title: "seaViewApartments",
    query: {
      propertyType: ["apartment", "penthouse", "duplex"],
      view: "SEA",
      quickSelection: 'seaViewApartments'
    },
  },
  {
    title: "complexesBuildingStage",
    query: {
      buildingStages: "stillBuilding",
      quickSelection: 'complexesBuildingStage'
    },
  },
  {
    title: "villasInBangTao",
    query: {
      propertyType: "villa",
      phuketDistrict: ["bang-tao", "lagoon", "layan"],
      quickSelection: 'villasInBangTao'
    },
  },
  {
    title: "apartmentComplexLagoon",
    query: {
      propertyType: ["apartment", "penthouse", "duplex"],
      phuketDistrict: "lagoon",
      quickSelection: 'apartmentComplexLagoon'
    },
  },
  {
    title: "villasForBigFamily",
    query: {
      propertyType: "villa",
      bedrooms: "4",
      quickSelection: 'villasForBigFamily'
    },
  },
  {
    title: "apartmentsNearALake",
    query: {
      propertyType: ["apartment", "penthouse", "duplex"],
      view: "LAKE",
      quickSelection: 'apartmentsNearALake'
    },
  },
  {
    title: "villasOnPhuketSouth",
    query: {
      propertyType: "villa",
      phuketDistrict: [
        "ko-kaeo",
        "patong",
        "phuket-town",
        "karon",
        "kata",
        "kata-noi",
        "panwa",
        "cape-yamu",
        "kathu",
        "ko-sire",
        "chalong",
        "rawai",
        "nai-harn",
      ],
      quickSelection: 'villasOnPhuketSouth'
    },
  },
  {
    title: "penthousePrettyView",
    query: {
      propertyType: "penthouse",
      view: ["HILLS", "SEA"],
      quickSelection: 'penthousePrettyView'
    },
  },
  {
    title: "spaciousDuplex",
    query: {
      propertyType: "duplex",
      bedrooms: ["3", "4"],
      quickSelection: 'spaciousDuplex'
    },
  },
  {
    title: "apartsReadyForMoveIn",
    query: {
      propertyType: ["apartment", "penthouse", "duplex"],
      buildingStages: "readyForMoveIn",
      quickSelection: 'apartsReadyForMoveIn'
    },
  },
];

export const bedroomsOptions = [
  { value: "0", label: "studio" },
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4+" },
];
export const bathroomOptions = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3+" },
];
export const islandOptions = [
  { value: "PHUKET", label: "PHUKET" },
  { value: "SAMUI", label: "SAMUI" },
];
export const propertyTypeOptions = [
  { value: "villa", label: "villa" },
  { value: "apartment", label: "apartments" },
  { value: "penthouse", label: "penthhouse", breakLine: true },
  { value: "townhouse", label: "townhouse" },
  { value: "duplex", label: "duplex" },
];
export const beachDistanceOptions = [
  { value: "1", label: "catalog.walkProximity" },
  { value: "2", label: "catalog.walkDistance" },
  { value: "3", label: "catalog.shortRide" },
];

export const investOptions = [
  { value: "guaranteedIncome", label: "guaranteedIncome" },
  { value: "rentalPool", label: "rentalPool" },
  { value: "installment", label: "installment" },
];

export const constructionOptions = [
  { value: "readyForMoveIn", label: "readyForMoveIn" },
  { value: "stillBuilding", label: "stillBuilding" },
];

export const viewOptions = [
  { value: "SEA", label: "SEA" },
  { value: "HILLS", label: "HILLS" },
  { value: "LAKE", label: "LAKE" },
];

export const landscapeOptions = [
  { value: "HILL", label: "HILL" },
  { value: "MOUNTAIN", label: "MOUNTAIN" },
  { value: "PLAIN", label: "PLAIN" },
  { value: "COAST", label: "COAST" },
];
export const ownershipTypeOptions = [
  { value: "FREEHOLD", label: "freehold" },
  { value: "LEASEHOLD", label: "Leasehold" },
  { value: "BOTH", label: "both" },
];

export const phuketBeachesOptions = [
  { value: "patong", label: "PATONG" },
  { value: "kata", label: "KATA" },
  { value: "kata-noi", label: "KATA_NOI" },
  { value: "karon", label: "KARON" },
  { value: "kamala", label: "KAMALA" },
  { value: "surin", label: "SURIN" },
  { value: "bang-tao", label: "BANG_TAO" },
  { value: "lagoon", label: "LAGOON" },
  { value: "layan", label: "LAYAN" },
  { value: "naithon", label: "NAITHON" },
  { value: "nai-yang", label: "NAI_YANG" },
  { value: "mai-khao", label: "MAI_KHAO" },
  { value: "natai", label: "NATAI" },
  { value: "nai-harn", label: "NAI_HARN" },
  { value: "rawai", label: "RAWAI" },
  { value: "ao-sane", label: "AO_SANE" },
  { value: "laem-ka", label: "LAEM_KA" },
  { value: "nui", label: "NUI" },
  { value: "panwa", label: "PANWA" },
  { value: "ao-yon", label: "AO_YON" },
  { value: "khao-khat", label: "KHAO_KHAT" },
  { value: "tri-trang", label: "TRI_TRANG" },
  { value: "paradise", label: "PARADISE" },
  { value: "kalim", label: "KALIM" },
  { value: "cape-yamu", label: "CAPE_YAMU" },
  { value: "thala", label: "THALA" },
  { value: "banana", label: "BANANA" },
  { value: "chalong", label: "CHALONG" },
  { value: "laem-sing", label: "LAEM_SING" },
  { value: "freedom", label: "FREEDOM" },
  { value: "ya-nui", label: "YA_NUI" },
];

export const samuiBeachesOptions = [
  { value: "maenam", label: "MAENAM" },
  { value: "choeng-mon", label: "CHOENG_MON" },
  { value: "lamai", label: "LAMAI" },
  { value: "bang-po", label: "BANG_PO" },
  { value: "baan-thai", label: "BAAN_THAI" },
  { value: "bophut", label: "BOPHUT" },
  { value: "chaweng", label: "CHAWENG" },
  { value: "chaweng-noi", label: "CHAWENG_NOI" },
  { value: "samrong", label: "SAMRONG" },
  { value: "thongson", label: "THONGSON" },
  { value: "coral-cove", label: "CORAL_COVE" },
  { value: "crystal-bay", label: "CRYSTAL_BAY" },
  { value: "bangrak", label: "BANGRAK" },
  { value: "lipa-noi", label: "LIPA_NOI" },
  { value: "plai-laem", label: "PLAI_LAEM" },
  { value: "hua-thanon", label: "HUA_THANON" },
  { value: "nahai", label: "NAHAI" },
  { value: "bang-kao", label: "BANG_KAO" },
  { value: "taling-ngam", label: "TALING_NGAM" },
  { value: "nathon", label: "NATHON" },
  { value: "laem-yai", label: "LAEM_YAI" },
  { value: "chaweng-yai", label: "CHAWENG_YAI" },
];

export const phuketDistrictOptions = [
  { value: "phang-nga", label: "PHANG_NGA" },
  { value: "coconut-island", label: "COCONUT_ISLAND" },
  { value: "mai-khao", label: "MAI_KHAO" },
  { value: "nai-yang", label: "NAI_YANG" },
  { value: "naithon", label: "NAITHON" },
  { value: "talang", label: "TALANG" },
  { value: "ao-po", label: "AO_PO" },
  { value: "layan", label: "LAYAN" },
  { value: "lagoon", label: "LAGOON" },
  { value: "bang-tao", label: "BANG_TAO" },
  { value: "pa-klok", label: "PA_KLOK" },
  { value: "cape-yamu", label: "CAPE_YAMU" },
  { value: "surin", label: "SURIN" },
  { value: "kamala", label: "KAMALA" },
  { value: "ko-kaeo", label: "KO_KAEO" },
  { value: "ratsada", label: "RATSADA" },
  { value: "patong", label: "PATONG" },
  { value: "kathu", label: "KATHU" },
  { value: "phuket-town", label: "PHUKET_TOWN" },
  { value: "ko-sire", label: "KO_SIRE" },
  { value: "karon", label: "KARON" },
  { value: "kata", label: "KATA" },
  { value: "kata-noi", label: "KATA_NOI" },
  { value: "chalong", label: "CHALONG" },
  { value: "panwa", label: "PANWA" },
  { value: "rawai", label: "RAWAI" },
  { value: "nai-harn", label: "NAI_HARN" },
];

export const samuiDistrictOptions = [
  { value: "lamai", label: "LAMAI" },
  { value: "choeng-mon", label: "CHOENG_MON" },
  { value: "chaweng", label: "CHAWENG" },
  { value: "maenam", label: "MAENAM" },
  { value: "bophut", label: "BOPHUT" },
  { value: "bangrak", label: "BANGRAK" },
  { value: "bang-kao", label: "BANG_KAO" },
  { value: "bang-po", label: "BANG_PO" },
  // trans
  { value: "baan-thai", label: "BAAN_THAI" },
  { value: "bang-makham", label: "BANG_MAKHAM" },
  { value: "coral-cove", label: "CORAL_COVE" },
  { value: "laem-set", label: "LAEM_SET" },
  { value: "laem-yai", label: "LAEM_YAI" },
  { value: "lipa-noi", label: "LIPA_NOI" },
  { value: "nathon", label: "NATHON" },
  { value: "taling-ngam", label: "TALING_NGAM" },
  // trans
  { value: "thongson", label: "THONGSON" },
  { value: "thongtakian", label: "THONGTAKIAN" },
  { value: "thong-krut", label: "THONG_KRUT" },
  { value: "hua-thanon", label: "HUA_THANON" },
  { value: "pang-ka", label: "PANG_KA" },
  { value: "plai-laem", label: "PLAI_LAEM" },
  { value: "chaweng-noi", label: "CHAWENG_NOI" },
];

export const facilitiesOptions = [
  { value: "pool", label: "pool" },
  { value: "gym", label: "gym" },
  { value: "parking", label: "parking" },
  { value: "cctv", label: "cctv" },
  { value: "security", label: "security24" },
  { value: "coworking", label: "coworking" },
  { value: "shuttle", label: "shuttle" },
  { value: "restaurant", label: "restaurant" },
  { value: "privatePool", label: "privatePool" }, //aparts
  { value: "publicGarden", label: "publicGarden" },
  { value: "privateGarden", label: "privateGarden" }, //aparts
  { value: "reception", label: "reception" },
  { value: "management", label: "management" },
  { value: "spa", label: "spa" },
  { value: "tennis", label: "tennis" },
  { value: "kidsRoom", label: "kidsInfra" },
  { value: "balcony", label: "balcony" }, //aparts
  { value: "terrace", label: "terrace" }, //aparts
  { value: "jacuzzi", label: "jacuzzi" },
  { value: "staffRoom", label: "staffRoom" },
];

export const predefinedPrices = {
  usd: {
    price1: {
      value: 200_000,
      display: "_symbol_200 000",
      minValue: 0,
      maxValue: 200_000,
    },
    price2: {
      value: 500_000,
      display: `_symbol_200 000 - _symbol_500 000`,
      minValue: 200_000,
      maxValue: 500_000,
    },
    price3: {
      value: 1_000_000,
      display: "_symbol_500 000 – _symbol_1 ",
      minValue: 500_000,
      maxValue: 1_000_000,
    },
    price4: { value: 1_000_000, display: "1+", minValue: 1_000_000, maxValue: Infinity },
  },
  rub: {
    price1: {
      value: 20_000_000,
      display: "_symbol_20 ",
      minValue: 0,
      maxValue: 20_000_000,
    },
    price2: {
      value: 50_000_000,
      display: `_symbol_20 - _symbol_50 `,
      minValue: 20_000_000,
      maxValue: 50_000_000,
    },
    price3: {
      value: 100_000_000,
      display: "_symbol_50 – _symbol_100 ",
      minValue: 50_000_000,
      maxValue: 100_000_000,
    },
    price4: {
      value: 100_000_000,
      display: "100+",
      minValue: 100_000_000,
      maxValue: Infinity,
    },
  },
  thb: {
    price1: { value: 7_000_000, display: "_symbol_7 ", minValue: 0, maxValue: 7_000_000 },
    price2: {
      value: 18_000_000,
      display: `_symbol_7 - _symbol_18 `,
      minValue: 7_000_000,
      maxValue: 18_000_000,
    },
    price3: {
      value: 35_000_000,
      display: "_symbol_18 – _symbol_35 ",
      minValue: 18_000_000,
      maxValue: 35_000_000,
    },
    price4: {
      value: 35_000_000,
      display: "35+",
      minValue: 35_000_000,
      maxValue: Infinity,
    },
  },
};
