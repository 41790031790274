import { FC } from 'react';
import { IClassname } from '@/lib/types/classname';

export const SvgVillaArea: FC<IClassname> = ({ className }) => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M23.9996 9V13.2856M33.1066 23.9997H38.9993" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M8.99997 23.9999H23.9996M23.9996 19V28.9998" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M33.23 38.9961H37.4985C38.3269 38.9961 38.9985 38.3245 38.9985 37.4961V10.5C38.9985 9.67157 38.3269 9 37.4985 9H10.5024C9.67398 9 9.00241 9.67157 9.00241 10.5V37.4961C9.00241 38.3245 9.67398 38.9961 10.5024 38.9961H22.2973C22.6521 38.9961 22.9954 38.8703 23.2662 38.6412L30.3458 32.6508" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
)}
