import React, { FC, useRef, useState } from "react";
import Link from "next/link";
import cn from "classnames";
import CustomMenuItem from "../CustomMenuItem";
import { useTranslation } from "next-i18next";
import { FeedBackModal, FeedBackModalNew, modal } from "../../Modal";
import Button from "../../Buttons/Button/Button";
import { ArrowIcon, BurgerButtonIcon, CloseSign, LogoMobileIcon } from "@/lib/ui/icons";
import { IMobileMenuItem, mobileMenuNav, oldUrl } from "./constants";
import styles from "./MobileNav.module.scss";
import { CircleButton } from "../../Buttons";
import { P1, P2, P4 } from "../../Typography";
import { PHONE, PHONE_FORMATTED, SALES_EMAIL } from "@/lib/constants/contacts";
import { LanguageSelect, CurrencySelect } from "@/lib/ui/components/Selects";
import { sendEmailClick, sendPhoneClick } from "@/lib/utils/sendGTM";

export const MobileMainMenu: FC<{ closeMenu: () => void; isMenuOpen: boolean }> = ({
  closeMenu,
  isMenuOpen,
}) => {
  const refSales = useRef(null);
  const refService = useRef(null);
  const refAbout = useRef(null);
  const {
    t,
    i18n: { language },
  } = useTranslation("index");

  const toCallModal = () => {
    modal.open(<FeedBackModalNew eventName={['header']}/>, { isFeedBackNew: true });
  };

  const [selectedMenu, setSelectedMenu] = useState<IMobileMenuItem | null>(null);

  const [isNavSalesOpen, setIsNavSalesOpen] = useState(false);
  const [isNavServiceOpen, setIsNavServiceOpen] = useState(false);
  const [isNavAboutCompanyOpen, setIsNavAboutCompanyOpen] = useState(false);
  const isNavOpen = isNavSalesOpen || isNavServiceOpen || isNavAboutCompanyOpen;

  const closeSubMenus = () => {
    closeSalesMenu();
    closeServiceMenu();
    closeAboutMenu();
  };

  const openSalesMenu = (item: IMobileMenuItem) => {
    setSelectedMenu(item);
    setIsNavSalesOpen(true);
  };
  const closeSalesMenu = () => {
    setIsNavSalesOpen(false);
  };
  const openServiceMenu = (item: IMobileMenuItem) => {
    setSelectedMenu(item);
    setIsNavServiceOpen(true);
  };
  const closeServiceMenu = () => {
    setIsNavServiceOpen(false);
  };

  const openAboutMenu = (item: IMobileMenuItem) => {
    setSelectedMenu(item);
    setIsNavAboutCompanyOpen(true);
  };
  const closeAboutMenu = () => {
    setIsNavAboutCompanyOpen(false);
  };

  const closeAllMenus = () => {
    closeSubMenus();
    closeMenu();
  };
  return (
    <section
      className={cn(styles.mobileMainMenu, isMenuOpen && styles.mobileMainMenu_active)}
    >
      <div
        className={cn(
          styles.mobileMainMenu_section,
          isNavOpen && styles["mobileMainMenu_section--open"],
        )}
      >
        <div>
          <div className={styles.mobileMainMenu_header} onClick={closeSubMenus}>
            <Link
              aria-label="main page"
              href={"/"}
              className={"header-wrapper-logo"}
              onClick={closeMenu}
            >
              <LogoMobileIcon />
            </Link>

            <div className={styles.mobileMainMenu_header_right}>
              <LanguageSelect label="language select" />
              <CurrencySelect label="currenct select" />

              <div className={styles.mobileMainMenu_header_button}>
                <CircleButton
                  as="button"
                  theme="mixed"
                  onClick={closeMenu}
                  name="menu button"
                  content={isMenuOpen ? <CloseSign /> : <BurgerButtonIcon />}
                />
              </div>
            </div>
          </div>
          <nav className={styles.menu__nav}>
            <div className={styles.menu__navMain}>
              {mobileMenuNav.map((item) => {
                const onClick = item.isNavSales
                  ? openSalesMenu
                  : item.isNavService
                  ? openServiceMenu
                  : item.isNavAboutCompany
                  ? openAboutMenu
                  : closeSalesMenu;
                const textClass =
                  (item.isNavSales && isNavSalesOpen) ||
                  (item.isNavService && isNavServiceOpen) ||
                  (item.isNavAboutCompany && isNavAboutCompanyOpen)
                    ? "colorGradient"
                    : "";

                if (item.link)
                  return (
                    <CustomMenuItem
                      key={item.text}
                      text={t(item.text)}
                      className={styles.mobileMainMenu_item}
                      path={item.text === 'rent' ? `${oldUrl}/${language}${item.link}` : `${item.link}`}
                      closeMenu={closeMenu}
                    />
                  );
                return (
                  <div
                    key={item.text}
                    className={""}
                    onClick={() => onClick(item)}
                    ref={
                      item.isNavSales
                        ? refSales
                        : item.isNavService
                        ? refService
                        : item.isNavAboutCompany
                        ? refAbout
                        : undefined
                    }
                  >
                    <CustomMenuItem
                      closeMenu={closeMenu}
                      text={t(item.text) || ""}
                      className={styles.mobileMainMenu_item}
                      textClass={textClass}
                      isWithoutArrow={!item.subItems}
                    />
                    <ul style={{ display: "none" }}>
                      {item.subItems?.map((subItem) => {
                        return <li key={subItem.key}>{t(subItem.label)}</li>;
                      })}
                    </ul>
                  </div>
                );
              })}
            </div>
          </nav>
        </div>

        <div className={styles.menu__additional}>
          <div className={styles.mobileMainMenu_header}>
            <CircleButton
              as="button"
              theme="mixed"
              onClick={() => closeSubMenus()}
              name="return button"
              content={<ArrowIcon />}
            />
            <P1 className={styles.menu__selectedTitle}>
              {selectedMenu ? t(selectedMenu.text) : ""}
            </P1>
            <CircleButton
              as="button"
              theme="mixed"
              onClick={closeAllMenus}
              name="menu button"
              content={isMenuOpen ? <CloseSign /> : <BurgerButtonIcon />}
            />
          </div>
          <ul className={cn(styles.menu__selectedList, styles.menu__nav)}>
            {selectedMenu?.subItems?.map((item) => (
              <li key={item.label}>
                <CustomMenuItem
                  closeMenu={closeAllMenus}
                  text={t(item.label) || ""}
                  className={styles.mobileMainMenu_item}
                  isWithoutArrow
                  path={
                    item.type === "old" ? `${oldUrl}/${language}${item.path}` : item.path
                  }
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className={styles.menu__bottom}>
        <div className={styles.menu__bottomItem} onClick={closeSubMenus}>
          <P4>{t("email") || ""}</P4>
          <a href={`mailto:${SALES_EMAIL}`} onClick={sendEmailClick}>
            <P2>{SALES_EMAIL}</P2>
          </a>
        </div>
        <div className={styles.menu__bottomItem} onClick={closeSubMenus}>
          <P4>{t("phone") || ""}</P4>
          <a href={`tel:${PHONE}`} onClick={sendPhoneClick}>
            <P2>{PHONE_FORMATTED}</P2>
          </a>
        </div>
        <Button
          className={styles.menu__bottomButton}
          label={t("form.buttons.requestACall") || ""}
          theme={"dark"}
          size={"lg"}
          as="button"
          type="button"
          onClick={toCallModal}
        />
      </div>
    </section>
  );
};
