import { FC } from "react";
import styles from "./ReloadTranslations.module.scss";
import { Button, CircleButton } from "../Buttons";
import { i18n } from "next-i18next";
import { useRouter } from "next/router";
import { isLocal } from "../../../api/global";

export const ReloadTranslations = () => {
  const router = useRouter();
  const onClick = () => {
    i18n?.reloadResources();
    router.replace(router.asPath, undefined, {
      scroll: false,
    });
  };
  
  if (!isLocal) return null;
  return (
    <CircleButton
      onClick={onClick}
      className={styles.te}
      as="button"
      theme="mixed"
      title="Перезагрузить переводы"
      content={
        <>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M3 12a9 9 0 0 1 9-9 9.75 9.75 0 0 1 6.74 2.74L21 8" />
            <path d="M21 3v5h-5" />
            <path d="M21 12a9 9 0 0 1-9 9 9.75 9.75 0 0 1-6.74-2.74L3 16" />
            <path d="M8 16H3v5" />
          </svg>
        </>
      }
    />
  );
};
