import { useRouter } from "next/router";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { CatalogQuery, CatalogQueryParam, ParamRecord } from "../types/catalogPage";
import { useAppSelector } from "../store/app";
import { useCatalogActions } from "../store/slices/catalog";
import { firstRowItems } from "../ui/blocks/page-specific/Catalog/CatalogFilter/constants";
import { compareArray } from "../utils/compareArrays";

export const useCatalogFilter = () => {
  const router = useRouter();
  const { clearParamState } = useCatalogActions();
  const predefinedFilters = useAppSelector((state) => state.catalog.filters);
  const url = useMemo(() => router.asPath.replaceAll(/[\?|&]modal=true/g, ""), [router]);
  const urlRef = useRef(url);
  const query = useMemo(() => {
    const q = router.query;
    delete q.modal;
    return { ...q, ...predefinedFilters } as CatalogQuery;
  }, [router.query, predefinedFilters]);

  const isOriginalCatalog = useMemo(() => router.asPath.endsWith("catalog"), [router]);
  const isRootCatalog = useMemo(() => {
    const str = router.asPath.replace("?modal=true", "");
    return str.includes("catalog") && !str.includes("?") && !str.includes("&");
  }, [router]);
  const paginatedQuery = useMemo(() => {
    const q = structuredClone(query);
    delete q.modal;
    delete q.page;
    delete q.display;
    return { ...q } as CatalogQuery;
  }, [query]);

  const isSelection = useMemo(() => {
    if (!paginatedQuery.quickSelection) return false;
    const selection = firstRowItems.find(
      (i) => i.query.quickSelection === paginatedQuery.quickSelection,
    );
    if (!selection) return false;
    const queryLength = Object.keys(paginatedQuery).length
    const res =  Object.entries(selection.query).every(([key, val], _, arr) => {
      return (
        arr.length === queryLength &&
        Object.hasOwn(paginatedQuery, key) &&
        typeof paginatedQuery[key as CatalogQueryParam] === typeof val &&
        (Array.isArray(val) && Array.isArray(paginatedQuery[key as CatalogQueryParam])
          ? compareArray(paginatedQuery[key as CatalogQueryParam] as any[], val)
          : val === paginatedQuery[key as CatalogQueryParam])
      );
    });
    return res;
  }, [paginatedQuery]);
  /**
   * Удаляет параметр целиком
   * */
  const deleteParam = useCallback(
    (key: CatalogQueryParam) => {
      // const oldKey = query[key];
      if (key in query) {
        delete query[key];
        return router.replace(
          {
            pathname: router.pathname,
            query: { ...query } as any,
          },
          undefined,
          { scroll: false, shallow: true },
        );
      }
    },

    [query, router],
  );

  const deleteParams = useCallback(
    (keys: CatalogQueryParam[]) => {
      // const oldKey = query[key];
      keys.forEach((key) => {
        if (key in query) {
          delete query[key];
        }
      });
      return router.replace(
        {
          pathname: router.pathname,
          query: { ...query } as any,
        },
        undefined,
        { scroll: false, shallow: true },
      );
    },

    [query, router],
  );

  const removeSingleParam = useCallback(
    (key: CatalogQueryParam, paramToDelete: any) => {
      const oldKey = query[key];
      if (key in query) {
        const newQuery = Array.isArray(oldKey)
          ? (oldKey as any[]).filter((v) => v !== paramToDelete)
          : undefined;
        if (!newQuery) {
          delete query[key];
        }

        return router.replace(
          {
            pathname: router.pathname,
            query: { ...query, ...(newQuery && { [key]: newQuery }) } as any,
          },
          undefined,
          { scroll: false, shallow: true },
        );
      }
    },

    [query, router],
  );
  const removeMultipleParams = useCallback(
    (values: Partial<CatalogQuery>) => {
      const currentQuery = query;
      Object.entries(values).forEach(([key, value]) => {
        const k = key as CatalogQueryParam;
        const oldKey = currentQuery[k];

        if (k in currentQuery) {
          const newQuery = Array.isArray(oldKey)
            ? (oldKey as any[]).filter((v) =>
                Array.isArray(value)
                  ? (value as any[]).every((val) => val !== v)
                  : v !== value,
              )
            : undefined;
          if (!newQuery || !newQuery.length) {
            delete currentQuery[k];
          }
        }
      });
      return router.replace(
        {
          pathname: router.pathname,
          query: { ...currentQuery } as any,
        },
        undefined,
        { scroll: false, shallow: true },
      );
    },

    [query, router],
  );

  const changeSelection = useCallback(
    (
      activeSelectionValues: Partial<CatalogQuery> | undefined,
      values: Partial<CatalogQuery>,
    ) => {
      const currentQuery = query;

      // setParam(undefined, { ...currentQuery, ...query }, true)
      const newEntry: Partial<CatalogQuery> = { ...currentQuery, ...values };

      if (activeSelectionValues) {
        Object.entries(activeSelectionValues).forEach(([key, value]) => {
          const k = key as CatalogQueryParam;
          const oldValue = newEntry[k];

          if (k in newEntry) {
            const newQuery = Array.isArray(oldValue)
              ? (oldValue as any[]).filter((v) => (oldValue as any[]).includes(v))
              : oldValue && Array.isArray(value)
                ? value
                : value;
            if (!(k in values) || !(k in newEntry)) {
              delete newEntry[k];
            }
            if (
              !newQuery ||
              (Array.isArray(newQuery) && !newQuery.length && !values[k])
            ) {
              delete newEntry[k];
            } else {
              (newEntry[k] as any) = values[k];
            }
          }
        });
      }
      let key: keyof CatalogQuery;
      for (key in newEntry) {
        if (newEntry[key] === undefined) {
          delete newEntry[key];
          delete currentQuery[key];
        }
      }
      return router.replace(
        {
          pathname: router.pathname,
          query: { ...newEntry } as any,
        },
        undefined,
        { scroll: false, shallow: true },
      );
    },

    [query, router],
  );

  const setParam = useCallback(
    <E extends CatalogQuery>(
      entry?: ParamRecord<E>,
      entries?: CatalogQuery,
      replaceParams?: boolean,
      routerPush?: boolean,
      scroll?: boolean,
      as?: string,
    ) => {
      const newEntry = entry ? { [entry.key]: entry.value } : { ...entries };
      let key: keyof typeof newEntry;
      for (key in newEntry) {
        if (newEntry[key] === undefined) {
          delete newEntry[key];
          delete query[key];
        }
      }
      if (!isOriginalCatalog) {
        deleteParam("predefinedParams");
      }
      const method = routerPush ? "push" : "replace";

      router[method](
        {
          pathname: router.pathname,
          query: { ...(!replaceParams && query), ...newEntry } as any,
        },
        as,
        { scroll: scroll || false, shallow: true },
      );
    },
    [deleteParam, query, router, isOriginalCatalog],
  );

  const resetParams = useCallback(() => {
    router.replace({ pathname: router.pathname, query: {} }, undefined, {
      scroll: false,
      shallow: true,
    });
  }, [router]);

  /**
   * @notice Для boolean значений
   *  */
  const toggleParam = useCallback(
    (key: CatalogQueryParam) => {
      const oldKey = query[key] === "true";

      if (!oldKey) {
        return router.replace({
          pathname: router.pathname,
          query: { ...query, [key]: "true" } as any,
        });
      } else {
        delete query[key];
        return router.replace(
          {
            pathname: router.pathname,
            query: { ...query } as any,
          },
          undefined,
          { scroll: false, shallow: true },
        );
      }
    },
    [query, router],
  );
  useEffect(() => {
    if (urlRef.current && urlRef.current !== url) {
      deleteParam("predefinedParams");
      clearParamState();
    }
  }, [deleteParam, clearParamState, url]);

  return {
    query,
    setParam,
    resetParams,
    toggleParam,
    router,
    deleteParam,
    isOriginalCatalog,
    predefinedFilters,
    isRootCatalog,
    removeSingleParam,
    removeMultipleParams,
    deleteParams,
    changeSelection,
    paginatedQuery,
    isSelection,
  };
};
