import { FC } from "react";
import { IClassname } from "@/lib/types/classname";

export const SvgRestaurant: FC<IClassname> = ({ className }) => {
  return (
    <svg
      className={className}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.1176 39V9.625H28.7059C30.11 9.625 31.4565 10.2176 32.4494 11.2725C33.4422 12.3274 34 13.7582 34 15.25V27.125H33.4118C32.6317 27.125 31.8836 27.4542 31.332 28.0403C30.7805 28.6263 30.4706 29.4212 30.4706 30.25M18.1176 39V20.875M18.1176 20.875C19.2097 20.875 20.2571 20.4141 21.0293 19.5936C21.8015 18.7731 22.2353 17.6603 22.2353 16.5V9M18.1176 20.875C17.0256 20.875 15.9782 20.4141 15.206 19.5936C14.4338 18.7731 14 17.6603 14 16.5V9M18.1176 9V18.375"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
    </svg>
  );
};
