import { useCallback, useEffect, useMemo, useRef, useState } from "react";

export const useWindowResizeObserver = (mobileWidth: number = 996) => {
  const observer = useRef<null | ResizeObserver>(null);
  const [windowSize, setWindowSize] = useState<{ width?: number; height?: number }>({
    width: undefined,
    height: undefined,
  });
  const isMobile = useMemo(
    () => !!windowSize.width && windowSize.width <= mobileWidth,
    [mobileWidth, windowSize.width],
  );
  const handleResize = useCallback(() => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }, []);
  useEffect(() => {
    observer.current = new ResizeObserver(handleResize);
    observer.current.observe(document.body);
    return () => {
      if (observer.current) {
        observer.current.unobserve(document.body);
      }
    };
  }, [handleResize]);
  return { ...windowSize, isMobile };
};
