import { ImageLoaderProps } from "next/image";

export const imageLoader = ({ src: originalSrc, width }: ImageLoaderProps) => {
  const originalHasExtension = /\.(jpg|jpeg|png|webp)$/i.test(originalSrc);
  const extension = originalSrc.includes("svg")
    ? ".svg"
    : originalHasExtension && originalSrc.includes("store")
    ? originalSrc.slice(originalSrc.lastIndexOf("."))
    : originalSrc.includes(".") && originalSrc.includes("store")
    ? ""
    : originalHasExtension
    ? ".webp"
    : "";

  const src =
    originalHasExtension && originalSrc.includes("store")
      ? originalSrc.slice(0, originalSrc.lastIndexOf("."))
      : originalHasExtension
      ? originalSrc.split(".")[0]
      : originalSrc;
  const getLink = (width: number) => {
    /* todo remove storage check */

    if (/* src.includes("villacarte") || */ extension.includes("svg")) {
      return originalSrc + "?width=" + width;
    }

    return `${src}-${width}${extension}`;
  };
  if (width >= 2800) {
    return getLink(2800);
  }
  if (width >= 2200) {
    return getLink(2200);
  }
  if (width >= 1800) {
    return getLink(1800);
  }
  if (width >= 1400) {
    return getLink(1400);
  }
  if (width >= 1200) {
    return getLink(1200);
  }
  if (width >= 1000) {
    return getLink(1000);
  }
  if (width >= 800) {
    return getLink(800);
  }
  if (width >= 600) {
    return getLink(600);
  }
  if (width >= 400) {
    return getLink(400);
  }
  if (width >= 200) {
    return getLink(200);
  }
  return getLink(2800);
};
export default imageLoader;
