import React, { ReactNode } from "react";
import Custom500 from "../../../../../pages/500";

export class ErrorBoundary extends React.Component<
  { children: ReactNode },
  { hasError: boolean; error: null | Error; errorInfo?: any; hasReloaded: boolean }
> {
  constructor(props: { children: ReactNode }) {
    super(props);
    this.state = { hasError: false, error: null, hasReloaded: false };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    // console.log(error.message);
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: any) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    this.setState({ ...this.state, errorInfo, error });
    console.log(error, errorInfo);
  }
  componentWillUnmount(){
    this.setState(({ ...this.state, hasReloaded: false }))
  }
  

  render() {
    if (this.state.hasError) {
      const setReloaded = () => {
        this.setState(() => ({ ...this.state, hasReloaded: true }))
      }
      return (
        <Custom500
          error={this.state.error}
          errorInfo={this.state.errorInfo}
          hasReloaded={this.state.hasReloaded}
          setReloaded={setReloaded}
        />
      );
    }

    return this.props.children;
  }
}
