import { FC } from "react";
import { IClassname } from "@/lib/types/classname";

export const BahtSign: FC<IClassname> = ({ className }) => {
  return (
    <svg
      className={className}
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.8904 10.3794C12.5277 10.3428 13.1273 10.0653 13.5676 9.60309C14.008 9.14091 14.2561 8.52861 14.2619 7.89026C14.2676 7.25192 14.0305 6.63525 13.5985 6.16523C13.1665 5.69519 12.5721 5.40695 11.9355 5.35889H8.05447C7.8121 5.35889 7.57966 5.45517 7.40828 5.62654C7.23691 5.79793 7.14062 6.03036 7.14062 6.27273V15.3999C7.14062 15.6423 7.23691 15.8748 7.40828 16.0462C7.57966 16.2175 7.8121 16.3138 8.05447 16.3138H11.8904C12.6773 16.3138 13.432 16.0011 13.9885 15.4447C14.545 14.8883 14.8576 14.1335 14.8576 13.3466C14.8576 12.5596 14.545 11.8049 13.9885 11.2485C13.432 10.692 12.6773 10.3794 11.8904 10.3794Z"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0137 5.35894V3.66663"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0137 18.3333V16.3138"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9355 10.3794H7.14062"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
