import { IClassname } from "@/lib/types/classname";
import { FC } from "react";

export const YoutubeSmallIcon: FC<IClassname> = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
        <g id="Youtube Clip Logo" clipPath="url(#clip0_4947_1349)">
        <path id="Vector" d="M17.0007 5.80696C17.0008 5.49086 16.939 5.17784 16.8189 4.88578C16.6987 4.59371 16.5226 4.32833 16.3005 4.10478C16.0784 3.88124 15.8147 3.7039 15.5245 3.58292C15.2343 3.46193 14.9232 3.39966 14.6091 3.39966H3.39169C3.07761 3.39975 2.76663 3.46209 2.4765 3.58312C2.18636 3.70415 1.92276 3.88149 1.70074 4.10503C1.47872 4.32857 1.30263 4.59392 1.18252 4.88594C1.06241 5.17796 1.00064 5.49092 1.00073 5.80696V12.1917C1.00055 12.5078 1.06225 12.8208 1.18232 13.1129C1.30239 13.405 1.47846 13.6704 1.70049 13.894C1.92252 14.1177 2.18616 14.2951 2.47634 14.4161C2.76651 14.5372 3.07755 14.5996 3.39169 14.5997H14.6091C14.9233 14.5997 15.2344 14.5374 15.5246 14.4163C15.8149 14.2953 16.0786 14.1179 16.3007 13.8943C16.5228 13.6707 16.699 13.4052 16.8191 13.1131C16.9392 12.8209 17.0009 12.5078 17.0007 12.1917V5.80696Z" 
        stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_2" d="M8.06559 6.6631C7.92803 6.5782 7.75903 6.57881 7.622 6.6647C7.48497 6.75058 7.40063 6.90875 7.40063 7.07985V10.9199C7.40063 11.091 7.48497 11.2491 7.622 11.335C7.75903 11.4209 7.92803 11.4215 8.06559 11.3366L11.1767 9.41661C11.3152 9.33115 11.4006 9.1721 11.4006 8.99985C11.4006 8.8276 11.3152 8.66856 11.1767 8.5831L8.06559 6.6631Z" 
        stroke="currentColor" strokeWidth="1.6" strokeLinejoin="round"/>
        </g>
        <defs>
        <clipPath id="clip0_4947_1349">
        <rect width="17.6" height="17.6" fill="currentColor" transform="translate(0.199951 0.199951)"/>
        </clipPath>
        </defs>
    </svg>
  );
};