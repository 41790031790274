import React from 'react';
import Link from 'next/link';;
import { P2 } from '../../Typography';
import { dropMenuItemsService, oldUrl } from '../MobileNav/constants';
import { useTranslation } from "next-i18next";
import cl from 'classnames';
import styles from './MainMenu.module.scss';

interface IProps {
	dropBgStyle: any;
	close: () => void;
	visible: boolean;
}

export const NavService = ( { dropBgStyle, close, visible }: IProps ) => {
	const { t,  i18n: {language}} = useTranslation("index");
	return (
		<div
			style={ dropBgStyle }
			className={ cl('dropdownContent dropService', styles[`${visible ? '' : 'in'}visible`]) }
		>
			{ dropMenuItemsService?.map( ( service, index ) =>
				<Link href={ service.type === 'old' ? `${oldUrl}/${language}${service.path}` : service.path  } key={ index } onClick={ close }>
					<P2>{ t(service.label) }</P2>
				</Link>
			) }
		</div>
	);
};

export default NavService;