import { IClassname } from "@/lib/types/classname";
import { FC } from "react";

export const RightSmallIcon: FC<IClassname> = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
      <path d="M9 15L13 11L9 7" stroke="currentColor" strokeWidth="1.6" strokeLinecap="square"/>
    </svg>
  );
};
