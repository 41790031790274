import { FC } from "react";
import { IClassname } from "@/lib/types/classname";

export const RoomServiceStaff: FC<IClassname> = ({ className }) => {
  return (
    <svg
      className={className}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33 31V24"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29 42V32"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.3235 27.3674C21.2992 27.2327 22.2321 26.8919 23.0556 26.3692C23.8792 25.8466 24.5732 25.155 25.0882 24.3437V40.0833C25.0895 40.5913 25.2991 41.0781 25.6711 41.4373C26.0431 41.7965 26.5474 41.9988 27.0735 42H31.0441C31.5703 41.9988 32.0745 41.7965 32.4465 41.4373C32.8186 41.0781 33.0282 40.5913 33.0294 40.0833V30.8833C33.0294 31.3917 33.2386 31.8792 33.6109 32.2386C33.9832 32.5981 34.4882 32.8 35.0147 32.8C35.5412 32.8 36.0462 32.5981 36.4185 32.2386C36.7908 31.8792 37 31.3917 37 30.8833V23.6C37 22.38 36.498 21.21 35.6044 20.3473C34.7109 19.4846 33.499 19 32.2353 19H26.6542C25.695 19.0005 24.7549 19.2589 23.9394 19.7465C23.1239 20.234 22.4652 20.9312 22.0372 21.76L21.8498 22.1234C21.6188 22.5671 21.2647 22.9401 20.8269 23.2008C20.3892 23.4616 19.885 23.5998 19.3706 23.6H11.9853C11.4588 23.6 10.9538 23.8019 10.5815 24.1614C10.2092 24.5208 10 25.0083 10 25.5167C10 26.025 10.2092 26.5125 10.5815 26.872C10.9538 27.2314 11.4588 27.4333 11.9853 27.4333H13.9706"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 24V31.6376C20 31.9989 19.842 32.3455 19.5607 32.601C19.2794 32.8565 18.8978 33 18.5 33H15.5C15.1022 33 14.7206 32.8565 14.4393 32.601C14.158 32.3455 14 31.9989 14 31.6376V24.1444"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25 11V11.6667C25 12.5507 25.4214 13.3986 26.1716 14.0237C26.9217 14.6488 27.9391 15 29 15C30.0609 15 31.0783 14.6488 31.8284 14.0237C32.5786 13.3986 33 12.5507 33 11.6667V11"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33 11H25V7.42501C24.9995 7.25042 25.0506 7.0799 25.1466 6.93672C25.2425 6.79355 25.3785 6.6847 25.536 6.62501C27.776 5.79166 30.224 5.79166 32.464 6.62501C32.6215 6.6847 32.7575 6.79355 32.8534 6.93672C32.9494 7.0799 33.0005 7.25042 33 7.42501V11Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
