import React, { ReactNode } from "react";
import css from "./CircleButton.module.scss";
import Link, { LinkProps } from "next/link";
import classNames from "classnames";

// dark - белый бордер матовый-темный bg + ховер
// light - белый бордер матовый-светлый bg + ховер
// mixed - белая с темным бордером, ховер - темная
// secondary - иконка без бордера светлая или темная, ховера нет!

interface CircleButtonProps<T extends "a" | "button" | typeof Link> {
  as: T;
  content: string | ReactNode;
  theme?: "mixed" | "dark" | "light";
  disabled?: boolean;
  className?: string;
  styleType?: "accent" | "secondary";
  withGradientHover?: boolean;
  target?: HTMLAnchorElement['target'];
  name?: string;
}

export function CircleButton<T extends "a" | "button" | typeof Link>({
  as: Component,
  content,
  theme = "light",
  disabled,
  className,
  styleType = "accent",
  withGradientHover,
  ...props
}: CircleButtonProps<T> &
  Omit<
    T extends "a" | "button" ? JSX.IntrinsicElements[T] : LinkProps,
    keyof CircleButtonProps<T>
  >) {
  return (
    // @ts-ignore
    <Component
      {...props}
      aria-label={props.name}
      {...(Component === "button" ? { disabled } : {})}
      className={classNames(
        css.button,
        styleType === 'accent' ? css.buttonAccent : css.buttonSecondary,
        theme === "dark" && css.buttonDark,
        theme === "light" && css.buttonLight,
        theme === "mixed" && css.buttonMixed,
        withGradientHover && css.buttonHoverGradient,
        disabled && css.disabledButton,
        className,
      )}
    >
      <div className={css.content}>
        {content}
      </div>
      
    </Component>
  );
}

export default CircleButton;
