import cl from "classnames";
import styles from "./socials.module.scss";
import { FC, useId } from "react";
import { LinearGradient } from "./LinearGradient";
import { ISocialIcon } from "@/lib/types/social-icons";

export const SvgFBMessenger: FC<ISocialIcon> = ({ className, isGradient }) => {
  const id = useId();
  const fill = isGradient ? `url('#blueGradient${id}')` : "#848C98";

  return (
    <svg
      className={cl(styles.socials, className)}
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <LinearGradient id={id} />
      <rect x="0.5" width="15" height="15" rx="7.5" fill={fill} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.99219 7.3508C2.99219 4.5596 5.17982 2.49219 8.00107 2.49219C10.8223 2.49219 13.01 4.56086 13.01 7.35206C13.01 10.1433 10.8223 12.2107 8.00107 12.2107C7.49392 12.2107 7.00806 12.1431 6.551 12.0178C6.46209 11.994 6.36692 12.0003 6.28302 12.0379L5.28876 12.4761C5.0283 12.5913 4.73528 12.406 4.72651 12.1218L4.69896 11.2302C4.69646 11.12 4.64637 11.0173 4.56498 10.9447C3.59075 10.0731 2.99219 8.81089 2.99219 7.3508ZM6.46442 6.43769L4.99307 8.77183C4.85157 8.99598 5.12705 9.24768 5.33743 9.08739L6.91773 7.88776C7.02417 7.80637 7.17193 7.80637 7.27962 7.88651L8.45044 8.76432C8.80232 9.02728 9.3032 8.93587 9.53737 8.56396L11.01 6.23107C11.1502 6.00693 10.8747 5.75398 10.6644 5.91426L9.08406 7.11389C8.97763 7.19528 8.82986 7.19528 8.72217 7.11514L7.55135 6.23734C7.19948 5.97437 6.69859 6.06578 6.46442 6.43769Z"
        fill="white"
      />
    </svg>
  );
};
