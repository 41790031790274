export type contentTypes =
  | "name"
  | "arrival"
  | "departure"
  | "guestsAmount"
  | "phone"
  | "email"
  | "question";

const name = /^[A-ЯЁа-яёa-zA-Z\s-]+/;
const phone = /^(\+|00)*[1-9][0-9 \-\(\)\.\s]{7,20}$/;
const email = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const regexList = {
  phone,
  name,
  email,
};
