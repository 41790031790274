import { FC } from 'react';
import { IClassname } from '@/lib/types/classname';

export const SvgPrivateGarden: FC<IClassname> = ({ className }) => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M38 33.1298V36.7819C38 37.1048 37.8717 37.4145 37.6434 37.6428C37.4151 37.8711 37.1055 37.9993 36.7826 37.9993H11.2174C10.8945 37.9993 10.5849 37.8711 10.3566 37.6428C10.1283 37.4145 10 37.1048 10 36.7819V24.608C10 24.2851 10.1283 23.9755 10.3566 23.7472C10.5849 23.5189 10.8945 23.3906 11.2174 23.3906H36.7826C37.1055 23.3906 37.4151 23.5189 37.6434 23.7472C37.8717 23.9755 38 24.2851 38 24.608V33.1298Z" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M14.4166 17.8965C15.4782 19.2076 17.3042 21.4306 17.3042 23.3906" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M20.0739 17.7461C19.0087 19.0572 17.3044 21.4324 17.3044 23.3911" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M11.2174 16.6952C11.2174 17.1795 11.4098 17.644 11.7522 17.9865C12.0947 18.3289 12.5592 18.5213 13.0435 18.5213C13.5278 18.5213 13.9922 18.3289 14.3347 17.9865C14.6772 17.644 14.8696 17.1795 14.8696 16.6952C14.8696 16.2109 14.6772 15.7464 14.3347 15.404C13.9922 15.0615 13.5278 14.8691 13.0435 14.8691C12.5592 14.8691 12.0947 15.0615 11.7522 15.404C11.4098 15.7464 11.2174 16.2109 11.2174 16.6952Z" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M19.7391 16.6952C19.7391 17.1795 19.9315 17.644 20.274 17.9865C20.6164 18.3289 21.0809 18.5213 21.5652 18.5213C22.0495 18.5213 22.514 18.3289 22.8565 17.9865C23.1989 17.644 23.3913 17.1795 23.3913 16.6952C23.3913 16.2109 23.1989 15.7464 22.8565 15.404C22.514 15.0615 22.0495 14.8691 21.5652 14.8691C21.0809 14.8691 20.6164 15.0615 20.274 15.404C19.9315 15.7464 19.7391 16.2109 19.7391 16.6952Z" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M15.4783 11.8261C15.4783 12.3104 15.6707 12.7749 16.0131 13.1173C16.3556 13.4598 16.8201 13.6522 17.3044 13.6522C17.7887 13.6522 18.2531 13.4598 18.5956 13.1173C18.9381 12.7749 19.1304 12.3104 19.1304 11.8261C19.1304 11.3418 18.9381 10.8773 18.5956 10.5348C18.2531 10.1924 17.7887 10 17.3044 10C16.8201 10 16.3556 10.1924 16.0131 10.5348C15.6707 10.8773 15.4783 11.3418 15.4783 11.8261Z" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M30.0869 11.8261C30.0869 12.3104 30.2793 12.7749 30.6218 13.1173C30.9643 13.4598 31.4287 13.6522 31.913 13.6522C32.3973 13.6522 32.8618 13.4598 33.2043 13.1173C33.5467 12.7749 33.7391 12.3104 33.7391 11.8261C33.7391 11.3418 33.5467 10.8773 33.2043 10.5348C32.8618 10.1924 32.3973 10 31.913 10C31.4287 10 30.9643 10.1924 30.6218 10.5348C30.2793 10.8773 30.0869 11.3418 30.0869 11.8261Z" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M29.143 17.7461C30.2082 19.0572 31.9126 21.4311 31.9126 23.3911" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M34.8056 17.8965C33.7391 19.2076 31.9131 21.4318 31.9131 23.3906" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M25.8261 16.6952C25.8261 17.1795 26.0185 17.644 26.3609 17.9865C26.7034 18.3289 27.1679 18.5213 27.6522 18.5213C28.1365 18.5213 28.6009 18.3289 28.9434 17.9865C29.2859 17.644 29.4783 17.1795 29.4783 16.6952C29.4783 16.2109 29.2859 15.7464 28.9434 15.404C28.6009 15.0615 28.1365 14.8691 27.6522 14.8691C27.1679 14.8691 26.7034 15.0615 26.3609 15.404C26.0185 15.7464 25.8261 16.2109 25.8261 16.6952Z" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M34.3478 16.6952C34.3478 17.1795 34.5402 17.644 34.8827 17.9865C35.2251 18.3289 35.6896 18.5213 36.1739 18.5213C36.6582 18.5213 37.1227 18.3289 37.4652 17.9865C37.8076 17.644 38 17.1795 38 16.6952C38 16.2109 37.8076 15.7464 37.4652 15.404C37.1227 15.0615 36.6582 14.8691 36.1739 14.8691C35.6896 14.8691 35.2251 15.0615 34.8827 15.404C34.5402 15.7464 34.3478 16.2109 34.3478 16.6952Z" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M17.3044 23.3915V13.6523" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M31.9131 23.3915V13.6523" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M13.651 28.2617H34.3466" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M13.651 33.1309H34.3466" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M13.651 37.9993V23.3906" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M34.3466 37.9993V23.3906" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
)}
