import React, { ReactNode } from "react";
import Link from "next/link";
import collapseArrow from "@/public/icons/collapseArrow.svg";
import Image from "next/image";
import { P2 } from "../Typography";

interface IProps {
  path?: string;
  text: string;
  className: string;
  textClass?: string;
  component?: ReactNode;
  toShowDropMenu?: () => void;
  ref?: any;
  closeMenu?: () => void;
  isWithoutArrow?: boolean;
}

export const CustomMenuItem = ({
  path,
  text,
  className,
  textClass,
  component,
  toShowDropMenu,
  closeMenu,
  isWithoutArrow,
  ref,
}: IProps) => {
  return (
    <>
      {path ? (
        <Link href={path} className={className} onClick={closeMenu}>
          <P2>{text}</P2>
        </Link>
      ) : (
        <div ref={ref} className={className} onClick={toShowDropMenu}>
          <P2 className={textClass}>{text}</P2>
          {!isWithoutArrow && 
          <Image 
            sizes="18px"
            unoptimized
            src={collapseArrow} alt={""} 
          />}
          {component}
        </div>
      )}
    </>
  );
};

export default CustomMenuItem;
