import { IFeedback } from "@villa/types/feedback";
import { Cookies } from "react-cookie";
import { agent } from "./agent";
import { BASE_SERVICE_URL } from "./global";

const LEADS_URL = `${BASE_SERVICE_URL}/leads`;
export const postFeedback = async (postData: IFeedback) => {
  const cookie = new Cookies();
  const utmCookie = cookie.get("utm") || {};

  return await agent.post(LEADS_URL, { ...postData, ...utmCookie });
};
