import React, { useId } from "react";
import Select, { Props as SelectProps } from "react-select";
import cn from "classnames";
import styles from "./InputSelect.module.scss";
import useValidationErrorTranslation from "@/lib/hooks/useValidationErrorTranslation";
import { FieldError } from "react-hook-form";
import { useTranslation } from "next-i18next";

interface IProps extends SelectProps {
  required?: boolean;
  error?: FieldError;
}

export const InputSelect = ({
  options,
  error,
  placeholder,
  required,
  ...props
}: IProps) => {
  const { t } = useTranslation("index");
  const getErrorTranslation = useValidationErrorTranslation();

  return (
    <div className={styles.selectWrapper}>
      <Select
        {...props}
        instanceId={useId()}
        options={options}
        placeholder={t((placeholder as string) || "") + (required ? " *" : "")}
        classNames={{
          control: () => cn(styles.control, error && styles.error),
          dropdownIndicator: (props) =>
            cn(styles.dropdownIndicator, props.selectProps.menuIsOpen && styles.open),
          indicatorSeparator: () => styles.indicatorSeparator,
          placeholder: () => styles.placeholder,
          singleValue: () => styles.singleValue,
          menu: () => styles.menu,
          option: (state) => cn(styles.option, state.isSelected && styles.selected),
          ...props.classNames,
        }}
      />
      {error && <p className={styles.errorText}>{getErrorTranslation(error)}</p>}
    </div>
  );
};

export default InputSelect;
