import { FC } from "react";
import { IClassname } from "@/lib/types/classname";

export const SvgPlain: FC<IClassname> = ({ className }) => {
  return (
    <svg
      className={className}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.7283 28.6338C28.7283 28.6338 21 23.9999 11.5 28.5516M2.21576 28.6375C2.21576 28.6375 10.977 27.1657 20.8633 30.6024M2 35.6265C16.7331 35.6265 26.1494 22.7504 45.4828 31.2651M28.7283 12C31.0245 12 32.8816 13.8571 32.8816 16.1533C32.8816 18.4496 31.0245 20.3067 28.7283 20.3067C26.432 20.3067 24.5749 18.4496 24.5749 16.1533C24.5749 13.8571 26.432 12 28.7283 12Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="22.93"
      />
    </svg>
  );
};
