import { FC } from "react";
import { IClassname } from "@/lib/types/classname";

export const SettingsIcon: FC<IClassname> = ({ className }) => {
  return (
    <svg
      className={className}
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 7L10.5 7M10.5 7C10.5 5.89543 9.60457 5 8.5 5C7.39543 5 6.5 5.89543 6.5 7M10.5 7C10.5 8.10457 9.60457 9 8.5 9C7.39543 9 6.5 8.10457 6.5 7M6.5 7L4 7M19 15L16.5 15M16.5 15C16.5 13.8954 15.6046 13 14.5 13C13.3954 13 12.5 13.8954 12.5 15M16.5 15C16.5 16.1046 15.6046 17 14.5 17C13.3954 17 12.5 16.1046 12.5 15M12.5 15H4"
        stroke="currentColor"
        strokeWidth="1.6"
      />
    </svg>
  );
};
