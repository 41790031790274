import { FC } from "react";
import { IClassname } from "@/lib/types/classname";

export const SvgParking: FC<IClassname> = ({ className }) => {
  return (
    <svg
      className={className}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.8478 29.0217V24.7174M21.8478 24.7174V20.1739H24.7174C25.5145 20.1739 27.1087 20.6522 27.1087 22.5652C27.1087 24.4783 25.4348 24.7174 24.7174 24.7174H21.8478ZM24 40C15.1638 40 8 32.8362 8 24C8 15.1638 15.1638 8 24 8C32.8362 8 40 15.1638 40 24C40 32.8362 32.8362 40 24 40ZM35.1304 24C35.1304 30.1472 30.1472 35.1304 24 35.1304C17.8528 35.1304 12.8696 30.1472 12.8696 24C12.8696 17.8528 17.8528 12.8696 24 12.8696C30.1472 12.8696 35.1304 17.8528 35.1304 24Z"
        stroke="currentColor"
        strokeWidth="2"
      />
    </svg>
  );
};
