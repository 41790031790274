import { FC } from "react";
import { IClassname } from "@/lib/types/classname";

export const SvgMountains: FC<IClassname> = ({ className }) => {
  return (
    <svg
      className={className}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 36H40M31.8867 33.5893L31.6903 33.2L29.2679 28.3941L25.6267 26.2681L24.0604 22.8845L20.4646 21.4696H18.8732L16.8687 24.4565L14.2449 25.4372L11.9887 30.4302L9.8231 33.3423L9.63676 33.5818M17.9566 18.9469L19.8351 16.7985L20.5955 14.1784L21.769 12L26.5534 13.572C27.0016 14.8222 27.4196 16.0049 27.9131 17.2102L31.5543 21.1028L34.8883 26.9418L37.2603 28.6636L39.1086 33.5743"
        stroke="currentColor"
        strokeWidth="2"
      />
    </svg>
  );
};
