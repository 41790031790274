import { IClassname } from "@/lib/types/classname";
import { FC } from "react";

export const UpSmallIcon: FC<IClassname> = ({ className }) => {
  return (
    <svg
      className={className}
      width="15"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3457 10L8.3457 7L5.3457 10"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="square"
      />
    </svg>
  );
};
