import { FC, ReactNode, useRef, useState } from "react";
import styles from "./SeparateHeaderModal.module.scss";
import { useObserver } from "@/lib/hooks/useObserver";
import { CircleButton } from "../Buttons";
import cn from "classnames";
import { ArrowIcon, CloseSign } from "../../icons";
import { modal } from "@/lib/ui/components/Modal";

interface SeparateHeaderModalProps {
  header: ReactNode;
  content: ReactNode;
  withScroller?: boolean;
}

export const SeparateHeaderModal: FC<SeparateHeaderModalProps> = ({
  header,
  content,
  withScroller,
}) => {
  const [isScrollerVisible, setIsScrollerVisible] = useState<boolean>();
  const endRef = useRef<HTMLDivElement | null>(null);
  useObserver({
    ref: endRef,
    isInterTrue: () => {
      setIsScrollerVisible(false);
    },
    isInterFalse: () => {
      setIsScrollerVisible(true);
    },
  });

  const onScrollerClick = () => {
    if (endRef.current) {
      endRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className={styles.separateHeaderModal}>
      <div className={styles.separateHeaderModal__content}>
        <div className={styles.separateHeaderModal__header}>
          <CircleButton
            as="button"
            theme="mixed"
            onClick={() => modal.close()}
            name="return button"
            content={<ArrowIcon />}
            className={styles.separateHeaderModal__backBtn}
          />
          {header}
          <CircleButton
            name="close modal"
            as="button"
            theme="light"
            className={styles.closeBtn}
            content={<CloseSign />}
            onClick={() => modal.close()}
          />
        </div>
        <div className={styles.separateHeaderModal__body}>
          {content}
          <div ref={endRef} />
          {withScroller && (
            <div
              onClick={onScrollerClick}
              className={cn(styles.scroller, !isScrollerVisible && styles.hidden)}
            />
          )}
        </div>
      </div>
    </div>
  );
};
