import { createSlice } from "@reduxjs/toolkit";
import * as I from "./interfaces";

const initialState: I.FilteredLayoutsState = {
    filters: {
        type: [],
        phaseNum: [],
        bedrooms: [],
    },
    // layoutsList: []
};

const slice = createSlice({
  name: "layouts",
  initialState,
  reducers: {
    setFilters(state, action: I.ISetFilteredLayoutsAction) {
      state.filters = action.payload;
    },
    // setLayoutsList(state, action: I.ISetLayoutsListAction) {
    //   state.layoutsList = action.payload;
    // },
    clear() {
      return initialState;
    },
  },
});

export const filteredLayoutsReducer = slice.reducer;
export const filteredLayoutsActions = slice.actions;
