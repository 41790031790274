import { IClassname } from "@/lib/types/classname";
import { FC, useId } from "react";
import { LinearGradient } from "./socials/LinearGradient";

export const HouseHeartIcon: FC<IClassname & {isGradient?: boolean, isDark?: boolean}> = ({className, isGradient, isDark}) => {
  const id = useId();
  const stroke = isGradient ? `url('#blueGradient${id}')` : "currentColor";
  return (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
    {isGradient && <LinearGradient id={id} isDark={isDark} />}
    <g id="House Heart">
    <path id="Vector" d="M11.6363 25.4546V38.5455C11.6363 38.9313 11.7896 39.3012 12.0623 39.574C12.3351 39.8468 12.7051 40 13.0909 40H34.909C35.2948 40 35.6648 39.8468 35.9376 39.574C36.2103 39.3012 36.3636 38.9313 36.3636 38.5455V26.1819" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path id="Vector_2" d="M7.27284 24.7273L24.0001 8L40.7274 24.7273" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path id="Vector_3" d="M29.8183 9.45459H34.1819C34.3748 9.45459 34.5598 9.53121 34.6962 9.6676C34.8326 9.80399 34.9092 9.98898 34.9092 10.1819V14.5455" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path id="Vector_4" d="M31.2727 26.3419C31.2727 30.1091 25.2494 34.6182 24.4436 35.2001L24.4087 35.2277C24.2902 35.3147 24.147 35.3616 24 35.3616C23.853 35.3616 23.7098 35.3147 23.5913 35.2277L23.5636 35.2001C22.7505 34.6182 16.7273 30.1091 16.7273 26.3419C16.6987 25.7954 16.7793 25.2488 16.9642 24.7338C17.1491 24.2189 17.4347 23.7459 17.8043 23.3424C18.1739 22.9389 18.6202 22.6131 19.117 22.3839C19.6138 22.1547 20.1513 22.0267 20.6982 22.0073C21.3748 22.0213 22.0359 22.2127 22.6154 22.5624C23.1948 22.9121 23.6723 23.4079 24 24.0001C24.3277 23.4079 24.8051 22.9121 25.3846 22.5624C25.964 22.2127 26.6251 22.0213 27.3018 22.0073C27.8486 22.0267 28.3861 22.1547 28.883 22.3839C29.3798 22.6131 29.8261 22.9389 30.1957 23.3424C30.5653 23.7459 30.8509 24.2189 31.0358 24.7338C31.2207 25.2488 31.3012 25.7954 31.2727 26.3419Z" 
    stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
  </svg>
  );
};
