import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import * as I from "./interfaces";
import {
  CatalogQuery,
  CatalogQueryParam,
  PredefinedCatalogQuery,
} from "../../../types/catalogPage";

const initialState: I.CatalogState = {
  isFixedFilter: false,
  properties: [],
  prices: [0, 0],
  filteredQuantity: 0,
  filteredPrices: [0, 0],
  filters: {},
};

const slice = createSlice({
  name: "catalog",
  initialState,
  reducers: {
    setFixedFilter(state, action: I.ISetFixedFilterAction) {
      state.isFixedFilter = action.payload;
    },
    setProperties(state, action: I.ISetPropertiesAction) {
      state.properties = action.payload;
    },
    setPriceRange(state, action: I.ISetPropertiesPriceRange) {
      state.prices = action.payload;
    },
    setFilteredPriceRange(state, action: I.ISetPropertiesPriceRange) {
      state.filteredPrices = action.payload;
    },
    setFilteredQuantity(state, action: I.ISetQuantityAction) {
      state.filteredQuantity = action.payload;
    },
    setParamState(
      state,
      action: I.ISetParamAction<Partial<PredefinedCatalogQuery>>,
    ) {
      state.filters = {
        ...state.filters,
        ...action.payload,
      };
    },
    clearParamState(state) {
      state.filters = initialState.filters;
    },
    clear() {
      return initialState;
    },
  },
});

export const catalogReducer = slice.reducer;
export const catalogActions = slice.actions;
