import { MutableRefObject } from "react";
import { CustomSelect } from ".";
import { IValueLabel } from "@/lib/types/mainPage";

interface IDropDown <T = boolean>{
  placeholder: string;
  options: IValueLabel[];
  selectedValuesHandler: (options: T extends true ? IValueLabel : IValueLabel[], ...args: any) => void;
  reference?: MutableRefObject<HTMLDivElement>;
  defaultValue?: { value: string | number }[];
  value?: { value: string | number }[];
  theme?: "light" | "dark";
  menuIsOpen?: boolean;
  className?: string;
  size?: 'lg' | 'md' | 'sm'
  disableMulti?: T;
  shouldHaveIndicator?: boolean;
}
export function DropDownMulti <T>({
  placeholder,
  options,
  selectedValuesHandler,
  reference,
  defaultValue,
  value,
  theme,
  menuIsOpen,
  className,
  disableMulti,
  size,
  shouldHaveIndicator,
}: IDropDown<T>) {
  return (
    <CustomSelect
      className={className}
      defaultValue={defaultValue}
      ref={reference}
      value={value}
      options={options}
      isMulti
      placeholder={placeholder}
      onChange={selectedValuesHandler}
      theme={theme}
      menuIsOpen={menuIsOpen}
      size={size}
      shouldHaveIndicator={shouldHaveIndicator}
      disableMulti={disableMulti as boolean}
    />
  );
};
