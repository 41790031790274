import { FC } from "react";
import { IClassname } from "@/lib/types/classname";

export const SvgBeach: FC<IClassname> = ({ className }) => {
  return (
    <svg
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.23798 27.3303C5.01787 27.3303 5.76582 27.0443 6.31728 26.5352C6.86875 26.0262 7.17856 25.3358 7.17856 24.6159C7.17856 25.3358 7.48837 26.0262 8.03983 26.5352C8.5913 27.0443 9.33925 27.3303 10.1191 27.3303C10.899 27.3303 11.647 27.0443 12.1984 26.5352C12.7499 26.0262 13.0597 25.3358 13.0597 24.6159C13.0597 25.3358 13.3695 26.0262 13.921 26.5352C14.4725 27.0443 15.2204 27.3303 16.0003 27.3303C16.7802 27.3303 17.5281 27.0443 18.0796 26.5352C18.6311 26.0262 18.9409 25.3358 18.9409 24.6159C18.9409 25.3358 19.2507 26.0262 19.8022 26.5352C20.3536 27.0443 21.1016 27.3303 21.8815 27.3303C22.6614 27.3303 23.4093 27.0443 23.9608 26.5352C24.5122 26.0262 24.8221 25.3358 24.8221 24.6159C24.8221 25.3358 25.1319 26.0262 25.6833 26.5352C26.2348 27.0443 26.9827 27.3303 27.7626 27.3303"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6528 6.7943C11.658 7.18668 10.7502 7.77116 9.98134 8.51437C9.21243 9.25757 8.59745 10.1449 8.17149 11.1258C7.74553 12.1067 7.51694 13.1619 7.49877 14.2311C7.4806 15.3003 7.67321 16.3626 8.06559 17.3574L15.6407 14.3695M12.6528 6.7943C13.6476 6.40191 14.7099 6.20931 15.7791 6.22748C16.8483 6.24565 17.9035 6.47424 18.8844 6.9002C19.8652 7.32616 20.7526 7.94114 21.4958 8.71004C22.239 9.47894 22.8235 10.3867 23.2159 11.3815L15.6407 14.3695M12.6528 6.7943L11.9541 4.98253M15.6407 14.3695L18.1968 20.9973M18.1968 20.9973L25.8877 20.9973M18.1968 20.9973L13.2205 20.9973"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
