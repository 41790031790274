import { FC } from "react";
import { IClassname } from "@/lib/types/classname";

export const SvgShuttle: FC<IClassname> = ({ className }) => {
  return (
    <svg
      className={className}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.2383 31.8628H29.726"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.3087 32H40.4348C40.8499 32 41.248 31.857 41.5416 31.6025C41.8351 31.348 42 31.0028 42 30.6429V23.8571C42.0001 22.9649 41.7606 22.0848 41.3003 21.2867L38.087 15.7143C37.6166 14.8987 36.8937 14.2128 35.9991 13.7334C35.1045 13.2539 34.0736 13 33.0219 13H9.13043C8.30019 13 7.50395 13.286 6.91688 13.795C6.32981 14.304 6 14.9944 6 15.7143V30.6429C6 31.0028 6.16491 31.348 6.45844 31.6025C6.75198 31.857 7.1501 32 7.56522 32H9.56087"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.3105 30.3453C29.3105 31.1501 29.6303 31.922 30.1994 32.4911C30.7685 33.0603 31.5404 33.38 32.3453 33.38C33.1501 33.38 33.922 33.0603 34.4911 32.4911C35.0603 31.922 35.38 31.1501 35.38 30.3453C35.38 29.5404 35.0603 28.7685 34.4911 28.1994C33.922 27.6303 33.1501 27.3105 32.3453 27.3105C31.5404 27.3105 30.7685 27.6303 30.1994 28.1994C29.6303 28.7685 29.3105 29.5404 29.3105 30.3453Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.58594 30.3453C9.58594 31.1501 9.90567 31.922 10.4748 32.4911C11.0439 33.0603 11.8158 33.38 12.6207 33.38C13.4255 33.38 14.1974 33.0603 14.7665 32.4911C15.3357 31.922 15.6554 31.1501 15.6554 30.3453C15.6554 29.5404 15.3357 28.7685 14.7665 28.1994C14.1974 27.6303 13.4255 27.3105 12.6207 27.3105C11.8158 27.3105 11.0439 27.6303 10.4748 28.1994C9.90567 28.7685 9.58594 29.5404 9.58594 30.3453Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.4501 28.0696L37.6567 24.7572C36.1864 23.4707 34.2996 22.7607 32.3459 22.7588H6.55078"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 22L17 13"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30 22L30 14"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
