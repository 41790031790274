import cl from "classnames";
import styles from "./socials.module.scss";
import { FC, useId } from "react";
import { LinearGradient } from "./LinearGradient";
import { ISocialIcon } from "@/lib/types/social-icons";

export const SvgVk: FC<ISocialIcon> = ({ className, isGradient }) => {
  const id = useId();
  const fill = isGradient ? `url('#blueGradient${id}')` : "#848C98";
  return (
    <svg
      className={cl(styles.socials, className)}
      width="24"
      height="20"
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <LinearGradient id={id} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.435 3.97222C23.613 3.42389 23.435 3.02528 22.6475 3.02528H20.029C19.3685 3.02528 19.0635 3.37431 18.8855 3.74764C18.8855 3.74764 17.5385 6.93653 15.6575 9.00396C15.0475 9.60187 14.768 9.80118 14.4375 9.80118C14.2595 9.80118 14.0305 9.60187 14.0305 9.05403V3.94646C14.0305 3.29896 13.8275 3 13.268 3H9.15001C8.74301 3 8.48901 3.29896 8.48901 3.59792C8.48901 4.22063 9.42951 4.37035 9.53101 6.11403V9.90278C9.53101 10.7248 9.38101 10.875 9.04801 10.875C8.15851 10.875 5.99801 7.66132 4.70201 3.99944C4.45001 3.27417 4.19401 3 3.533 3H0.889501C0.127 3 0 3.34854 0 3.72236C0 4.39514 0.889501 7.78285 4.14301 12.2667C6.30351 15.3292 9.37901 16.9752 12.1495 16.9752C13.8275 16.9752 14.0305 16.6014 14.0305 15.9787V13.6619C14.0305 12.9147 14.183 12.7903 14.717 12.7903C15.098 12.7903 15.7845 12.9891 17.335 14.4591C19.114 16.2028 19.419 17 20.4105 17H23.0285C23.791 17 24.147 16.6262 23.9435 15.9038C23.715 15.1815 22.8505 14.1353 21.732 12.8899C21.122 12.1928 20.207 11.4204 19.9275 11.0466C19.5465 10.5483 19.648 10.349 19.9275 9.90083C19.902 9.90083 23.105 5.46653 23.435 3.97028"
        fill={fill}
      />
    </svg>
  );
};
