const I18NextHttpBackend = require("i18next-http-backend/cjs");

const loadPath =
  process.env.NEXT_PUBLIC_LOCAL === "local"
    ? `http://localhost:3000/locales/{{lng}}/{{ns}}.json`
    : `https://${process.env.NEXT_PUBLIC_URL}/store/locales/{{lng}}/{{ns}}.json`;
/**
 * @type {import('next-i18next').UserConfig}
 */
module.exports = {
  i18n: {
    locales: ["default", "en", "ru"],
    defaultLocale: "default",
    localeDetection: false,
  },
  ns: ["index"],
  use: [I18NextHttpBackend],
  serializeConfig: false, // because of the custom use i18next plugin
  backend: {
    loadPath,
  },
};
