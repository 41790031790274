import { FC } from "react";
import { IClassname } from "@/lib/types/classname";

export const PlusSign: FC<IClassname> = ({ className }) => {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8 0V16M0 8H16" stroke="currentColor" />
    </svg>
  );
};
