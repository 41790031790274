import { FC } from 'react';
import { IClassname } from '@/lib/types/classname';

export const SVGWhatsappColor: FC<IClassname> = ({ className }) => {
    return (
        <svg className={className} width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="48" height="48" rx="24" fill="url(#paint0_linear_2950_1827)"/>
<g clipPath="url(#clip0_2950_1827)">
<path d="M12.4189 34.5736L14.0579 28.6249C13.0436 26.8853 12.5106 24.9063 12.5106 22.8872C12.5164 16.5564 17.6911 11.4062 24.0522 11.4062C27.141 11.4062 30.035 12.604 32.2126 14.7713C34.3903 16.9386 35.588 19.8245 35.588 22.8929C35.588 29.2237 30.4075 34.3739 24.0522 34.3739H24.0464C22.1152 34.3739 20.2184 33.8891 18.5336 32.9766L12.4189 34.5736Z" 
fill="url(#paint1_linear_2950_1827)"/>
<path d="M12 34.9961L13.6963 28.8364C12.6476 27.0284 12.0974 24.9809 12.1032 22.8877C12.1032 16.3288 17.467 10.9961 24.0516 10.9961C27.2493 10.9961 30.2521 12.2337 32.5043 14.4809C34.7622 16.728 36.0057 19.7166 36 22.8934C36 29.4524 30.6361 34.7851 24.0458 34.7851H24.0401C22.0401 34.7851 20.0745 34.2832 18.3266 33.3364L12 34.9961ZM18.6304 31.1862L18.9914 31.4029C20.5158 32.3041 22.2636 32.7775 24.0458 32.7832H24.0516C29.5243 32.7832 33.9828 28.3516 33.9828 22.8991C33.9828 20.2585 32.9513 17.7775 31.0773 15.9067C29.2034 14.036 26.7049 13.0094 24.0516 13.0094C18.5788 13.0037 14.1203 17.4353 14.1203 22.8877C14.1203 24.7528 14.6418 26.5721 15.639 28.1463L15.8739 28.5227L14.8711 32.1672L18.6304 31.1862Z" 
fill="white"/>
<path fillRule="evenodd" clipRule="evenodd" d="M21.0655 17.9135C20.842 17.4173 20.6071 17.4059 20.395 17.4002C20.2231 17.3945 20.0225 17.3945 19.822 17.3945C19.6214 17.3945 19.3005 17.4687 19.0254 17.7653C18.7503 18.0618 17.9824 18.7805 17.9824 20.2462C17.9824 21.7063 19.0541 23.1208 19.2031 23.3204C19.352 23.52 21.2718 26.617 24.3033 27.809C26.8248 28.8014 27.3406 28.6018 27.885 28.5504C28.4294 28.4991 29.65 27.8318 29.9022 27.136C30.1486 26.4402 30.1486 25.847 30.0741 25.7215C29.9996 25.5961 29.799 25.5219 29.501 25.3736C29.203 25.2253 27.736 24.5067 27.4609 24.404C27.1859 24.3071 26.9853 24.2557 26.7904 24.5523C26.5899 24.8489 26.0168 25.5162 25.8449 25.7158C25.673 25.9154 25.4953 25.9383 25.1973 25.79C24.8993 25.6417 23.9366 25.328 22.7962 24.3128C21.9079 23.5257 21.3062 22.5504 21.1343 22.2538C20.9624 21.9573 21.1171 21.7976 21.2661 21.6493C21.3979 21.5181 21.5641 21.3014 21.7131 21.1303C21.8621 20.9592 21.9137 20.8337 22.0111 20.6341C22.1085 20.4345 22.0626 20.2634 21.9881 20.1151C21.9137 19.9725 21.3291 18.501 21.0655 17.9135Z" 
fill="white"/>
</g>
<defs>
<linearGradient id="paint0_linear_2950_1827" x1="24" y1="0" x2="24" y2="48" gradientUnits="userSpaceOnUse">
<stop stopColor="#44E55B"/>
<stop offset="1" stopColor="#2ECA45"/>
</linearGradient>
<linearGradient id="paint1_linear_2950_1827" x1="24.0035" y1="11.4062" x2="24.0035" y2="34.5736" gradientUnits="userSpaceOnUse">
<stop stopColor="#44E55B"/>
<stop offset="1" stopColor="#2ECA45"/>
</linearGradient>
<clipPath id="clip0_2950_1827">
<rect width="24" height="24" fill="white" transform="translate(12 11.0039)"/>
</clipPath>
</defs>
</svg>
)}
