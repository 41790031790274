import { FC } from "react";
import { IClassname } from "@/lib/types/classname";

export const TeamIcon: FC<IClassname> = ({ className }) => {
  return (
    <svg
      className={className}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 31C7 31.5253 7.10346 32.0454 7.30448 32.5307C7.5055 33.016 7.80014 33.457 8.17157 33.8284C8.54301 34.1999 8.98396 34.4945 9.46927 34.6955C9.95457 34.8965 10.4747 35 11 35C11.5253 35 12.0454 34.8965 12.5307 34.6955C13.016 34.4945 13.457 34.1999 13.8284 33.8284C14.1999 33.457 14.4945 33.016 14.6955 32.5307C14.8965 32.0454 15 31.5253 15 31C15 30.4747 14.8965 29.9546 14.6955 29.4693C14.4945 28.984 14.1999 28.543 13.8284 28.1716C13.457 27.8001 13.016 27.5055 12.5307 27.3045C12.0454 27.1035 11.5253 27 11 27C10.4747 27 9.95457 27.1035 9.46927 27.3045C8.98396 27.5055 8.54301 27.8001 8.17157 28.1716C7.80014 28.543 7.5055 28.984 7.30448 29.4693C7.10346 29.9546 7 30.4747 7 31Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 40C17.5695 39.0925 16.9179 38.2836 16.0934 37.6332C15.2689 36.9827 14.2926 36.5074 13.2366 36.2422C12.1806 35.9771 11.0719 35.9289 9.9924 36.1012C8.91292 36.2736 7.89027 36.6621 7 37.238"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33 31C33 32.0609 33.4214 33.0783 34.1716 33.8284C34.9217 34.5786 35.9391 35 37 35C38.0609 35 39.0783 34.5786 39.8284 33.8284C40.5786 33.0783 41 32.0609 41 31C41 29.9391 40.5786 28.9217 39.8284 28.1716C39.0783 27.4214 38.0609 27 37 27C35.9391 27 34.9217 27.4214 34.1716 28.1716C33.4214 28.9217 33 29.9391 33 31Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30 40C30.4305 39.0925 31.0821 38.2836 31.9066 37.6332C32.7311 36.9827 33.7074 36.5074 34.7634 36.2422C35.8194 35.9771 36.9281 35.9289 38.0076 36.1012C39.0871 36.2736 40.1097 36.6621 41 37.238"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 30C19 31.3261 19.5268 32.5979 20.4645 33.5355C21.4021 34.4732 22.6739 35 24 35C25.3261 35 26.5979 34.4732 27.5355 33.5355C28.4732 32.5979 29 31.3261 29 30C29 28.6739 28.4732 27.4021 27.5355 26.4645C26.5979 25.5268 25.3261 25 24 25C22.6739 25 21.4021 25.5268 20.4645 26.4645C19.5268 27.4021 19 28.6739 19 30Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30 40C29.2396 39.055 28.3132 38.3 27.28 37.7834C26.2468 37.2668 25.1296 37 24 37C22.8704 37 21.7532 37.2668 20.72 37.7834C19.6868 38.3 18.7604 39.055 18 40"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.7107 8.46953L26.3791 12.0182H29.2193C29.3731 12.0189 29.5233 12.0684 29.6512 12.1607C29.7791 12.253 29.8791 12.3839 29.9387 12.5371C29.9983 12.6903 30.0149 12.8591 29.9864 13.0225C29.9579 13.1858 29.8856 13.3365 29.7785 13.4557L27.1655 16.2306L28.6105 19.8174C28.6761 19.9818 28.6917 20.1644 28.6553 20.3391C28.6189 20.5137 28.5322 20.6714 28.4077 20.7895C28.2832 20.9076 28.1273 20.9801 27.9623 20.9964C27.7974 21.0128 27.6319 20.9723 27.4895 20.8807L24.0026 18.7583L20.5104 20.8765C20.3681 20.9681 20.2026 21.0086 20.0377 20.9922C19.8727 20.9758 19.7168 20.9034 19.5923 20.7853C19.4678 20.6672 19.3811 20.5095 19.3447 20.3348C19.3082 20.1602 19.3239 19.9775 19.3895 19.8131L20.8344 16.2263L18.2215 13.4515C18.1143 13.3322 18.042 13.1814 18.0135 13.0179C17.9851 12.8544 18.0018 12.6855 18.0615 12.5322C18.1213 12.3789 18.2215 12.248 18.3496 12.1559C18.4777 12.0637 18.6281 12.0144 18.782 12.014H21.6209L23.2945 8.46953C23.3609 8.32825 23.4623 8.20961 23.5872 8.12674C23.7122 8.04387 23.856 8 24.0026 8C24.1492 8 24.293 8.04387 24.418 8.12674C24.543 8.20961 24.6443 8.32825 24.7107 8.46953Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.033 14.3251L13.2843 16.7818H15.4145C15.5298 16.7823 15.6425 16.8166 15.7384 16.8805C15.8344 16.9444 15.9093 17.035 15.954 17.1411C15.9988 17.2471 16.0112 17.364 15.9898 17.4771C15.9684 17.5902 15.9142 17.6945 15.8338 17.7771L13.8742 19.6981L14.9579 22.1812C15.007 22.2951 15.0188 22.4215 14.9915 22.5424C14.9642 22.6634 14.8991 22.7725 14.8058 22.8543C14.7124 22.9361 14.5955 22.9862 14.4717 22.9975C14.348 23.0089 14.2239 22.9808 14.1172 22.9174L11.502 21.448L8.88283 22.9145C8.77609 22.9779 8.65196 23.0059 8.52825 22.9946C8.40453 22.9833 8.2876 22.9331 8.19422 22.8514C8.10085 22.7696 8.03584 22.6604 8.00851 22.5395C7.98118 22.4186 7.99295 22.2921 8.04213 22.1783L9.12584 19.6951L7.16615 17.7741C7.08573 17.6915 7.03148 17.5871 7.01014 17.4739C6.9888 17.3607 7.00132 17.2438 7.04614 17.1377C7.09097 17.0316 7.16611 16.9409 7.2622 16.8771C7.35829 16.8133 7.47108 16.7792 7.5865 16.7789H9.7157L10.9709 14.3251C11.0207 14.2273 11.0967 14.1451 11.1904 14.0877C11.2842 14.0304 11.392 14 11.502 14C11.6119 14 11.7197 14.0304 11.8135 14.0877C11.9072 14.1451 11.9832 14.2273 12.033 14.3251Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.033 14.3251L38.2843 16.7818H40.4145C40.5298 16.7823 40.6425 16.8166 40.7384 16.8805C40.8344 16.9444 40.9093 17.035 40.954 17.1411C40.9988 17.2471 41.0112 17.364 40.9898 17.4771C40.9684 17.5902 40.9142 17.6945 40.8338 17.7771L38.8742 19.6981L39.9579 22.1812C40.007 22.2951 40.0188 22.4215 39.9915 22.5424C39.9642 22.6634 39.8991 22.7725 39.8058 22.8543C39.7124 22.9361 39.5955 22.9862 39.4717 22.9975C39.348 23.0089 39.2239 22.9808 39.1172 22.9174L36.502 21.448L33.8828 22.9145C33.7761 22.9779 33.652 23.0059 33.5282 22.9946C33.4045 22.9833 33.2876 22.9331 33.1942 22.8514C33.1008 22.7696 33.0358 22.6604 33.0085 22.5395C32.9812 22.4186 32.993 22.2921 33.0421 22.1783L34.1258 19.6951L32.1661 17.7741C32.0857 17.6915 32.0315 17.5871 32.0101 17.4739C31.9888 17.3607 32.0013 17.2438 32.0461 17.1377C32.091 17.0316 32.1661 16.9409 32.2622 16.8771C32.3583 16.8133 32.4711 16.7792 32.5865 16.7789H34.7157L35.9709 14.3251C36.0207 14.2273 36.0967 14.1451 36.1904 14.0877C36.2842 14.0304 36.392 14 36.502 14C36.6119 14 36.7197 14.0304 36.8135 14.0877C36.9072 14.1451 36.9832 14.2273 37.033 14.3251Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
