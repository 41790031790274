import { FC } from "react";
import { IClassname } from "@/lib/types/classname";

export const SvgBuildingStage: FC<IClassname> = ({ className }) => {
  return (
    <svg className={className} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18 28H4" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M6 28V22.375C6.02116 22.0218 6.17783 21.6886 6.44 21.439L9.31765 19H12.8471L15.5706 21.4289C15.8273 21.6851 15.9797 22.0207 16 22.375V28" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M11.6085 8.26666C11.8955 8.26666 12.364 8.30333 12.652 8.35L28 9.99999V12.1667C28 12.3877 27.945 12.5996 27.8472 12.7559C27.7493 12.9122 27.6166 13 27.4783 13H4.52174C4.38337 13 4.25066 12.9122 4.15281 12.7559C4.05497 12.5996 4 12.3877 4 12.1667V9.99999L8.5 8.5C8.83338 8.35667 9.1764 8.27832 9.52157 8.26666H11.6085Z" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M4 10L10.5395 4.38178C10.7766 4.19958 11.0606 4.07716 11.3639 4.02641C11.6672 3.97566 11.9795 3.9983 12.2706 4.09214L28 10" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M25.5 10V17.0276C25.4999 17.2315 25.5566 17.431 25.6632 17.6017C25.7698 17.7723 25.9216 17.9067 26.1 17.9883C26.3478 18.1016 26.5618 18.283 26.7193 18.5133C26.8768 18.7436 26.972 19.0142 26.9947 19.2965C27.0174 19.5788 26.9669 19.8623 26.8484 20.117C26.7299 20.3717 26.5479 20.5881 26.3217 20.7433C26.0954 20.8984 25.8334 20.9867 25.5632 20.9986C25.293 21.0105 25.0247 20.9457 24.7868 20.811C24.5489 20.6763 24.3502 20.4768 24.2117 20.2334C24.0732 19.9901 24 19.7121 24 19.4288" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M9 14L13 18" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M13 23L9 26" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M17 9V13" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M12 4V8" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M13 9V28" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M9 28V9" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

  );
};
