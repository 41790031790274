import { FC } from "react";
import { IClassname } from "@/lib/types/classname";

export const SvgBangTao: FC<IClassname> = ({ className }) => {
  return (
    <svg className={className} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 26L26 26" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M4 17L14 13" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M5 17V26" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M12 5L28 15" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M26 14V26" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M23 11.5598V7.58354C23 7.42878 23.079 7.28035 23.2197 7.17092C23.3603 7.06148 23.5511 7 23.75 7H25.25C25.4489 7 25.6397 7.06148 25.7803 7.17092C25.921 7.28035 26 7.42878 26 7.58354V13" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M23.5 26C23.6326 26 23.7598 25.9473 23.8536 25.8536C23.9473 25.7598 24 25.6326 24 25.5V22.5C24 22.3674 23.9473 22.2402 23.8536 22.1464C23.7598 22.0527 23.6326 22 23.5 22H17.5C17.3674 22 17.2402 22.0527 17.1464 22.1464C17.0527 22.2402 17 22.3674 17 22.5V25.5C17 25.6326 17.0527 25.7598 17.1464 25.8536C17.2402 25.9473 17.3674 26 17.5 26" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M21 22V26" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M18 14.5C18 14.697 18.0388 14.892 18.1142 15.074C18.1896 15.256 18.3001 15.4214 18.4393 15.5607C18.5786 15.6999 18.744 15.8104 18.926 15.8858C19.108 15.9612 19.303 16 19.5 16C19.697 16 19.892 15.9612 20.074 15.8858C20.256 15.8104 20.4214 15.6999 20.5607 15.5607C20.6999 15.4214 20.8104 15.256 20.8858 15.074C20.9612 14.892 21 14.697 21 14.5C21 14.1022 20.842 13.7206 20.5607 13.4393C20.2794 13.158 19.8978 13 19.5 13C19.1022 13 18.7206 13.158 18.4393 13.4393C18.158 13.7206 18 14.1022 18 14.5Z" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M11.5 26C11.6326 26 11.7598 25.9368 11.8536 25.8243C11.9473 25.7117 12 25.5591 12 25.4V20.6C12 20.4409 11.9473 20.2883 11.8536 20.1757C11.7598 20.0632 11.6326 20 11.5 20H8.5C8.36739 20 8.24021 20.0632 8.14645 20.1757C8.05268 20.2883 8 20.4409 8 20.6V25.4C8 25.5591 8.05268 25.7117 8.14645 25.8243C8.24021 25.9368 8.36739 26 8.5 26" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M14 7V26" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    
  );
};
