import React from "react";
import Link from "next/link";
import { useTranslation } from "next-i18next";
import styles from "./Footer.module.scss";
import { Logo } from "../../components/Logo";
import { RequestCallNew } from "@/lib/ui/components/Forms";
import { H3, P2, P3 } from "../../components/Typography";
import { SocialNetworks } from "@/lib/ui/components/SocialNetworks";
import { PHONE, PHONE_FORMATTED, SALES_EMAIL } from "@/lib/constants/contacts";
import { useRouter } from "next/router";
import cn from "classnames";
import Image from "next/image";
import { sendEmailClick, sendPhoneClick } from "@/lib/utils/sendGTM";

export const Footer: React.FC = () => {
  const { asPath } = useRouter();
  const { t, i18n: {language} } = useTranslation("index");

  const linkPages = [
    { title: t("aboutTheCompany"), link: "/about" },
    { title: t("contacts"), link: "/contacts" },
    { title: t("blog"), link: `https://villacarte.com/${language}/blog` },
    { title: "FAQ", link: `https://villacarte.com/${language}/company/faq` },
    { title: t("sale"), link: "/sale/catalog" },
    { title: t("rent"), link: `https://villacarte.com/${language}/rent` },
    { title: t("propertyManagement"), link: "/propertymanagement" },
    { title: t("concierge"), link: `https://villacarte.com/${language}/concierge` },
    { title: t("clubVillaCarte"), link: `https://villacarte.com/${language}/company/loyalty-program` },
  ];

  return (
    <footer
      className={cn(
        styles.footer,
        // asPath.includes("/rent/") && styles.footer_bottomMargin,
      )}
    >
      <div className={styles.footer__container}>
        <div className={styles.footer_noise} />
        <div className={styles.footer_ellipse} />
        <Image
          src={"/image.webp"}
          width={1505}
          height={505}
          alt=""
          className={styles.footer_leaves}
          sizes="(max-width: 1200px) 0vw, 1035px"
        />
        <div className={styles.footer__wrapper}>
          <div className={styles.footer__contactsBlock}>
            <div>
              <div className={styles.footer__logotype}>
                <Logo size="lg" />
              </div>
              <div className={styles.footer__contactsWrapper}>
                <div className={styles.footer__contactsLeft}>
                  <a
                    className={styles.footer__contactMail}
                    href={`mailto:${SALES_EMAIL}`}
                    onClick={sendEmailClick}
                  >
                    <H3 as='p'>{SALES_EMAIL}</H3>
                  </a>
                  <div className={styles.footer__contactWrapper}>
                    <div className={styles.footer__contact}>
                      <P3 className={styles.footer__text}>{t("phone") || ""}</P3>
                      <a href={`tel:${PHONE}`} onClick={sendPhoneClick}>
                        <P2 className={styles.p2}>{PHONE_FORMATTED}</P2>
                      </a>
                    </div>
                  </div>
                </div>
                <div className={styles.footer__contactsRight}>
                  <div className={styles.footer__linkPages}>
                    {linkPages.slice(4).map((item, index) => (
                      <Link href={item.link} key={index}>
                        <P3 className={styles.footer__text}>{item.title}</P3>
                      </Link>
                    ))}
                  </div>
                  <div className={styles.footer__linkPages}>
                    {linkPages.slice(0, 4).map((item, index) => (
                      <Link href={item.link} key={index}>
                        <P3 className={styles.footer__text}>{item.title}</P3>
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
              <SocialNetworks className={styles.footer__socials} />

              <div className={styles.footer__privacyPolicy}>
                <Link href={`https://villacarte.com/${language}/cookie`} className={styles.footer__privacyPolicyLink} target="_blank">
                  <P3>{t("privacyPolicy")}</P3>
                </Link>
                <Link href={`https://villacarte.com/${language}/terms`} className={styles.footer__privacyPolicyLink} target="_blank">
                  <P3>{t("termsAndConditions")}</P3>
                </Link>
                <Link href={`https://villacarte.com/${language}/cancellation`} className={styles.footer__privacyPolicyLink} target="_blank">
                  <P3>{t("howToCancelTheOrder")}</P3>
                </Link>
              </div>
            </div>

            <div className={styles.footer__divider} />
            <P3 className={styles.footer__copyRights}>{t("VillaCarteAllRights")}</P3>
          </div>

          <div className={styles.footer__formWrapper}>
            <RequestCallNew
              theme="blue"
              title="orderAnIndividualSelection"
              className={styles.footer__form}
              sourceForm="footer-form"
              eventName={['footer']}
            />
          </div>
        </div>
      </div>
    </footer>
  );
};
