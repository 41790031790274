import { FC } from "react";
import { IClassname } from "@/lib/types/classname";

export const SvgPublicGarden: FC<IClassname> = ({ className }) => {
  return (
    <svg
      className={className}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.4961 36.2269C29.3241 36.7424 28.9941 37.1907 28.5529 37.5081C28.1117 37.8255 27.5818 37.9959 27.0383 37.9951H24.3025C23.759 37.9959 23.2291 37.8255 22.7879 37.5081C22.3468 37.1907 22.0168 36.7424 21.8448 36.2269L20.1347 31.085C20.1023 30.9878 20.0935 30.8842 20.109 30.7828C20.1245 30.6814 20.1639 30.5852 20.2239 30.5021C20.2839 30.419 20.3629 30.3513 20.4542 30.3046C20.5455 30.258 20.6466 30.2338 20.7491 30.2339H30.5969C30.6994 30.2338 30.8005 30.258 30.8918 30.3046C30.9831 30.3513 31.0621 30.419 31.1221 30.5021C31.1821 30.5852 31.2215 30.6814 31.237 30.7828C31.2525 30.8842 31.2437 30.9878 31.2113 31.085L29.4961 36.2269Z"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path
        d="M28.2845 19.8782C26.5046 21.0669 25.5862 23.2414 28.2845 25.0523C29.7643 28.7519 32.5157 27.7571 34.1443 26.0406C35.6112 24.4884 36.4481 20.4551 35.7198 17.7529C35.6943 17.6552 35.6463 17.5648 35.5795 17.489C35.5128 17.4132 35.4293 17.3541 35.3356 17.3163C35.2419 17.2786 35.1407 17.2634 35.0401 17.2718C34.9395 17.2802 34.8422 17.3121 34.7561 17.3648C32.4226 18.8136 30.3128 18.5264 28.2845 19.8782Z"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path
        d="M23.1361 14.6586C25.0519 16.9132 26.0647 19.878 21.4972 21.8714C19.4082 26.5359 16.2286 24.809 14.1137 22.3163C12.1475 19.9996 11.3106 14.0687 12.6998 10.417C12.7349 10.3247 12.7907 10.2416 12.8629 10.1741C12.9352 10.1067 13.0219 10.0567 13.1164 10.0279C13.211 9.99921 13.3108 9.99253 13.4084 10.0084C13.5059 10.0243 13.5985 10.0623 13.6791 10.1195C16.9918 12.4699 20.8867 12.0081 23.1361 14.6586Z"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path
        d="M31.9828 22.0975C27.5459 24.316 25.3262 30.2339 25.3262 30.2339C23.7647 24.3448 20.5494 19.0261 16.0605 14.9067"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="bevel"
      />
    </svg>
  );
};
