import { IClassname } from "@/lib/types/classname";
import { FC } from "react";

export const MapLocationIcon: FC<IClassname> = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="56"
      height="69"
      viewBox="0 0 56 69"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.5067 66.7259L46.9804 47.1724C57.691 36.418 57.691 18.8201 46.9804 8.06579C36.2698 -2.6886 18.7435 -2.6886 8.03294 8.06579C-2.67765 18.8202 -2.67765 36.4182 8.03294 47.1724L27.5066 66.7259H27.5067Z"
        fill="#4074A5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.5066 68.5005L12.0674 51.4496L14.6252 48.6248C16.9369 51.1777 18.6108 53.3085 21.7613 54.7585C23.615 55.6167 25.5688 56.0291 27.5066 56.0266C29.4444 56.0291 31.3982 55.6167 33.2519 54.7585C36.4025 53.3085 38.0764 51.1777 40.388 48.6248L42.9458 51.4496L27.5066 68.5005Z"
        fill="#284968"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.5066 45.0754L36.1756 36.3388C40.9435 31.5336 40.9435 23.6708 36.1756 18.8656C31.4076 14.0604 23.6057 14.0604 18.8377 18.8656C14.0697 23.6707 14.0697 31.5335 18.8377 36.3388L27.5067 45.0754H27.5066Z"
        fill="white"
      />
    </svg>
  );
};
