import { FC } from "react";
import { IClassname } from "@/lib/types/classname";

export const SvgRestroom: FC<IClassname> = ({ className }) => {
  return (
    <svg
      className={className}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35 20.6667C35 21.0203 34.842 21.3594 34.5607 21.6095C34.2794 21.8595 33.8978 22 33.5 22C33.1022 22 32.7206 21.8595 32.4393 21.6095C32.158 21.3594 32 21.0203 32 20.6667C32.3261 19.7086 32.8334 18.8068 33.5 18C34.1666 18.8068 34.6739 19.7086 35 20.6667Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28 30V28.6667C28 27.9594 28.295 27.2811 28.8201 26.781C29.3452 26.281 30.0574 26 30.8 26H35"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 30V28.6667C20 27.9594 19.7471 27.2811 19.2971 26.781C18.847 26.281 18.2365 26 17.6 26H14"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5476 30C10.4513 30.0002 10.3567 30.0264 10.2733 30.076C10.19 30.1256 10.1209 30.1968 10.0729 30.2826C10.0249 30.3683 9.99977 30.4655 10 30.5644C10.0002 30.6632 10.0258 30.7603 10.0742 30.8458C11.5054 33.3197 13.5364 35.3711 15.9695 36.8001C18.4025 38.229 21.1547 38.9869 23.9579 38.9998C26.7611 39.0128 29.5197 38.2804 31.9652 36.8739C34.4107 35.4675 36.4596 33.435 37.9124 30.9743C37.9688 30.8767 37.999 30.7656 38 30.6522C38.0009 30.5387 37.9726 30.4271 37.9179 30.3285C37.8632 30.2299 37.7841 30.148 37.6886 30.0909C37.593 30.0339 37.4845 30.0039 37.3739 30.0039L10.5476 30Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24 30V13.9219C24 12.6165 24.5268 11.3646 25.4645 10.4416C26.4021 9.51855 27.6739 9 29 9C30.3261 9 31.5979 9.51855 32.5355 10.4416C33.4732 11.3646 34 12.6165 34 13.9219V14.9062"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
