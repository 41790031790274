import { FC, forwardRef, useState } from "react";
import cl from "classnames";
import styles from "./Textarea.module.scss";
import { FieldError } from "react-hook-form";
import { useTranslation } from "next-i18next";
import useValidationErrorTranslation from "@/lib/hooks/useValidationErrorTranslation";

type TextareaType = React.DetailedHTMLProps<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
>;

export type TextareaProps = {
  placeholder?: string;
  className?: string;
  error?: FieldError;
  theme?: "white" | "blue" | "transparent";
  isValid?: boolean;
  isEmpty?: boolean;
  maxRows?: number;
  wrapperClassName?: string;
} & Pick<
  TextareaType,
  | "onFocus"
  | "defaultValue"
  | "autoComplete"
  | "disabled"
  | "id"
  | "value"
  | "onChange"
  | "onBlur"
  | "maxLength"
  | "required"
>;

export const Textarea: FC<TextareaProps> = forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    {
      placeholder,
      className,
      disabled,
      error,
      theme = "white",
      maxRows = 3,
      isValid,
      isEmpty,
      required,
      onChange,
      wrapperClassName,
      ...props
    },
    ref,
  ) => {
    const { t } = useTranslation("index");
    const getErrorTranslation = useValidationErrorTranslation();

    const [value, setValue] = useState("");

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setValue(e.target.value);
      onChange && onChange(e);
    };

    const rows = value.split("\n").length || 0;

    return (
      <div
        className={cl(styles.input__wrapper, wrapperClassName, {
          [styles["input__wrapper--error"]]: !!error?.type,
          [styles["input__wrapper--valid"]]: isValid,
          [styles["input__wrapper--blue"]]: theme === "blue" || theme === "transparent",
          [styles["input__wrapper--white"]]: theme === "white",
          [styles["input__wrapper--transparent"]]: theme === "transparent",
        })}
      >
        <textarea
          placeholder={t(placeholder || "")}
          ref={ref}
          rows={rows > maxRows ? maxRows : rows}
          onChange={handleChange}
          {...props}
          className={cl(styles.input, styles.p3, className, {
            [styles["input--error"]]: !!error?.type,
            [styles["input--blue"]]: theme === "blue" || theme === "transparent",
            [styles["input--white"]]: theme === "white",
          })}
        />
        {error && <p className={styles.input__error}>{getErrorTranslation(error)}</p>}
      </div>
    );
  },
);

Textarea.displayName = "Textarea";
