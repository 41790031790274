import { FC } from "react";
import { IClassname } from "@/lib/types/classname";

export const SvgGym: FC<IClassname> = ({ className }) => {
  return (
    <svg
      className={className}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M19.8691 24.8261H28.13" stroke="currentColor" strokeWidth="2" />
      <path
        d="M13.2615 13.2608H18.2181C18.2181 13.2608 19.8702 13.2608 19.8702 14.913V33.0869C19.8702 33.0869 19.8703 34.7391 18.2181 34.7391H13.2615C13.2615 34.7391 11.6094 34.7391 11.6094 33.0869V14.913C11.6094 14.913 11.6094 13.2608 13.2615 13.2608Z"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path
        d="M11.6089 18.2173H8.30452C7.86633 18.2173 7.4461 18.3914 7.13625 18.7012C6.82641 19.0111 6.65234 19.4313 6.65234 19.8695V28.1304C6.65234 28.5685 6.82641 28.9888 7.13625 29.2986C7.4461 29.6085 7.86633 29.7825 8.30452 29.7825H11.6089"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path d="M6.65218 24H5" stroke="currentColor" strokeWidth="2" />
      <path
        d="M29.783 13.2608H34.7396C34.7396 13.2608 36.3917 13.2608 36.3917 14.913V33.0869C36.3917 33.0869 36.3917 34.7391 34.7396 34.7391H29.783C29.783 34.7391 28.1309 34.7391 28.1309 33.0869V14.913C28.1309 14.913 28.1309 13.2608 29.783 13.2608Z"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path
        d="M36.3906 18.2173H39.695C40.1332 18.2173 40.5534 18.3914 40.8632 18.7012C41.1731 19.0111 41.3472 19.4313 41.3472 19.8695V28.1304C41.3472 28.5685 41.1731 28.9888 40.8632 29.2986C40.5534 29.6085 40.1332 29.7825 39.695 29.7825H36.3906"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path d="M41.3477 24H42.9998" stroke="currentColor" strokeWidth="2" />
    </svg>
  );
};
