import { FC } from "react";
import { IClassname } from "@/lib/types/classname";

export const SvgReady: FC<IClassname> = ({ className }) => {
  return (
    <svg
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 13.5033C17.0009 12.4801 16.6903 11.477 16.1032 10.6068C15.516 9.73661 14.6756 9.03378 13.6764 8.57738C12.6772 8.12099 11.5589 7.92913 10.4473 8.02338C9.33561 8.11764 8.27469 8.49427 7.38383 9.11091C6.49296 9.72756 5.8075 10.5598 5.40452 11.5139C5.00154 12.4681 4.89703 13.5064 5.10274 14.512C5.30846 15.5176 5.81624 16.4507 6.56897 17.2063C7.32171 17.9619 8.28955 18.51 9.36364 18.7891V20.0018L8.27273 21.0016L9.36364 22.0014V23.0011L8.27273 24.0009L9.36364 25.0007V26.5003L11 28L12.6364 26.5003V18.7891C13.892 18.4629 14.9974 17.7703 15.7849 16.8164C16.5723 15.8626 16.9989 14.6994 17 13.5033Z"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 11C9 11.5304 9.21071 12.0391 9.58579 12.4142C9.96086 12.7893 10.4696 13 11 13C11.5304 13 12.0391 12.7893 12.4142 12.4142C12.7893 12.0391 13 11.5304 13 11C13 10.4696 12.7893 9.96086 12.4142 9.58579C12.0391 9.21071 11.5304 9 11 9C10.4696 9 9.96086 9.21071 9.58579 9.58579C9.21071 9.96086 9 10.4696 9 11Z"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 19.0783C12.9513 19.5891 14.0279 19.8609 15.1248 19.8671C16.2217 19.8734 17.3017 19.6139 18.2596 19.1139L19.5 20.1435V21.7623H21.2308L21.8077 22.3289V23.9208H23.3273L24.5527 25H27V22.711L20.7069 16.8249C21.2784 15.8657 21.5542 14.7769 21.5036 13.6798C21.453 12.5827 21.0779 11.5202 20.4203 10.6106C19.7626 9.70094 18.848 8.97977 17.7782 8.52729C16.7084 8.07482 15.5254 7.90879 14.3608 8.04766"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 11V6.15385C11 5.58261 11.2107 5.03477 11.5858 4.63085C11.9609 4.22692 12.4696 4 13 4C13.5304 4 14.0391 4.22692 14.4142 4.63085C14.7893 5.03477 15 5.58261 15 6.15385V8.58015"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
