import React, { useRef, useState } from "react";
import NavService from "@/lib/ui/components/Menu/MainMenu/NavService";
import NavSales from "@/lib/ui/components/Menu/MainMenu/NavSales";
import useOnClickOutside from "@villa/hooks/useOnClickOutside";
import { CustomMenuItem } from "..";
import { useTranslation } from "next-i18next";
import { UpSmallIcon } from "@/lib/ui/icons";
import styles from "./MainMenu.module.scss";
import cl from "classnames";
import { P2 } from "../../Typography";
import NavAboutCompany from "./NavAboutCompany";

interface IProps {
  scrolled: boolean;
}

const dropBgTransform = {
  background: "rgba(34, 54, 74, 0.50)",
  backdropFilter: "blur(15px)",
  boxShadow: "5px 5px 10px 0 rgb(68, 68, 68, .15)",
  borderRadius: "0 0 16px 16px",
};

const dropBg = {
  background: "rgba(255, 255, 255, 0.20)",
  backdropFilter: "blur(15px)",
  boxShadow: "0px 51px 64px 0px rgba(0, 0, 0, 0.10)",
  borderRadius: "16px",
};

export const MainMenu = ({ scrolled }: IProps) => {
  const refSales = useRef(null);
  const refService = useRef(null);
  const refAbout = useRef(null);
  const {
    t,
    i18n: { language },
  } = useTranslation("index");
  const [isNavSalesOpen, setIsNavSalesOpen] = useState(false);
  const [isNavServiceOpen, setIsNavServiceOpen] = useState(false);
  const [isNavAboutCompanyOpen, setIsNavAboutCompanyOpen] = useState(false);

  const dropBgStyle = scrolled ? dropBgTransform : dropBg;

  useOnClickOutside(refSales, () => {
    setIsNavSalesOpen(false);
  });

  useOnClickOutside(refService, () => {
    setIsNavServiceOpen(false);
  });

  useOnClickOutside(refAbout, () => {
    setIsNavAboutCompanyOpen(false);
  });

  const showSale = () => {
    if (isNavSalesOpen) {
      setIsNavSalesOpen(false);
      return;
    }
    setIsNavSalesOpen(true);
  };

  const showService = () => {
    if (isNavServiceOpen) {
      setIsNavServiceOpen(false);
      return;
    }
    setIsNavServiceOpen(true);
  };

  const showAboutCompany = () => {
    if (isNavAboutCompanyOpen) {
      setIsNavAboutCompanyOpen(false);
      return;
    }
    setIsNavAboutCompanyOpen(true);
  };

  return (
    <div className={"mainMenu-wrapper"}>
      <nav>
        <div className={"nav-item pos"} onClick={showSale} ref={refSales}>
          <P2>{t("sale")}</P2>
          <UpSmallIcon
            className={cl(styles.icon, isNavSalesOpen ? styles.icon__rotate : "")}
          />
          <NavSales
            visible={isNavSalesOpen}
            dropBgStyle={dropBgStyle}
            close={() => setIsNavSalesOpen(true)}
          />
        </div>

        <CustomMenuItem
          text={t("rent")}
          className={"nav-item"}
          path={`https://villacarte.com/${language}/rent`}
        />

        <div className={"nav-item pos"} onClick={showService} ref={refService}>
          <P2>{t("concierge")}</P2>
          <UpSmallIcon
            className={cl(styles.icon, isNavServiceOpen ? styles.icon__rotate : "")}
          />
          <NavService
            visible={isNavServiceOpen}
            dropBgStyle={dropBgStyle}
            close={() => setIsNavServiceOpen(false)}
          />
        </div>
        <CustomMenuItem
          text={t("propertyManagement")}
          className={"nav-item"}
          path={`/propertymanagement`}
        />
        <div className={"nav-item pos"} onClick={showAboutCompany} ref={refAbout}>
          <P2>{t("aboutTheCompany")}</P2>
          <UpSmallIcon
            className={cl(styles.icon, isNavAboutCompanyOpen ? styles.icon__rotate : "")}
          />
          <NavAboutCompany
            visible={isNavAboutCompanyOpen}
            dropBgStyle={dropBgStyle}
            close={() => setIsNavAboutCompanyOpen(true)}
          />
        </div>
      </nav>
    </div>
  );
};

export default MainMenu;
