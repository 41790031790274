import { FC } from 'react';
import { IClassname } from '@/lib/types/classname';

export const MinusSVG: FC<IClassname> = ({ className }) => {
    return (
        <svg className={className} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="32" height="32" fill="#FFA2A2"/>
<rect x="26" y="15" width="2" height="20" transform="rotate(90 26 15)" fill="white"/>
</svg>
)}

