import { FC } from 'react';
import { IClassname } from '@/lib/types/classname';

export const SvgNearLake: FC<IClassname> = ({ className }) => {
    return (
        <svg className={className} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.59902 28.4522C15.707 29.894 24.5041 28.1519 28.0142 27.1006C29.5189 26.6929 30.6468 25.2984 29.0014 24.8479C27.6851 24.4875 27.1367 23.7966 27.027 23.4963C26.9173 23.0457 27.027 22.1447 28.3433 22.1447C29.6595 22.1447 29.9886 21.2436 29.9886 20.7931C30.0983 20.0422 29.4621 18.4503 26.0398 18.0898C22.6174 17.7294 20.2262 18.5404 19.4583 18.9909C18.8002 19.4414 14.1932 20.7931 10.2443 19.892C4.785 18.6462 3.33388 19.4414 2.34667 20.7931C1.57883 22.2948 1.49109 27.0105 8.59902 28.4522Z" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round"/>
<path d="M17.3521 24.0032C17.1633 24.3063 16.9003 24.5563 16.5881 24.7296C16.2759 24.9029 15.9247 24.9939 15.5675 24.9939C15.2104 24.9939 14.8592 24.9029 14.547 24.7296C14.2347 24.5563 13.9718 24.3063 13.783 24.0032" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M20.9213 24.0032C20.7325 24.3063 20.4695 24.5563 20.1573 24.7296C19.8451 24.9029 19.4939 24.9939 19.1368 24.9939C18.7796 24.9939 18.4284 24.9029 18.1162 24.7296C17.804 24.5563 17.541 24.3063 17.3522 24.0032" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M22.7011 24.9946C22.3432 24.9947 21.9913 24.9034 21.6785 24.7293C21.3658 24.5552 21.1027 24.3042 20.9142 24" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M13.783 24.0032C13.5945 24.3063 13.3319 24.5564 13.02 24.7299C12.708 24.9034 12.357 24.9945 12 24.9946" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M15.7186 8.93845L19.5716 6.79797C19.6371 6.76173 19.6917 6.7083 19.7297 6.6433C19.7677 6.5783 19.7877 6.50414 19.7875 6.42864C19.7843 6.35183 19.7612 6.27723 19.7203 6.21237C19.6795 6.14752 19.6225 6.09471 19.555 6.0593C19.0451 5.85055 18.4937 5.76609 17.9455 5.81275C17.3973 5.85942 16.8677 6.0359 16.3995 6.32791C15.6882 6.70141 15.1083 7.28768 14.7388 8.00671C14.5279 7.22253 14.0845 6.52242 13.4683 6.00054C13.0733 5.61144 12.5926 5.32259 12.0657 5.1577C11.5389 4.99282 10.9808 4.9566 10.4374 5.05202C10.3609 5.07576 10.2925 5.12051 10.2397 5.18128C10.1869 5.24205 10.1519 5.31645 10.1385 5.39617C10.1267 5.46464 10.1319 5.53499 10.1536 5.60092C10.1754 5.66685 10.2129 5.72629 10.263 5.7739L13.3271 8.47678C12.4579 8.26293 11.5421 8.36084 10.7363 8.75378C10.2012 8.96054 9.72572 9.29921 9.35302 9.73899C8.98032 10.1788 8.72225 10.7057 8.60227 11.272C8.59356 11.3438 8.60266 11.4167 8.62875 11.4841C8.65484 11.5515 8.6971 11.6113 8.75173 11.6581C8.80663 11.7034 8.87229 11.7335 8.9422 11.7452C9.01211 11.757 9.08385 11.7501 9.15032 11.7253L13.8669 10.0465H14.2821L18.1101 13.446C18.163 13.4949 18.2275 13.5291 18.2974 13.5452C18.3672 13.5614 18.44 13.5591 18.5087 13.5384C18.5765 13.515 18.6377 13.4753 18.6869 13.4227C18.7361 13.37 18.7719 13.306 18.791 13.2362C18.8828 12.6694 18.8345 12.0886 18.6505 11.5452C18.4666 11.0019 18.1525 10.513 17.7364 10.122C17.1976 9.53042 16.4941 9.11778 15.7186 8.93845Z" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M10.5735 17.5047C10.9272 15.0699 11.6676 12.5458 13.3212 10.8251L13.8642 10.2651" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M14.5223 10.2651L14.162 11.3099C12.8943 15.012 13.2822 16.9535 13.206 18.1628" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

)}
