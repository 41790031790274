import cl from "classnames";
import styles from "./socials.module.scss";
import { FC, useId } from "react";
import { LinearGradient } from "./LinearGradient";
import { ISocialIcon } from "@/lib/types/social-icons";

export const SvgYoutube: FC<ISocialIcon> = ({ className, isGradient }) => {
  const id = useId();
  const fill = isGradient ? `url('#blueGradient${id}')` : "#848C98";
  return (
    <svg
      className={cl(styles.socials, className)}
      viewBox="0 0 24 20"
      width="24"
      height="20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {isGradient && <LinearGradient id={id} />}
      <path
        d="M22.5408 4.49936C22.4156 4.01538 22.1701 3.57402 21.8288 3.21945C21.4875 2.86488 21.0625 2.60954 20.5962 2.47898C18.8799 2 12 2 12 2C12 2 5.12009 2 3.40379 2.47643C2.93733 2.60657 2.51207 2.86177 2.17073 3.21641C1.82939 3.57104 1.58398 4.01263 1.45915 4.49682C1 6.28025 1 10 1 10C1 10 1 13.7197 1.45915 15.5006C1.71205 16.4841 2.45848 17.2586 3.40379 17.521C5.12009 18 12 18 12 18C12 18 18.8799 18 20.5962 17.521C21.544 17.2586 22.2879 16.4841 22.5408 15.5006C23 13.7197 23 10 23 10C23 10 23 6.28025 22.5408 4.49936ZM9.81473 13.414V6.58599L15.5112 9.97452L9.81473 13.414Z"
        fill={fill}
      />
    </svg>
  );
};
