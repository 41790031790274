import { createSlice } from "@reduxjs/toolkit";
import * as I from "./interfaces";
import { ICurrency } from "@/lib/types/currency";

const getInitialCurrency = () => {
  if (typeof window === "undefined")
    return {
      value: "usd",
      label: "USD",
      symbol: "$",
    };
  const lsValue = JSON.parse(localStorage.getItem("user_currency") as string);
  if (lsValue === null || !lsValue) {
    return {
      value: "usd",
      label: "USD",
      symbol: "$",
    };
  }

  return lsValue;
};
const initialCurrency: ICurrency = getInitialCurrency();

const initialState: I.SettingsState = {
  forceHeaderStyle: undefined,
  currency: initialCurrency,
};

const slice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setCurrency(state, action: I.ISetCurrencyAction) {
      state.currency = action.payload;
      localStorage.setItem("user_currency", JSON.stringify(action.payload));
    },
    setForceHeaderStyle(state, action: I.ISetForceHeaderStyleAction) {
      state.forceHeaderStyle = action.payload;
    },
    clear() {
      return initialState;
    },
  },
});

export const settingsReducer = slice.reducer;
export const settingsActions = slice.actions;
