import { FC } from "react";
import { IClassname } from "@/lib/types/classname";

export const SvgReception: FC<IClassname> = ({ className }) => {
  return (
    <svg
      className={className}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 15.7059C30.2347 15.7059 34.1558 19.1647 34.9387 25.1312C35.1288 26.5839 36.25 28.0588 37.4167 28.9853V29.9118H10.5833V28.9853C11.75 28.0588 12.87 26.5839 13.0613 25.1312C13.8442 19.1647 17.7653 15.7059 24 15.7059ZM24 15.7059V12M10 33H38M21.6667 12H26.3333"
        stroke="currentColor"
        strokeWidth="2"
      />
    </svg>
  );
};
