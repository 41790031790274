import { FC } from "react";
import styles from "./Logo.module.scss";
import Link from "next/link";
import cl from "classnames";
import { LogoMobileIcon, SvgLogo } from "../../icons";
import { IClassname } from "@/lib/types/classname";

interface ILogo extends IClassname {
  size: "lg" | "sm";
  color?: string;
}
export const Logo: FC<ILogo> = ({ size, color }) => {
  return (
    <Link
      aria-label="main page"
      href="/"
      className={cl(styles.logo, styles[size])}
      style={{ color: color ? color : "#22364A" }}
    >
      <SvgLogo />
    </Link>
  );
};
