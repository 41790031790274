import { FC } from "react";
import { IClassname } from "@/lib/types/classname";

export const SvgCity: FC<IClassname> = ({ className }) => {
  return (
    <svg
      className={className}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 15.7353C21 15.5403 20.9181 15.3533 20.7722 15.2154C20.6263 15.0775 20.4285 15 20.2222 15H7.77778C7.5715 15 7.37367 15.0775 7.22781 15.2154C7.08194 15.3533 7 15.5403 7 15.7353V39.2647C7 39.4597 7.08194 39.6467 7.22781 39.7846C7.37367 39.9225 7.5715 40 7.77778 40H21V15.7353Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 15L9.00106 10.9187C9.00106 10.675 9.07631 10.4413 9.21025 10.2691C9.34419 10.0968 9.52585 10 9.71527 10H18.2858C18.4752 10 18.6569 10.0968 18.7908 10.2691C18.9248 10.4413 19 10.675 19 10.9187V15"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 10V7"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41 39.2857C41 39.4752 40.9172 39.6568 40.7699 39.7908C40.6225 39.9247 40.4227 40 40.2143 40H30V20.7143C30 20.5248 30.0828 20.3432 30.2301 20.2092C30.3775 20.0753 30.5773 20 30.7857 20H40.2143C40.4227 20 40.6225 20.0753 40.7699 20.2092C40.9172 20.3432 41 20.5248 41 20.7143V39.2857Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32 20V17.75C32 17.5511 32.0737 17.3603 32.205 17.2197C32.3363 17.079 32.5143 17 32.7 17H38.3C38.4857 17 38.6637 17.079 38.795 17.2197C38.9263 17.3603 39 17.5511 39 17.75V20"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 23H30"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 40H31"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 20H18"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 24H18"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 28H18"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33 24H38"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33 28H38"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 33H18"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33 33H38"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
