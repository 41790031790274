import { IClassname } from "@/lib/types/classname";
import { FC } from "react";

export const TranslateIcon: FC<IClassname> = ({className}) => {
  return (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
    <g id="Translate">
    <g id="Group 1000004690">
    <path id="Vector" d="M10.827 13.5647H21.9133" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path id="Vector_2" d="M16.3485 10.7825V13.5648" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path id="Vector_3" d="M19.8265 13.5676C19.2741 15.5611 18.2149 17.3776 16.7522 18.8404C15.2895 20.3031 13.4729 21.3623 11.4794 21.9147" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path id="Vector_4" d="M12.8706 13.5676C13.423 15.5611 14.4821 17.3776 15.9449 18.8404C17.4076 20.3031 19.2242 21.3623 21.2177 21.9147" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path id="Vector_5" d="M27.478 37.2176L31.0005 27.8257C31.0503 27.6933 31.1393 27.5792 31.2556 27.4987C31.372 27.4181 31.5101 27.375 31.6516 27.375C31.7931 27.375 31.9312 27.4181 32.0475 27.4987C32.1638 27.5792 32.2528 27.6933 32.3026 27.8257L35.8251 37.2176" 
    stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path id="Vector_6" d="M28.5214 34.4324H34.7984" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path id="Vector_7" d="M9.39259 8H23.3044C23.3044 8 24.6956 8 24.6956 9.39118V23.303C24.6956 23.303 24.6956 24.6942 23.3044 24.6942H9.39259C9.39259 24.6942 8.0014 24.6942 8.0014 23.303V9.39118C8.0014 9.39118 8.0014 8 9.39259 8Z" 
    stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path id="Vector_8" d="M28.1736 23.3059H38.6075C38.9764 23.3059 39.3303 23.4525 39.5912 23.7134C39.8521 23.9743 39.9986 24.3281 39.9986 24.6971V38.6089C39.9986 38.9779 39.8521 39.3317 39.5912 39.5926C39.3303 39.8535 38.9764 40.0001 38.6075 40.0001H24.6956C24.3267 40.0001 23.9728 39.8535 23.7119 39.5926C23.451 39.3317 23.3044 38.9779 23.3044 38.6089V28.175" 
    stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path id="Vector_9" d="M15.653 39.3015L18.4354 36.5192L15.653 33.7368" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path id="Vector_10" d="M10.0882 29.5662C10.0882 30.4796 10.2681 31.3841 10.6177 32.2281C10.9673 33.072 11.4796 33.8388 12.1255 34.4847C12.7715 35.1307 13.5383 35.643 14.3822 35.9926C15.2261 36.3422 16.1306 36.5221 17.0441 36.5221H18.4353" 
    stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path id="Vector_11" d="M32.3471 8.69556L29.5648 11.4779L32.3471 14.2603" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path id="Vector_12" d="M37.9119 18.4366C37.9119 16.5918 37.179 14.8225 35.8745 13.5181C34.57 12.2136 32.8008 11.4807 30.9559 11.4807H29.5648" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    </g>
  </svg>
  );
};
