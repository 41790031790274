import { createSlice } from "@reduxjs/toolkit";
import * as I from "./interfaces";

const initialState: I.MapState = {
  filter: [],
};

const slice = createSlice({
  name: "map",
  initialState,
  reducers: {
    toggleFilterQuery(state, action: I.IAddFilterQueryAction) {
      if (!state.filter.includes(action.payload)) {
        state.filter.push(action.payload);
      } else {
        state.filter = state.filter.filter((item) => item !== action.payload);
      }
    },
    bulkAddFilterQuery(state, action: I.IBulkAddFilterQueryAction) {
      state.filter = action.payload;
    },
    clear() {
      return initialState;
    },
  },
});

export const mapReducer = slice.reducer;
export const mapActions = slice.actions;
