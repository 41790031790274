import { AxiosResponse } from "axios";
import { ICurrencyResponse } from "../types/currency";
import { agent } from "./agent";
import { BASE_SERVICE_URL } from "./global";

const CURRENCY_URL = `${BASE_SERVICE_URL}/currency/get-rates`;

export const fetchCurrencyRates = (): Promise<AxiosResponse<ICurrencyResponse>> => {
  return agent.get(CURRENCY_URL);
};
