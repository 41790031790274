import { FC } from 'react';
import { IClassname } from '@/lib/types/classname';

export const ConstructionIcon: FC<IClassname> = ({ className }) => {
    return (
        <svg className={className} width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M66 96H24" stroke="#D4D4D4" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M30 96V79.125C30.0635 78.0655 30.5335 77.0657 31.32 76.317L39.9529 69H50.5412L58.7118 76.2866C59.482 77.0552 59.939 78.0621 60 79.125V96" stroke="#D4D4D4" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M21 96L99 96" stroke="#D4D4D4" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M46.8256 37C47.6864 37 49.092 37.1085 49.956 37.2465L96 42.1267V48.5352C96 49.1889 95.8351 49.8158 95.5416 50.2781C95.248 50.7403 94.8499 51 94.4348 51H25.5652C25.1501 51 24.752 50.7403 24.4584 50.2781C24.1649 49.8158 24 49.1889 24 48.5352V42.1267L37.5 37.6901C38.5001 37.2662 39.5292 37.0345 40.5647 37H46.8256Z" 
            stroke="#D4D4D4" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M24 42L43.6184 25.1454C44.3298 24.5987 45.1818 24.2315 46.0917 24.0792C47.0016 23.927 47.9386 23.9949 48.8118 24.2764L96 42" stroke="#D4D4D4" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M88.5 42V63.0827C88.4997 63.6945 88.6699 64.2931 88.9896 64.8051C89.3094 65.317 89.7648 65.7201 90.3 65.9648C91.0435 66.3048 91.6855 66.849 92.158 67.5399C92.6305 68.2308 92.9159 69.0427 92.9841 69.8896C93.0522 70.7365 92.9006 71.587 92.5452 72.351C92.1897 73.115 91.6438 73.7642 90.965 74.2298C90.2863 74.6953 89.5001 74.96 88.6895 74.9958C87.879 75.0316 87.0742 74.8372 86.3605 74.4331C85.6468 74.029 85.0506 73.4303 84.6351 72.7003C84.2196 71.9703 84.0001 71.1363 84 70.2863" 
            stroke="#D4D4D4" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M39 54L51 66" stroke="#D4D4D4" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M51 81L39 90" stroke="#D4D4D4" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M63 39V51" stroke="#D4D4D4" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M48 24V36" stroke="#D4D4D4" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M51 39V96" stroke="#D4D4D4" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M39 96V39" stroke="#D4D4D4" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
)}
