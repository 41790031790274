import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import styles from "./Popup.module.scss";
import cl from "classnames";
import { IModalParams } from "@/lib/ui/components/Modal/types";
import { PopupCloseButton } from "./PopupCloseButton";
import { CircleButton } from "@/lib/ui/components/Buttons";
import { CloseSign } from "@/lib/ui/icons";

export interface IPopupProps extends IModalParams {
  show: boolean;
  children: React.ReactNode;
  onClose: () => void;
  isFeedBackNew?: boolean;
}

export const Popup: React.FC<IPopupProps> = ({
  show,
  onClose,
  children,
  isNotClose,
  isVideoModal,
  hideCloseButton,
  className,
  contentClassName,
  fullScreen,
  fillOverlay,
  isFilterModal,
  isMapModal,
  isGalleryModal,
  isFlexCenter,
  isFeedBack,
  isFeedBackNew
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const closePopup = () => {
    if (isNotClose) return;
    onClose();
  };
  const closeOnEscapeKeyDown = (e: KeyboardEvent) => {
    if (isNotClose) return;

    if ((e.charCode || e.keyCode) === 27) {
      closePopup();
    }
  };

  useEffect(() => {
    document.body.addEventListener("keydown", closeOnEscapeKeyDown);
    return function () {
      document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mouseHandler = function (event: any) {
    const startY = event.targetTouches[0].pageY;
    let shiftY: number;

    document.addEventListener("touchmove", onMouseMove);
    document.addEventListener("touchend", onMouseUp);

    function onMouseMove(event: any) {
      const Y = event.targetTouches[0].pageY;
      shiftY = Y - startY;
      if (!containerRef.current || shiftY < 0) return;
      containerRef.current.style.transform = `translate(0, ${shiftY}px)`;
    }

    function onMouseUp() {
      if (!containerRef.current) return;
      const { clientHeight } = containerRef.current;
      const prc = (shiftY * 100) / clientHeight;
      if (prc > 30) {
        containerRef.current.style.transform = `translate(0, ${clientHeight}px)`;
        setTimeout(() => {
          closePopup();
        }, 100);
      } else {
        containerRef.current.style.transform = "";
      }
      document.removeEventListener("touchmove", onMouseMove);
      document.removeEventListener("touchend", onMouseUp);
    }
  };

  const [element, setElement] = useState<HTMLElement>();

  useEffect(() => {
    if (!show) {
      document.body.style.overflow = "";
      setElement(undefined);
    } else {
      document.body.style.overflow = "hidden";
      const elem = document.getElementById("modal");
      if (elem) setElement(elem);
    }
  }, [show]);
  const showRegularCloseButton =
    !isNotClose &&
    !isFilterModal &&
    !isMapModal &&
    !hideCloseButton &&
    !isFilterModal &&
    !isGalleryModal &&
    !isFeedBack &&
    !isFeedBackNew;
  const showInnerCloseButton =
    (isFilterModal || isMapModal || isGalleryModal || isFeedBack || isFeedBackNew) && !hideCloseButton;

  if (element) {
    return ReactDOM.createPortal(
      <div
        className={cl(
          styles.popup,
          className,
          fullScreen && styles.fullScreen,
          fillOverlay && styles.fillOverlay,
          isFeedBackNew && styles.fillFeedBackNew,
        )}
      >
        <div className={styles.popup__container} ref={containerRef}>
          <div
            className={cl(
              styles.popup__content,
              contentClassName,
              isVideoModal && styles["popup__content--video"],
              isFilterModal && styles["popup__content--filter"],
              isMapModal && styles["popup__content--map"],
              isGalleryModal && styles["popup__content--gallery"],
              isFlexCenter && styles["popup__content--flexCenter"],
              isFeedBack && styles["popup__content--feedBack"],
              isFeedBackNew && styles["popup__content--feedBackNew"],
            )}
            onClick={(e) => {
              if (e.target === e.currentTarget) {
                closePopup();
              }
            }}
          >
            <div className={cl(isFeedBackNew ? styles.popup__modalContainer : '')} style={{height: '100%'}}>
            {showInnerCloseButton &&
              (isGalleryModal ? (
                <CircleButton
                  name="close gallery"
                  as="button"
                  theme="mixed"
                  className={cl(styles.popup__close, styles["popup__close--cross"])}
                  content={<CloseSign />}
                  onClick={closePopup}
                />
              ) : (
                <PopupCloseButton className={styles.popup__close} onClick={closePopup} />
              ))}
            {children}
            </div>
            
          </div>
          {showRegularCloseButton &&
            (isGalleryModal ? (
              <CircleButton
                name="close gallery"
                as="button"
                theme="mixed"
                className={cl(styles.popup__close, styles["popup__close--cross"])}
                content={<CloseSign />}
                onClick={closePopup}
              />
            ) : (
              <PopupCloseButton className={styles.popup__close} onClick={closePopup} />
            ))}
        </div>
        <div
          className={styles.popup__overlay}
          onClick={closePopup}
          onTouchStart={mouseHandler}
        />
      </div>,
      element,
    );
  }
  return null;
};
