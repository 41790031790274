import { IClassname } from "@/lib/types/classname";
import { FC } from "react";
import cl from "classnames";
import styles from "./icons.module.scss";

export const ArrowIcon: FC<IClassname & { isRight?: boolean }> = ({
  className,
  isRight,
}) => {
  return (
    <svg
      className={cl(className, isRight && styles.rotate)}
      width="20"
      height="14"
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 14C8 13.2585 7.2669 12.1504 6.5254 11.2194C5.5709 10.0207 4.4311 8.9732 3.1238 8.1738C2.1449 7.5752 0.9556 7 1.35122e-07 7M1.35122e-07 7C0.9556 7 2.1449 6.4248 3.1238 5.8262C4.4311 5.0268 5.5709 3.9793 6.5254 2.7806C7.2669 1.8496 8 0.741499 8 -8.10655e-07M1.35122e-07 7L20 7"
        stroke="currentColor"
        strokeWidth="1.6"
      />
    </svg>
  );
};
