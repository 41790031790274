import { FC } from 'react';
import { IClassname } from '@/lib/types/classname';

export const SVGFbMessengerColor: FC<IClassname> = ({ className }) => {
    return (
        <svg className={className} width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="48" height="48" rx="24" fill="url(#paint0_radial_2950_1833)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M12.6455 23.6545C12.6455 17.3277 17.6041 12.6416 23.999 12.6416C30.3938 12.6416 35.3524 17.3306 35.3524 23.6573C35.3524 29.984 30.3938 34.6702 23.999 34.6702C22.8494 34.6702 21.7482 34.5169 20.7121 34.2331C20.5106 34.1791 20.2949 34.1933 20.1047 34.2785L17.8511 35.2719C17.2607 35.533 16.5965 35.113 16.5766 34.4686L16.5142 32.4477C16.5085 32.198 16.395 31.9652 16.2105 31.8006C14.0022 29.8251 12.6455 26.964 12.6455 23.6545ZM20.5161 21.5853L17.181 26.876C16.8603 27.3841 17.4847 27.9546 17.9616 27.5913L21.5436 24.8721C21.7848 24.6876 22.1198 24.6876 22.3639 24.8693L25.0177 26.859C25.8153 27.455 26.9507 27.2478 27.4814 26.4049L30.8193 21.117C31.1372 20.6089 30.5128 20.0356 30.036 20.3989L26.4539 23.118C26.2127 23.3025 25.8778 23.3025 25.6337 23.1209L22.9798 21.1312C22.1822 20.5351 21.0469 20.7423 20.5161 21.5853Z" fill="white"/>
<defs>
<radialGradient id="paint0_radial_2950_1833" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(0 48) rotate(-45) scale(67.8823)">
<stop offset="0.144049" stopColor="#39A3DD"/>
<stop offset="0.842933" stopColor="#FC567E"/>
</radialGradient>
</defs>
</svg>
)}
