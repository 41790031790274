import { FC } from "react";
import { IClassname } from "@/lib/types/classname";

export const SvgApartsForPhuket: FC<IClassname> = ({ className }) => {
  return (
    <svg className={className} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3 27L29 27" stroke="currentColor" strokeWidth="1.6" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M15.9131 9.54348H3.71747V26.7609H15.9131V9.54348Z" stroke="currentColor" strokeWidth="1.6" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M5.98166 5.95652L3 9.54348H16.6304L14.0747 5.95652H5.98166Z" stroke="currentColor" strokeWidth="1.6" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M11 23H8V27H11V23Z" stroke="currentColor" strokeWidth="1.6" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M6 19H13" stroke="currentColor" strokeWidth="1.6" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M6 16L13 16" stroke="currentColor" strokeWidth="1.6" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M6 13L13 13" stroke="currentColor" strokeWidth="1.6" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M25.1196 17.9384L28.9725 15.798C29.038 15.7617 29.0927 15.7083 29.1307 15.6433C29.1687 15.5783 29.1886 15.5041 29.1884 15.4286C29.1853 15.3518 29.1621 15.2772 29.1213 15.2124C29.0805 15.1475 29.0234 15.0947 28.9559 15.0593C28.446 14.8506 27.8946 14.7661 27.3465 14.8128C26.7983 14.8594 26.2687 15.0359 25.8005 15.3279C25.0892 15.7014 24.5092 16.2877 24.1397 17.0067C23.9288 16.2225 23.4855 15.5224 22.8692 15.0005C22.4742 14.6114 21.9935 14.3226 21.4667 14.1577C20.9398 13.9928 20.3817 13.9566 19.8383 14.052C19.7619 14.0758 19.6934 14.1205 19.6406 14.1813C19.5879 14.242 19.5528 14.3164 19.5394 14.3962C19.5276 14.4646 19.5328 14.535 19.5546 14.6009C19.5763 14.6668 19.6139 14.7263 19.664 14.7739L22.7281 17.4768C21.8588 17.2629 20.9431 17.3608 20.1373 17.7538C19.6022 17.9605 19.1267 18.2992 18.754 18.739C18.3813 19.1788 18.1232 19.7057 18.0032 20.272C17.9945 20.3438 18.0036 20.4167 18.0297 20.4841C18.0558 20.5515 18.098 20.6113 18.1527 20.6581C18.2076 20.7034 18.2732 20.7335 18.3431 20.7452C18.413 20.757 18.4848 20.7501 18.5513 20.7253L23.2678 19.0465H23.683L27.511 22.446C27.564 22.4949 27.6285 22.5291 27.6983 22.5452C27.7682 22.5614 27.841 22.5591 27.9096 22.5384C27.9775 22.515 28.0386 22.4753 28.0879 22.4227C28.1371 22.37 28.1728 22.306 28.192 22.2362C28.2837 21.6694 28.2355 21.0886 28.0515 20.5452C27.8675 20.0019 27.5535 19.513 27.1374 19.122C26.5985 18.5304 25.895 18.1178 25.1196 17.9384Z" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M19.9744 26.5047C20.3282 24.0699 21.0686 21.5458 22.7222 19.8251L23.2651 19.2651" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M23.9232 19.2651L23.563 20.3099C22.2952 24.012 22.5762 25.2907 22.5 26.5" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    

  );
};
