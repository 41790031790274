import { IClassname } from "@/lib/types/classname";
import { FC, useId } from "react";
import { LinearGradient } from "./socials/LinearGradient";

export const LegalIcon: FC<IClassname & { isGradient?: boolean, isDark?: boolean }> = ({
  className,
  isGradient,
  isDark
}) => {
  const id = useId();
  const stroke = isGradient ? `url('#blueGradient${id}')` : "currentColor";
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
    >
      {isGradient && <LinearGradient id={id} isDark={isDark} />}
      <path
        d="M24 13.3333V39.9999"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.8105 40H29.1412"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.44629 23.9965L10.6667 9.77783L16.8658 23.9965"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.4844 9.77783H8"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.8888 24C16.8888 25.6502 16.2332 27.2329 15.0663 28.3998C13.8994 29.5667 12.3168 30.2222 10.6666 30.2222C9.01632 30.2222 7.43368 29.5667 6.26678 28.3998C5.09989 27.2329 4.44434 25.6502 4.44434 24H16.8888Z"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.5538 23.9965L37.3334 9.77783L31.1343 23.9965"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.5156 9.77783H40.0001"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.1113 24C31.1113 25.6502 31.7669 27.2329 32.9338 28.3998C34.1007 29.5667 35.6833 30.2222 37.3335 30.2222C38.9838 30.2222 40.5664 29.5667 41.7333 28.3998C42.9002 27.2329 43.5558 25.6502 43.5558 24H31.1113Z"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.3333 10.6667C21.3333 11.3739 21.6142 12.0522 22.1143 12.5523C22.6144 13.0524 23.2927 13.3333 23.9999 13.3333C24.7072 13.3333 25.3854 13.0524 25.8855 12.5523C26.3856 12.0522 26.6666 11.3739 26.6666 10.6667C26.6666 9.95942 26.3856 9.28115 25.8855 8.78105C25.3854 8.28095 24.7072 8 23.9999 8C23.2927 8 22.6144 8.28095 22.1143 8.78105C21.6142 9.28115 21.3333 9.95942 21.3333 10.6667Z"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
