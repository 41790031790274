import { IClassname } from "@/lib/types/classname";
import { FC } from "react";

export const EmailIcon: FC<IClassname> = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
        <g id="Email Action Unread">
        <path id="Vector" d="M1.80078 5.39966C1.80078 4.29509 2.69621 3.39966 3.80078 3.39966H14.2008C15.3053 3.39966 16.2008 4.29509 16.2008 5.39966V12.5997C16.2008 13.7042 15.3054 14.5997 14.2008 14.5997H3.80078C2.69621 14.5997 1.80078 13.7042 1.80078 12.5997V5.39966Z" 
        stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_2" d="M15.4003 4.39966L9.70745 10.0926C9.31692 10.4831 8.68376 10.4831 8.29323 10.0926L2.60034 4.39966" 
        stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
    </svg>
  );
};