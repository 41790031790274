import { IClassname } from "@/lib/types/classname";
import { FC, useId } from "react";
import { LinearGradient } from "./socials/LinearGradient";

export const UserQuestionIcon: FC<IClassname & {isGradient?: boolean, isDark?: boolean}> = ({className, isGradient, isDark}) => {
  const id = useId();
  const stroke = isGradient ? `url('#blueGradient${id}')` : "currentColor";
  return (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
    {isGradient && <LinearGradient id={id} isDark={isDark} />}
    <g id="User Question">
    <path id="Vector" d="M28.6545 40V35.8264H30.0457C31.1527 35.8264 32.2142 35.3868 32.9971 34.6039C33.7798 33.8212 34.2194 32.7597 34.2194 31.6527V27.4791H38.1329C38.2419 27.4784 38.3493 27.4521 38.4463 27.4021C38.5434 27.3523 38.6273 27.2803 38.6913 27.1919C38.7554 27.1037 38.7977 27.0016 38.8151 26.8939C38.8324 26.7862 38.8242 26.6759 38.7909 26.572C36.1838 18.503 34.2959 9.82871 25.6856 8.25384C23.8067 7.88972 21.8727 7.9176 20.0051 8.33576C18.1374 8.75392 16.3762 9.55333 14.8318 10.6839C13.2875 11.8144 11.9933 13.2518 11.0304 14.9059C10.0674 16.5599 9.45652 18.3951 9.2359 20.2962C9.0156 22.634 9.40742 24.9888 10.3729 27.1292C11.3383 29.2697 12.8442 31.122 14.7423 32.5042V40" 
    stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path id="Vector_2" d="M18.9158 18.436C18.9152 17.6558 19.1333 16.8911 19.5453 16.2285C19.9574 15.566 20.5469 15.0322 21.2469 14.6878C21.947 14.3433 22.7295 14.202 23.5058 14.2799C24.2823 14.3577 25.0211 14.6516 25.6388 15.1282C26.2567 15.6047 26.7284 16.2449 27.0007 16.9761C27.273 17.7072 27.3349 18.5 27.1793 19.2646C27.0239 20.0291 26.6572 20.7348 26.1209 21.3015C25.5846 21.8682 24.9002 22.2732 24.1454 22.4706C23.8515 22.535 23.5878 22.6961 23.3963 22.9282C23.2049 23.1602 23.0968 23.4497 23.0894 23.7505V26.0878" 
    stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path id="Vector_3" d="M23.0896 29.566C22.8976 29.566 22.7418 29.4103 22.7418 29.2182C22.7418 29.0262 22.8976 28.8704 23.0896 28.8704" 
    stroke={stroke} strokeWidth="2"/>
    <path id="Vector_4" d="M23.0896 29.566C23.2817 29.566 23.4374 29.4103 23.4374 29.2182C23.4374 29.0262 23.2817 28.8704 23.0896 28.8704"
    stroke={stroke} strokeWidth="2"/>
    </g>
  </svg>
  );
};
