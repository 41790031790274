import { FC } from "react";
import { IClassname } from "@/lib/types/classname";

export const SvgSPA: FC<IClassname> = ({ className }) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M25.0178 22.6427V19.9275C25.0178 19.7492 24.9827 19.5726 24.9145 19.4079C24.8462 19.2432 24.7462 19.0935 24.6202 18.9675C24.4941 18.8414 24.3444 18.7414 24.1797 18.6732C24.015 18.6049 23.8384 18.5698 23.6602 18.5698H12.799C12.439 18.5698 12.0937 18.7129 11.839 18.9675C11.5844 19.2221 11.4414 19.5674 11.4414 19.9275V38.242C11.4414 38.6021 11.5844 38.9474 11.839 39.202C12.0937 39.4566 12.439 39.5997 12.799 39.5997H21.6237"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.0848 8.52318C18.9636 8.43787 18.819 8.39209 18.6708 8.39209C18.5225 8.39209 18.3779 8.43787 18.2567 8.52318C18.1413 8.61137 18.0559 8.733 18.0122 8.87148C17.9685 9.00997 17.9685 9.15857 18.0123 9.29703C18.1848 9.82247 18.2485 10.3775 18.1995 10.9284C18.1504 11.4792 17.9898 12.0143 17.7272 12.5011C17.4057 12.2669 17.1335 11.9716 16.9262 11.6322C16.8808 11.5463 16.8172 11.4715 16.7398 11.4128C16.6624 11.3542 16.5731 11.3132 16.4782 11.2928C16.3868 11.2694 16.2917 11.2645 16.1984 11.2785C16.1051 11.2925 16.0156 11.3251 15.9351 11.3742C15.396 11.6965 14.9507 12.1544 14.6434 12.7022C14.3362 13.25 14.1777 13.8687 14.1838 14.4968C14.1838 15.577 14.6129 16.613 15.3767 17.3768C16.1405 18.1406 17.1765 18.5697 18.2567 18.5697C19.3369 18.5697 20.3728 18.1406 21.1367 17.3768C21.9005 16.613 22.3296 15.577 22.3296 14.4968C22.3012 13.3151 21.9921 12.1572 21.4279 11.1185C20.8637 10.0799 20.0606 9.19026 19.0848 8.52318Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.4414 21.9775H12.1202C12.8404 21.9775 13.531 22.2636 14.0402 22.7728C14.5494 23.282 14.8355 23.9727 14.8355 24.6928V26.0505C14.8355 26.5005 15.0143 26.9322 15.3326 27.2504C15.6508 27.5687 16.0825 27.7475 16.5326 27.7475C16.9826 27.7475 17.4143 27.5687 17.7325 27.2504C18.0508 26.9322 18.2296 26.5005 18.2296 26.0505V23.6746C18.2296 23.2245 18.4084 22.7929 18.7267 22.4746C19.0449 22.1563 19.4766 21.9775 19.9266 21.9775C20.3767 21.9775 20.8084 22.1563 21.1266 22.4746C21.4449 22.7929 21.6237 23.2245 21.6237 23.6746C21.6237 24.1247 21.8025 24.5563 22.1208 24.8746C22.439 25.1928 22.8707 25.3716 23.3207 25.3716C23.7708 25.3716 24.2025 25.1928 24.5207 24.8746C24.839 24.5563 25.0178 24.1247 25.0178 23.6746V22.6428"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.2176 29.8517C35.9559 29.4952 35.6201 29.1996 35.2334 28.985C34.8467 28.7704 34.4182 28.6419 33.9772 28.6084C33.5362 28.5748 33.0932 28.637 32.6785 28.7907C32.2638 28.9444 31.8872 29.1859 31.5745 29.4987C31.7266 29.0579 31.7766 28.5884 31.7209 28.1255C31.6652 27.6626 31.5052 27.2184 31.253 26.8262C31.0007 26.4341 30.6628 26.1044 30.2646 25.8618C29.8664 25.6192 29.4184 25.4701 28.9543 25.4258C28.4889 25.4682 28.0394 25.6159 27.6395 25.8577C27.2397 26.0995 26.9001 26.429 26.6463 26.8214C26.3926 27.2137 26.2314 27.6587 26.175 28.1225C26.1185 28.5863 26.1683 29.0569 26.3205 29.4987C26.0066 29.1954 25.6315 28.9629 25.2203 28.8168C24.8091 28.6707 24.3713 28.6144 23.9365 28.6517C23.5017 28.689 23.0799 28.819 22.6996 29.0331C22.3192 29.2471 21.9892 29.5401 21.7316 29.8924C20.6184 31.7388 22.2068 34.2776 24.6234 34.3998C24.2705 34.6358 23.9715 34.9437 23.7459 35.3033C23.5203 35.6629 23.3731 36.0661 23.3142 36.4865C23.2552 36.9069 23.2857 37.335 23.4037 37.7428C23.5217 38.1506 23.7245 38.5289 23.9989 38.8528C24.4008 39.1807 24.8777 39.4038 25.387 39.5022C25.8962 39.6005 26.4219 39.571 26.917 39.4163C27.412 39.2616 27.861 38.9865 28.2237 38.6157C28.5863 38.2449 28.8513 37.7899 28.995 37.2915C29.1387 37.7899 29.4037 38.2449 29.7663 38.6157C30.129 38.9865 30.578 39.2616 31.073 39.4163C31.5681 39.571 32.0938 39.6005 32.6031 39.5022C33.1123 39.4038 33.5892 39.1807 33.9911 38.8528C34.2718 38.5272 34.4796 38.1454 34.6006 37.7328C34.7216 37.3203 34.7529 36.8867 34.6925 36.461C34.632 36.0354 34.4812 35.6276 34.2502 35.2651C34.0192 34.9026 33.7133 34.5936 33.353 34.359C35.7289 34.2369 37.3038 31.6845 36.2176 29.8517Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.9336 32.852C26.9336 33.3885 27.1467 33.903 27.5261 34.2824C27.9054 34.6617 28.42 34.8749 28.9565 34.8749C29.493 34.8749 30.0075 34.6617 30.3869 34.2824C30.7662 33.903 30.9794 33.3885 30.9794 32.852C30.9794 32.3155 30.7662 31.801 30.3869 31.4216C30.0075 31.0422 29.493 30.8291 28.9565 30.8291C28.42 30.8291 27.9054 31.0422 27.5261 31.4216C27.1467 31.801 26.9336 32.3155 26.9336 32.852Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
