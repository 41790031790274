import React from "react";
import { useTranslation } from "next-i18next";
import MessengerCard from "./MessengerCard";
import styles from "./OurMessengers.module.scss";
import cl from "classnames";
import { SvgFBMessenger, SvgTelegram } from "../../../icons";
import { SvgWhatappTransparent } from "@/lib/ui/icons";
import { H3 } from "@/lib/ui/components/Typography";

const messengers = [
  {
    url: "https://t.me/VillaCarte_sales",
    icon: <SvgTelegram className={cl(styles["messenger--wa"], styles.messenger__icon)} />,
    text: "Telegram",
  },
  {
    url: "https://api.whatsapp.com/send/?phone=66811032514",
    icon: (
      <SvgWhatappTransparent
        className={cl(styles["messenger__icon--wa"], styles.messenger__icon)}
      />
    ),
    text: "WhatsApp",
  },
  {
    url: "https://www.facebook.com/messages/t/429349140601777",
    icon: <SvgFBMessenger className={cl(styles["messenger__icon--fb"], styles.messenger__icon)} />,
    text: "Facebook",
  },
];
const OurMessengers = ({}) => {
  const { t } = useTranslation("index");
  return (
    <div className={styles.messenger}>
      <div className={styles.messenger__bg} />
      <div className={styles.messenger__wrapper} /* className={ 'ourMessengers' } */>
        <H3 className={styles.messenger__title}>
          {t("contactUsThroughOurMessengers")}
        </H3>

        <div className={styles.messenger__cards}>
          {messengers.map((item) => (
            <MessengerCard key={item.text} {...item} />
          ))}{" "}
        </div>
      </div>
    </div>
  );
};

export default OurMessengers;
