import { FC } from "react";
import { IClassname } from "@/lib/types/classname";

export const RubSign: FC<IClassname> = ({ className }) => {
  return (
    <svg
      className={className}
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.26758 16.9583V12.0972M8.26758 12.0972V5.5H13.4606C14.8114 5.5 15.9065 6.59505 15.9065 7.94586V9.65136C15.9065 11.0022 14.8114 12.0972 13.4606 12.0972H8.26758Z"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.25 14.2083L9.625 14.2083"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.41667 14.2083L8.25 14.2083"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.41667 11.9167L8.25 11.9167"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
