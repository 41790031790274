import { IClassname } from "@/lib/types/classname";
import { FC } from "react";

export const SvgPlay: FC<IClassname> = ({ className }) => {
  return (
    <svg
      className={className}
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_301_162)">
        <circle cx="21" cy="21" r="21" fill="white" fillOpacity="0.1" />
        <circle cx="21" cy="21" r="20" stroke="white" strokeWidth="2" />
      </g>
      <path
        d="M19.9974 18.0922C19.7911 17.9684 19.5376 17.9693 19.332 18.0946C19.1265 18.2198 19 18.4505 19 18.7V24.3C19 24.5495 19.1265 24.7802 19.332 24.9054C19.5376 25.0307 19.7911 25.0316 19.9974 24.9078L24.6641 22.1078C24.8718 21.9831 25 21.7512 25 21.5C25 21.2488 24.8718 21.0169 24.6641 20.8922L19.9974 18.0922Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_b_301_162"
          x="-4"
          y="-4"
          width="50"
          height="50"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_301_162"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_301_162"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
