import { IClassname } from "@/lib/types/classname";
import { FC } from "react";

export const SvgStories: FC<IClassname> = ({ className }) => {
  return (
    <svg
      className={className}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.5 40H39.5001"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.4994 18.4347H30.7385C30.5598 18.4347 30.3884 18.5057 30.262 18.6321C30.1356 18.7584 30.0646 18.9299 30.0646 19.1086V23.826H25.3472C25.2587 23.826 25.1711 23.8434 25.0893 23.8773C25.0075 23.9112 24.9332 23.9608 24.8707 24.0234C24.8081 24.086 24.7584 24.1603 24.7246 24.242C24.6907 24.3238 24.6733 24.4114 24.6733 24.4999V29.2173H19.9559C19.8674 29.2173 19.7797 29.2348 19.698 29.2686C19.6162 29.3025 19.5419 29.3521 19.4793 29.4147C19.4168 29.4773 19.3671 29.5516 19.3333 29.6334C19.2994 29.7151 19.282 29.8027 19.282 29.8912V34.6087H14.5645C14.3858 34.6087 14.2144 34.6797 14.088 34.806C13.9616 34.9324 13.8906 35.1038 13.8906 35.2826V40"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 23.8262L23.3262 9"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5859 9H23.3251V15.7392"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
