import { FC } from "react";
import { IClassname } from "@/lib/types/classname";

export const MinusIcon: FC<IClassname> = ({ className }) => {
  return (
    <svg
    className={className}
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9 13.5H18" stroke="currentColor" strokeWidth="1.6" />
    </svg>
  );
};
