import { FC } from "react";
import { IClassname } from "@/lib/types/classname";

export const SvgPlayTriangle: FC<IClassname> = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
    >
      <g id="Frame">
        <path
          id="Vector"
          d="M11.4974 10.0922C11.2911 9.96843 11.0376 9.96932 10.832 10.0946C10.6265 10.2198 10.5 10.4505 10.5 10.7V16.3C10.5 16.5495 10.6265 16.7802 10.832 16.9054C11.0376 17.0307 11.2911 17.0316 11.4974 16.9078L16.1641 14.1078C16.3718 13.9831 16.5 13.7512 16.5 13.5C16.5 13.2488 16.3718 13.0169 16.1641 12.8922L11.4974 10.0922Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
