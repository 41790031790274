import { GetStaticProps } from "next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useTranslation } from "next-i18next";
import Head from "next/head";
import { Error500 } from "@/lib/pages/Error500";
import { BASE_FRONT_URL } from "../lib/api/global";
import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale ?? "en", ["index"])),
      headerTheme: "light",
    },
  };
};

interface Error500Props {
  error?: Error | null;
  errorInfo?: any;
  hasReloaded: boolean;
  setReloaded: () => void;
}
export default function Custom500({
  error,
  errorInfo,
  hasReloaded,
  setReloaded,
}: Error500Props) {
  const { t } = useTranslation("index", { keyPrefix: "meta" });
  const [isLoading, setIsLoading] = useState(true);
  const router = useRouter();

  useEffect(() => {
    const handleRefresh = () => {
      if (!hasReloaded && setReloaded) {
        setReloaded();
        router.reload();
      }
      setTimeout(() => {
        setIsLoading(false);
      }, 250);
    };
    handleRefresh();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasReloaded, setReloaded]);
  if (isLoading) return null;
  return (
    <>
      <Head>
        <title>500</title>
        <meta property="og:title" content={"500"} />
        <meta property="og:keywords" content={t("metaKeywords")} />
        <meta property="og:image" content={"/images/og.png"} />
        <meta property="og:title" content={"500"} />
        <meta property="og:keywords" content={t("metaKeywords")} />
        <meta property="og:type" content="website" />
        <meta property="og:image:width" content="510" />
        <meta property="og:site_name" content="Villacarte" />
        <meta property="og:url" content={BASE_FRONT_URL} />
      </Head>
      <Error500 error={error} errorInfo={errorInfo} />
    </>
  );
}
