import { IClassname } from "@/lib/types/classname";
import { FC } from "react";

export const ListPointStarIcon: FC<IClassname> = ({ className }) => {
  return (
    <svg
      className={className}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 8.48486L20.0361 9.04889C20.4115 14.9104 25.2081 19.5946 31.25 20C25.2081 20.4054 20.4115 25.0897 20.0361 30.9511L20 31.5152L19.9639 30.9511C19.5885 25.0897 14.7919 20.4054 8.75 20C14.7919 19.5946 19.5885 14.9104 19.9639 9.04889L20 8.48486Z"
        fill="currentColor"
      />
    </svg>
  );
};
