import { FC } from "react";
import { IClassname } from "@/lib/types/classname";

export const SvgSecurity: FC<IClassname> = ({ className }) => {
  return (
    <svg
      className={className}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M20.0586 40V30.321" stroke="currentColor" strokeWidth="2" />
      <path
        d="M22.4231 13.2012C21.9027 13.8798 21.6202 14.7107 21.6191 15.5658C21.6191 16.611 22.0343 17.6134 22.7734 18.3525C23.5125 19.0916 24.5149 19.5068 25.5601 19.5068C26.6053 19.5068 27.6077 19.0916 28.3468 18.3525C29.0859 17.6134 29.5011 16.611 29.5011 15.5658C29.5091 14.7144 29.2378 13.8838 28.7287 13.2012H22.4231Z"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path
        d="M28.7287 13.2014H21.6349L19.8694 9.08702C19.8154 8.95521 19.7981 8.81123 19.8194 8.67038C19.8407 8.52954 19.8998 8.39709 19.9903 8.28713C20.0809 8.17718 20.1995 8.09381 20.3337 8.04591C20.4678 7.998 20.6124 7.98735 20.7522 8.01508L29.312 9.81216C29.4287 9.83801 29.538 9.89008 29.6317 9.96443C29.7253 10.0388 29.8008 10.1335 29.8524 10.2413C29.9041 10.3491 29.9305 10.4673 29.9297 10.5869C29.929 10.7064 29.901 10.8242 29.8479 10.9314L28.7287 13.2014Z"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path d="M21.6352 13.2012L18.4824 14.7776" stroke="currentColor" strokeWidth="2" />
      <path
        d="M20.0579 30.3053L15.6913 35.8226C15.3319 36.3318 14.7873 36.6798 14.1742 36.7918C13.5611 36.9038 12.9285 36.7709 12.4124 36.4217C12.1547 36.2463 11.9345 36.0215 11.7646 35.7602C11.5946 35.4989 11.4784 35.2065 11.4227 34.8998C11.3669 34.5932 11.3727 34.2785 11.4398 33.9741C11.5069 33.6698 11.6339 33.3818 11.8134 33.127L18.9071 23.6687C19.1253 23.3569 19.4155 23.1024 19.753 22.9267C20.0906 22.7511 20.4655 22.6595 20.8461 22.6598H22.7062C22.8431 22.6636 22.9769 22.7017 23.0953 22.7705C23.2137 22.8394 23.313 22.9368 23.384 23.0539L25.5752 26.6007L27.7034 23.0539C27.7744 22.9368 27.8737 22.8394 27.9921 22.7705C28.1105 22.7017 28.2443 22.6636 28.3812 22.6598H29.5162C30.7705 22.6598 31.9733 23.158 32.8602 24.0449C33.7471 24.9318 34.2454 26.1347 34.2454 27.3889V36.0591C34.2454 36.6862 33.9962 37.2876 33.5528 37.7311C33.1094 38.1745 32.5079 38.4237 31.8808 38.4237C31.2537 38.4237 30.6522 38.1745 30.2088 37.7311C29.7653 37.2876 29.5162 36.6862 29.5162 36.0591V28.1771"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path d="M42.128 39.9999H5.87109" stroke="currentColor" strokeWidth="2" />
    </svg>
  );
};
