import { FC } from "react";
import { IClassname } from "@/lib/types/classname";

export const SortDirectionIcon: FC<IClassname & { upsideDown?: boolean }> = ({
  className,
  upsideDown,
}) => {
  return (
    <svg
      className={className}
      style={{ scale: `1 ${upsideDown ? -1 : 1}` }}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 27.5L18 24.5M15 27.5L12 24.5M15 27.5V13M21.5 16.5H27.5M21.5 20.5H25.5M21.5 24.5H23.5"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="square"
      />
    </svg>
  );
};
