export * from "./SvgFacebook";
export * from "./SvgInstagram";
export * from "./SvgTelegram";
export * from "./SvgVk";
export * from "./SvgYoutube";
export * from "./SvgFBMessenger";
export * from "./SvgWhatsapp";
export * from "./SvgWhatappTransparent";
export * from './EmailIcon';
export * from './InstaSmallIcon';
export * from './YoutubeSmallIcon';
export * from './SvgFbMessengerFill';
export * from './SVGTelegramColor';
export * from './SVGWhatsappColor';
export * from './SVGFbMessengerColor';
