import { FC } from "react";
import { IClassname } from "@/lib/types/classname";

export const SvgCoworking: FC<IClassname> = ({ className }) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M14.9941 34.9751H33.5422"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.2324 35.2764V39.0004"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32 35.2007V39.0007"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.2483 23.4727H28.7517C28.7517 23.4727 30.1094 23.4727 30.1094 24.8303V30.2609C30.1094 30.2609 30.1094 31.6185 28.7517 31.6185H19.2483C19.2483 31.6185 17.8906 31.6185 17.8906 30.2609V24.8303C17.8906 24.8303 17.8906 23.4727 19.2483 23.4727Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.6129 31.6187C22.4907 32.9104 21.8862 34.1088 20.9199 34.9747"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.3828 31.6191C25.505 32.9109 26.1095 34.1092 27.0758 34.9752"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.2406 16.3594C13.1492 17.5258 12.6441 18.6211 11.8164 19.448"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5684 16.3594C15.6598 17.5258 16.1648 18.6211 16.9925 19.448"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.7484 16.3594C31.657 17.5258 31.1519 18.6211 30.3242 19.448"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.0781 16.3594C34.1695 17.5258 34.6746 18.6211 35.5023 19.448"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.06445 19.4478H39.1158"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.3047 19.7495V25.51"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.5742 19.6748V25.5099"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.7502 8.99902H37.0753C37.0753 8.99902 38.4329 8.99902 38.4329 10.3567V15.0011C38.4329 15.0011 38.4329 16.3588 37.0753 16.3588H28.7502C28.7502 16.3588 27.3926 16.3588 27.3926 15.0011V10.3567C27.3926 10.3567 27.3926 8.99902 28.7502 8.99902Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.2424 8.99951H18.5674C18.5674 8.99951 19.9251 8.99951 19.9251 10.3572V15.0016C19.9251 15.0016 19.9251 16.3593 18.5674 16.3593H10.2424C10.2424 16.3593 8.88477 16.3593 8.88477 15.0016V10.3572C8.88477 10.3572 8.88477 8.99951 10.2424 8.99951Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
