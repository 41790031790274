import { sendGTMEvent } from "@next/third-parties/google";

export const sendPhoneClick = () => {
    sendGTMEvent(
      {
        event: 'ga_event',
        eventName: 'tel_click_new',
        eventType: 'click'
      },
    );
  }

  export const sendEmailClick = () => {
    sendGTMEvent(
      {
        event: 'ga_event',
        eventName: 'mail_click_new',
        eventType: 'click'
      },
    );
  }

  export const sendSocialClick = () => {
    sendGTMEvent(
      {
        event: 'ga_event',
        eventName: 'social_click_new',
        eventType: 'click'
      },
    );
  }

  export const sendMessClick = () => {
    sendGTMEvent(
      {
        event: 'ga_event',
        eventName: 'messenger_click_new',
        eventType: 'click'
      },
    );
  }