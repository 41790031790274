import { FC, useId } from "react";
import { IClassname } from "@/lib/types/classname";

export const SvgGradient: FC<IClassname & { isMobile?: boolean }> = ({
  className,
  isMobile,
}) => {
  const id = useId();
  return (
    <svg
      className={className}
      width={isMobile ? "100" : "291"}
      viewBox={isMobile ? "0 0 100 26" : "0 0 291 26"}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {isMobile ? (
        <g clipPath={`url(#clip0_11466_22${id})`}>
          <rect
            x="-191"
            width="291"
            height="26"
            fill={`url(#paint0_linear_11466_22${id})`}
          />
        </g>
      ) : (
        <rect width="291" height="26" fill={`url(#paint0_linear_11466_22${id})`} />
      )}
      <defs>
        <linearGradient
          id={`paint0_linear_11466_22${id}`}
          x1={isMobile ? "100" : "291"}
          y1="0.764705"
          x2={isMobile ? "-191" : "-8.94587e-08"}
          y2="0.764671"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={isMobile ? "0.0898271" : "0.131785"} stopColor="white" />
          <stop
            offset={isMobile ? "0.231381" : "0.598912"}
            stopColor="white"
            stopOpacity="0"
          />
        </linearGradient>
        {isMobile && (
          <clipPath id={`clip0_11466_22${id}`}>
            <rect width="100" height="26" fill="white" />
          </clipPath>
        )}
      </defs>
    </svg>
  );
};
