import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import EventEmitter from "events";
import { IModal, IModalParams } from "./types";
import { Popup } from "./Popup";
import { useRouter } from "next/router";
import { useWindowResizeObserver } from "../../../hooks/useWindowResizeObserver";

const ev = new EventEmitter();

export const modal: IModal = {
  open: (component, props) => {
    ev.emit("open", { component, props });
  },
  close: () => {
    ev.emit("close");
  },
};

export const ModalConnector = () => {
  const router = useRouter();
  const [active, setActive] = useState(false);
  const [content, setContent] = useState<React.ReactNode>(null);
  const [props, setProps] = useState<IModalParams>({});
  const isBrowserBack = useRef<null | boolean>(null);
  const { isMobile } = useWindowResizeObserver();

  useEffect(() => {
    router.beforePopState(() => {
      isBrowserBack.current = true;
      ev.emit("close");
      return true;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hideZoho = useCallback(() => {
    if (!window.$zoho || !isMobile) return;
    window?.$zoho?.salesiq?.floatbutton?.visible("hide");
  }, [isMobile]);

  const showZoho = useCallback(() => {
    if (!window.$zoho || !isMobile) return;
    window?.$zoho?.salesiq?.floatbutton?.visible("show");
  }, [isMobile]);

  const openModal = useMemo(() => {
    return ({
      component,
      props,
    }: {
      component: React.ReactNode;
      props: IModalParams;
    }) => {
      setContent(component);
      setProps(props);
      setActive(true);
      hideZoho();
      router.push(
        {
          query: {
            ...router.query,
            modal: true,
          },
        },
        undefined,
        {
          scroll: false,
          shallow: true,
        },
      );
    };
  }, [hideZoho, router]);

  const closeModal = useMemo(
    () => () => {
      setActive(false);
      showZoho();
      if (!isBrowserBack.current) {
        delete router.query.modal;
        router.replace(
          {
            query: {
              ...router.query,
            },
          },
          undefined,
          {
            scroll: false,
            shallow: true,
          },
        );
      }

      isBrowserBack.current = null;
      return setActive;
    },
    [router, showZoho],
  );

  useEffect(() => {
    ev.on("open", openModal);
    ev.on("close", closeModal);

    return () => {
      ev.off("open", openModal);
      ev.off("close", closeModal);
    };
  }, [openModal, closeModal, showZoho, hideZoho]);

  return (
    <Popup
      show={active}
      isNotClose={props?.isNotClose}
      onClose={() => closeModal()}
      {...props}
    >
      {content}
    </Popup>
  );
};
