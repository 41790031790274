import { FC } from 'react';
import { IClassname } from '@/lib/types/classname';

export const SVGTelegramColor: FC<IClassname> = ({ className }) => {
    return (
        <svg className={className} width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M24.4448 48C37.6997 48 48.4448 37.2548 48.4448 24C48.4448 10.7452 37.6997 0 24.4448 0C11.19 0 0.444824 10.7452 0.444824 24C0.444824 37.2548 11.19 48 24.4448 48Z" fill="url(#paint0_linear_9119_1804)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M11.3098 23.7518C18.3063 20.7035 22.9717 18.6939 25.3061 17.723C31.9712 14.9508 33.3561 14.4692 34.2588 14.4533C34.4573 14.4498 34.9012 14.499 35.1888 14.7323C35.4316 14.9293 35.4984 15.1955 35.5304 15.3823C35.5623 15.5691 35.6021 15.9946 35.5705 16.3271C35.2093 20.122 33.6465 29.3314 32.8514 33.5818C32.515 35.3803 31.8525 35.9834 31.2112 36.0424C29.8175 36.1706 28.7592 35.1213 27.4093 34.2365C25.297 32.8518 24.1036 31.9899 22.0533 30.6387C19.6837 29.0772 21.2198 28.219 22.5702 26.8164C22.9236 26.4493 29.0644 20.8638 29.1832 20.3571C29.1981 20.2938 29.2119 20.0575 29.0716 19.9328C28.9312 19.8081 28.7241 19.8507 28.5747 19.8847C28.3628 19.9327 24.9884 22.1631 18.4513 26.5758C17.4935 27.2335 16.6259 27.554 15.8486 27.5372C14.9917 27.5187 13.3433 27.0527 12.1179 26.6543C10.6149 26.1658 9.42036 25.9075 9.52438 25.0777C9.57856 24.6456 10.1737 24.2036 11.3098 23.7518Z" fill="white"/>
<defs>
<linearGradient id="paint0_linear_9119_1804" x1="24.4448" y1="0" x2="24.4448" y2="47.644" gradientUnits="userSpaceOnUse">
<stop stopColor="#2AABEE"/>
<stop offset="1" stopColor="#229ED9"/>
</linearGradient>
</defs>
</svg>
)}

