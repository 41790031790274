import { combineReducers } from '@reduxjs/toolkit';
import { settingsReducer } from '../slices/settings';
import { mapReducer } from '../slices/map';
import { catalogReducer } from '../slices/catalog';
import { filteredLayoutsReducer } from '../slices/filteredLayouts/slice';
import { currencyReducer } from '../slices/currency';

const appReducer = combineReducers({
  settings: settingsReducer,
  map: mapReducer,
  catalog: catalogReducer,
  filteredLayouts: filteredLayoutsReducer,
  currency: currencyReducer,
});

export default appReducer;
