import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import { useTranslation } from "next-i18next";
import { MobileMainMenu, MainMenu } from "../../components/Menu";
import Button from "../../components/Buttons/Button/Button";
import { Logo } from "../../components/Logo";
import { modal } from "../../components/Modal";
import styles from "./Header.module.scss";
import { useObserver } from "@/lib/hooks/useObserver";
import { useAppSelector } from "@/lib/store/app";
import { LanguageSelect, CurrencySelect } from "@/lib/ui/components/Selects";
import { useRouter } from "next/router";
import { CircleButton } from "../../components/Buttons";
import { BurgerButtonIcon, CloseSign } from "../../icons";
import { FeedBackModalNew } from "../../components/Modal/FeedBackModalNew";

interface IProps {
  headerTheme: "dark" | "light";
}

export const Header: React.FC<IProps> = ({ headerTheme }) => {
  const { t } = useTranslation("index");
  const [scrolled, setScrolled] = useState<boolean>(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const observerRef = useRef<HTMLDivElement>();
  const router = useRouter();

  useEffect(() => {
    const el = document?.querySelector("#header-observer");

    if (el) {
      observerRef.current = el as HTMLDivElement;
    }
  }, []);
  const isFixedFilterInCatalog = useAppSelector((state) => state.catalog.isFixedFilter) && router.asPath.includes('/sale/catalog');
  const forceHeaderStyle = useAppSelector((state) => state.settings.forceHeaderStyle);

  useEffect(() => {
    if (forceHeaderStyle === "default") {
      setScrolled(false);
    } else setScrolled(scrolled);
  }, [forceHeaderStyle, scrolled]);

  const toCallModal = () => {
    modal.open(
      <FeedBackModalNew 
        title='getExpert' 
        sourceForm="request-call-header" 
        eventName={['header']}/>, 
      {isFeedBackNew: true});
  };

  const openMenu = () => {
    setIsMenuOpen(true);
    document.body.classList.add("scroll-disabled");
  };
  const closeMenu = () => {
    setIsMenuOpen(false);
    document.body.classList.remove("scroll-disabled");
  };
  useObserver({
    ref: observerRef,
    isInterTrue: () => setScrolled(false),
    isInterFalse: () => setScrolled(true),
  });
  

  const selectorTheme = scrolled ? "light" : headerTheme === "dark" ? "light" : "dark";

  return (
    <>
      <header
        id="main-header"
        className={cn(
          "header header-container",
          isFixedFilterInCatalog && styles["header--white"],
        )}
        style={
          {
            "--headerColor": headerTheme === "dark" || scrolled ? "#FFFFFF" : "#22364A",
            transition: "color 150ms",
          } as any
        }
      >
        {isFixedFilterInCatalog && <div className={styles.header__white} />}
        <div
          id="main-header-wrapper"
          ref={ref}
          className={cn("header-wrapper", scrolled && "header-wrapper_scrolled")}
        >
          <div className={cn(styles.header_nav, scrolled && styles.header_nav_scrolled)}>
            <Logo
              size="sm"
              color={headerTheme === "dark" || scrolled ? "#FFFFFF" : "#22364A"}
            />
            <MainMenu scrolled={scrolled} />
          </div>

          <div className={styles.header_nav}>
            <div className={styles.header_selectWrapper}>
              <LanguageSelect
                theme={selectorTheme}
                menuStyles={cn(scrolled && styles.selectScrolledMenu)}
                label="language select"
              />
              <CurrencySelect
                theme={selectorTheme}
                menuStyles={cn(scrolled && styles.selectScrolledMenu)}
                label="currency select"
              />
            </div>
            <div
              className={cn(
                "d-flex ai-center",
                styles.header_mobile__buttons,
                scrolled ? styles["mobile__buttons--scrolled"] : "",
                isMenuOpen ? styles["mobile__buttons--open"] : "",
              )}
            >
              <div className={styles.header_requestACall}>
                <Button
                  as="button"
                  label={t("form.buttons.requestACall")}
                  theme={headerTheme === "dark" || scrolled ? "light-invert" : "dark"}
                  onClick={toCallModal}
                  size={"md"}
                  type="button"
                />
              </div>
              <div className={styles.header_burgerButton}>
              <CircleButton 
                as="button"
                theme={headerTheme === "dark" || scrolled ? "light" : "mixed"}
                onClick={openMenu} 
                name="menu button"
                content={isMenuOpen ? (
                  <CloseSign />
                ) : (
                  <BurgerButtonIcon/>
                )}
              />
              </div>
            </div>
          </div>
        </div>
        <MobileMainMenu closeMenu={closeMenu} isMenuOpen={isMenuOpen} />
      </header>
    </>
  );
};
