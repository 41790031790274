import React from "react";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import { H4 } from "@/lib/ui/components/Typography";
import { PDF } from "../../../types/pdf";
import { ContactFormTeam } from "../Forms/ContactFormTeam";
import styles from './QuestionFormModalNew.module.scss';
import { LeadType } from "../../../types/lead-type";

interface Props {
  title?: string;
  subTitle?: string;
  agreementText?: string;
  buttonLabel?: string;
  leadType?: LeadType;
  url?: string;
  comment?: string;
  sourceForm?: string;
  pdf?: PDF;
  eventName: string[];
  value: string;
}

export const QuestionFormModalNew = ({
  value,
  title = "sendYourQuestion",
}: Props) => {
  const { t } = useTranslation("index", { keyPrefix: "form" });
  return (
    <div className={styles.questionModal}>
      <div className={styles.questionModal__leaf}>
        <Image
          sizes="494px"
          src={"/leaves/feedbackLeaf.webp"}
          alt={""}
          className={styles.questionModal__leafImg}
          fill
        />
      </div>
      <H4 className={styles.questionModal__title}>{t(`titles.${title}`)}</H4>
      <div className={styles.questionModal__content}>
        <ContactFormTeam isModal employee={value} eventName={['others']}/>
      </div>
    </div>
  );
};
