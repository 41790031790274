import { useRouter } from "next/router";
import { BASE_FRONT_URL, isDevelopment } from "../../../api/global";
import Script from "next/script";
import { useCallback, useState } from "react";
import { sendGTMEvent } from "@next/third-parties/google";
import { useInterval } from "../../../hooks/useInterval";

export const Salesiq = () => {
  const router = useRouter();

  const sourcePage = `${BASE_FRONT_URL}/${router.locale}${router.asPath}`;

  const [delay, setDelay] = useState<null | 1000>(1000);
  const [tooltipDelay, setTooltipDelay] = useState<null | 1000>(1000);

  const handleChatWidgetClick = useCallback(() => {
    sendGTMEvent({
      event: "ga_event",
      eventName: "online_chat_open",
      eventType: "click",
      pageUrl: sourcePage,
    });
  }, [sourcePage]);

  const handleZoho = useCallback(() => {
    if (!window.$zoho?.salesiq?.chatbutton) return;
    window.$zoho?.salesiq?.chatbutton?.click(function () {
      handleChatWidgetClick();
    });
    setDelay(null);
  }, [handleChatWidgetClick]);

  const handleMobileTooltip = useCallback(() => {
    if (!window.$zoho?.salesiq?.floatbutton?.coin) return;
    window.$zoho.salesiq?.floatbutton?.coin?.hidetooltip();
    setTooltipDelay(null);
  }, []);

  useInterval(handleZoho, delay);
  useInterval(handleMobileTooltip, tooltipDelay);

  if (isDevelopment) return null;

  return (
    <Script
      id="zsiqchat"
      type="text/javascript"
      strategy="lazyOnload"
    >{`var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "siq91270abf0b6040256cb24fafafc98b6e0228d910529e26aa51534d541c705fae", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="https://salesiq.zohopublic.com/widget";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t); `}</Script>
  );
};
