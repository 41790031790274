export const downloadLink = (href: string, filename?: string) => {
  const link = document.createElement("a");

  link.href = href;
  link.target = "_blank";
  if (filename) {
    link.download = filename;
  }
  link.click();
  link.remove();
};
