import { FC } from 'react';
import { IClassname } from '@/lib/types/classname';

export const SvgArea: FC<IClassname> = ({ className }) => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.3043 9H37.6957C37.6957 9 39 9 39 10.3043V37.6957C39 37.6957 39 39 37.6957 39H10.3043C10.3043 39 9 39 9 37.6957V10.3043C9 10.3043 9 9 10.3043 9Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M12.999 33.9978C12.999 34.5501 13.4467 34.9978 13.999 34.9978H22.999C23.5513 34.9978 23.999 34.5501 23.999 33.9978C23.999 33.4455 23.5513 32.9978 22.999 32.9978H14.999V24.9978C14.999 24.4455 14.5513 23.9978 13.999 23.9978C13.4467 23.9978 12.999 24.4455 12.999 24.9978V33.9978ZM34.9969 14C34.9969 13.4477 34.5491 13 33.9969 13H24.9969C24.4446 13 23.9969 13.4477 23.9969 14C23.9969 14.5523 24.4446 15 24.9969 15H32.9969V23C32.9969 23.5523 33.4446 24 33.9969 24C34.5491 24 34.9969 23.5523 34.9969 23V14ZM14.7061 34.7049L34.704 14.7071L33.2897 13.2929L13.2919 33.2907L14.7061 34.7049Z" fill="currentColor"/>
</svg>
)}
