import { FC } from "react";
import { IClassname } from "@/lib/types/classname";

export const PlusIcon: FC<IClassname> = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
        <g id="Frame">
        <path id="Vector" d="M13.5 9V18M9 13.5H18" stroke="currentColor" strokeWidth="1.6"/>
        </g>
    </svg>
  );
};