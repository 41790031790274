import { FC } from "react";
import { IClassname } from "@/lib/types/classname";

export const SortDefaultIcon: FC<IClassname> = ({ className }) => {
  return (
    <svg
      className={className}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.5 16H13M23 20H13M19.5 24H13"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="square"
      />
    </svg>
  );
};
