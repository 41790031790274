import Head from "next/head";
import React, { ReactNode, useEffect } from "react";
import { ModalConnector } from "@/lib/ui/components/Modal";
import styles from "./Layout.module.scss";
import { Footer } from "@/lib/ui/layout/Footer";
import { Header } from "@/lib/ui/layout/Header";
import cn from "classnames";
import { useUtmCookies } from "@/lib/utils/useUtmTags";
import { ReloadTranslations } from "../../components/ReloadTranslations";
import { useTestAccessCookies } from "../../../utils/useTestAccessCookies";
import { useCurrencyActions } from "../../../store/slices/currency";
interface IProps {
  children: ReactNode;
  headerTheme: "dark" | "light";
  className: string;
  isWhiteBg?: boolean;
}

export function Layout({ children, headerTheme, className, isWhiteBg }: IProps) {
  useUtmCookies();
  const isAuth = useTestAccessCookies();
  const { updateRates } = useCurrencyActions();

  useEffect(() => {
    updateRates()
  }, [updateRates])

  return (
    <div
      className={cn(styles.layout, className, isWhiteBg && styles["layout--white-bg"])}
    >
      <Head>
        <meta
          content="width=device-width,initial-scale=1,maximum-scale=5"
          name="viewport"
        />
        <meta name="google-site-verification" content="k0QLpEI0k_K0QLyqNBcAi2ljFuivvv1bhIaDxr4BpeQ" />
        <meta name="msapplication-config" content="/favicons/browserconfig.xml" />
        <link rel="icon" href="/favicon.ico" type="image/x-icon" />
        <link rel="icon" type="image/svg+xml" href="/favicon.svg" sizes="any" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/favicons/favicon-180x180.png"
        />
        <link rel="manifest" href="/favicons/manifest.json" />
      </Head>
      <div className={styles.observer} id="header-observer" />
      {isAuth && <Header headerTheme={headerTheme} />}
      {/* Needs to be exactly here */}
      <div id="modal" />

      <main>{children}</main>
      <ReloadTranslations />
      {isAuth && <Footer />}
      <ModalConnector />
    </div>
  );
}
