import {
  DetailedHTMLProps,
  FC,
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
} from "react";
import styles from "./Input.module.scss";
import { CloseSign, SearchIcon } from "../../icons";
import cl from "classnames";
import { useRouter } from "next/router";
import { useAppSelector } from "@/lib/store/app";
import { getFilteredProperties } from "@/lib/hooks/useFilteredProperties";
import { useTranslation } from "next-i18next";
import { P2 } from "../Typography";
import { FeedBackModal, modal } from "../Modal";
import { Button } from "../Buttons";
import useOnClickOutside from "@/lib/hooks/useOnClickOutside";
interface ISearchInput
  extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {}

export const SearchInput: FC<ISearchInput> = ({ className, ...props }) => {
  const [isEmptyResult, setIsEmptyResult] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { t } = useTranslation("index");
  const properties = useAppSelector((state) => state.catalog.properties);
  const currency = useAppSelector((state) => state.settings.currency.value);
  const router = useRouter();
  const query = router.query.q;
  const [value, setValue] = useState((query as string) || "");

  const emptyResultText = t("emptyResult");

  const navigate = (isClear?: boolean) => {
    const prefilteredProperties = getFilteredProperties(
      properties,
      { q: decodeURIComponent(value).trim() },
      currency,
    );
    if (value === emptyResultText) {
      setValue("");
      inputRef.current && inputRef.current.blur();
      return setIsEmptyResult(false);
    }
    if (prefilteredProperties.length === 0 && value !== "" && !isClear) {
      setValue(emptyResultText);
      inputRef.current && inputRef.current.blur();
      return setIsEmptyResult(true);
    }
    const newQuery = { q: encodeURIComponent(value.trim()) } as Record<
      "q",
      string | undefined
    >;

    if (value === "" || isClear) {
      delete newQuery.q;
    }
    router.push({ pathname: router.pathname, query: { ...newQuery } }, undefined, {
      shallow: true,
    });
    inputRef.current && inputRef.current.blur();
  };

  const resetQuery = () => {
    setValue(() => "");
    setIsEmptyResult(false);
    navigate(true);
  };

  const openModal = () => {
    modal.open(<FeedBackModal leadType="SALE" eventName={['sale']}/>, { isFeedBack: true });
    setIsEmptyResult(false);
  };

  useEffect(() => {
    setValue(query === undefined ? "" : decodeURIComponent(query as string));
  }, [query]);

  const emptyResultRef = useRef<HTMLDivElement | null>(null);
  useOnClickOutside(emptyResultRef, () => isEmptyResult && setIsEmptyResult(false));

  return (
    <div className={styles.search__wrapper}>
      <input
        value={value}
        ref={inputRef}
        onChange={(e) => {
          if (isEmptyResult) {
            const lastSymbol =
              e.target.value === emptyResultText.slice(0, -1)
                ? ""
                : e.target.value.slice(-1);
            setIsEmptyResult(false);
            return setValue(() => lastSymbol);
          }
          setValue(() => e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            navigate();
          }
        }}
        onFocus={(e) => {
          if (isEmptyResult || e.target.value === emptyResultText) {
            setValue("");
          }
        }}
        onBlur={(e) => {
          if (isEmptyResult && e.target.value.length === 0) {
            setValue(emptyResultText);
          }
        }}
        name="q"
        className={cl(styles.search__input, className)}
        type="text"
        {...props}
      />
      <div className={styles.search__icons}>
        {!isEmptyResult && value !== "" && (
          <SearchIcon className={styles.search__icon} onClick={() => navigate()} />
        )}
        {(value || (isEmptyResult && value !== emptyResultText)) && (
          <button className={styles.search__reset} onClick={() => resetQuery()}>
            <CloseSign />
          </button>
        )}
      </div>
      {isEmptyResult && (
        <div className={styles.search__popup} ref={emptyResultRef}>
          <P2>{t("callManager")}</P2>
          <Button
            name="call manager"
            as="button"
            label={t("form.buttons.requestACall")}
            theme={"dark"}
            onClick={openModal}
            size={"md"}
            type="button"
          />
        </div>
      )}
    </div>
  );
};
