import "@/styles/index.scss";
import React from "react";
import nextI18nextConfig from "../next-i18next.config";
import { appWithTranslation } from "next-i18next";
import { AppProps } from "next/app";
import { Provider } from "react-redux";
import { Layout } from "@/lib/ui/layout";
import store from "@/lib/store/app/store";
import { useRouter } from "next/router";
import {
  Manrope,
  Noto_Sans_Thai,
  Noto_Serif_Thai,
  Noto_Sans,
  Noto_Serif,
} from "next/font/google";
import cn from "classnames";
import localFont from "next/font/local";
import { GTM } from "../lib/ui/components/GTM/GTM";
import { ErrorBoundary } from "../lib/ui/blocks/common/ErrorBoundary";
import { Salesiq } from "../lib/ui/components/Salesiq";

const manrope = Manrope({
  subsets: ["cyrillic", "latin"],
  variable: "--font-manrope",
  display: "swap",
  preload: true,
});
const tttSarsA = localFont({
  src: [
    {
      path: "../public/fonts/TTTsarsA/TTTsarsA-Light.ttf",
      weight: "300",
      style: "normal",
    },
  ],
  preload: true,
  variable: "--font-tttSarsA",
  display: "swap",
});
const notoSans = Noto_Sans_Thai({ subsets: ["thai"], variable: "--font-sans-thai" });
const sans = Noto_Sans({
  subsets: ["latin", "cyrillic"],
  variable: "--font-sans",
  weight: ["300", "400", "700"],
});
const notoSerif = Noto_Serif_Thai({ subsets: ["thai"], variable: "--font-serif-thai" });
const serif = Noto_Serif({ subsets: ["latin", "cyrillic"], variable: "--font-serif" });

function App({ Component, pageProps }: AppProps) {
  const router = useRouter();

  if (router.pathname === "/500") return <Component {...pageProps} />;
  const isWhiteBg =
    router.pathname.includes("catalog") || router.pathname.includes("selection");

  return (
    <Provider store={store}>
      <ErrorBoundary>
        <Salesiq />
        <GTM />
        <Layout
          className={cn(
            manrope.variable,
            tttSarsA.variable,
            notoSans.variable,
            notoSerif.variable,
            sans.variable,
            serif.variable,
          )}
          headerTheme={pageProps.headerTheme}
          isWhiteBg={isWhiteBg}
        >
          <Component {...pageProps} />
        </Layout>
      </ErrorBoundary>
    </Provider>
  );
}

export default appWithTranslation(App, nextI18nextConfig);
