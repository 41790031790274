import React, { FC } from "react";
import styles from "./SocialNetworks.module.scss";
import Link from "next/link";
import cl from "classnames";
import { SvgFacebook, SvgInstagram, SvgTelegram, SvgYoutube } from "../../icons";
import { IClassname } from "@/lib/types/classname";
import { sendSocialClick } from "@/lib/utils/sendGTM";

const links = [
  { icon: <SvgYoutube isGradient />, link: "https://www.youtube.com/channel/UCt5bJSvlrU8q7IGuO1LHjkg", name: 'youtube' },
  { icon: <SvgInstagram isGradient />, link: "https://www.instagram.com/villacarte.vip/", name: 'instagram' },
  { icon: <SvgFacebook isGradient />, link: "https://www.facebook.com/villacarte/timeline/", name: 'facebook' },
  { icon: <SvgTelegram isGradient />, link: "https://t.me/villacarte", name: 'telegram' },
];

interface ILogo extends IClassname {
  size?: "m" | "l";
  color?: "gray" | "blue";
}

export const SocialNetworks: FC<ILogo> = ({ className, size = "m", color = "gray" }) => {
  return (
    <div className={cl(styles.main, className, styles[color], styles[`size-${size}`])}>
      {links.map((social) => (
        <Link 
          aria-label={`${social.name} link`} 
          href={social.link} 
          key={social.link} 
          target="_blank" 
          onClick={sendSocialClick}
        >
          {social.icon}
        </Link>
      ))}
    </div>
  );
};
