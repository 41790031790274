import { FC } from "react";
import { IClassname } from "@/lib/types/classname";

export const DollarSign: FC<IClassname> = ({ className }) => {
  return (
    <svg 
      className={className}
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 13.1C7 14.5359 8.07452 15.7 9.4 15.7H12.6C13.9255 15.7 15 14.5359 15 13.1C15 11.6641 13.9255 10.5 12.6 10.5H9.4C8.07452 10.5 7 9.33594 7 7.9C7 6.46406 8.07452 5.3 9.4 5.3H12.6C13.9255 5.3 15 6.46406 15 7.9M11 4V5.3M11 17V15.7"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="square"
      />
    </svg>
  );
};
