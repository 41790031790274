import { FC } from "react";
import { IClassname } from "@/lib/types/classname";

export const SvgVideoSurveillance: FC<IClassname> = ({ className }) => {
  return (
    <svg
      className={className}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.4775 18.8618C27.4775 19.0538 27.4012 19.238 27.2654 19.3738C27.1296 19.5096 26.9454 19.5859 26.7534 19.5859H12.271C12.0789 19.5859 11.8948 19.5096 11.759 19.3738C11.6232 19.238 11.5469 19.0538 11.5469 18.8618V11.6206C11.5469 10.6603 11.9283 9.73944 12.6073 9.06045C13.2863 8.38145 14.2072 8 15.1675 8H38.3392C38.4492 8.00003 38.5578 8.02512 38.6566 8.07336C38.7555 8.1216 38.842 8.19172 38.9097 8.27841C38.9774 8.36509 39.0245 8.46607 39.0474 8.57366C39.0702 8.68125 39.0683 8.79263 39.0416 8.89935L37.9207 13.3889C37.8897 13.5128 37.8265 13.6262 37.7374 13.7178C37.6484 13.8094 37.5368 13.8758 37.4138 13.9103L27.4775 16.6894V18.8618Z"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path
        d="M33.2714 15.0675V21.7584C33.2714 21.9504 33.1951 22.1346 33.0593 22.2704C32.9235 22.4062 32.7393 22.4825 32.5473 22.4825H15.8926C15.1244 22.4825 14.3876 22.1773 13.8445 21.6341C13.3013 21.0909 12.9961 20.3542 12.9961 19.586"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path d="M36.166 17.4135V20.31" stroke="currentColor" strokeWidth="2" />
      <path d="M17.3398 25.379H23.1328" stroke="currentColor" strokeWidth="2" />
      <path
        d="M20.2365 25.379V32.8808C20.2369 33.2149 20.1218 33.5389 19.9106 33.7978C19.6995 34.0568 19.4053 34.2347 19.0779 34.3015L12.416 35.6332"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path d="M12.0547 31.3105V39.9999" stroke="currentColor" strokeWidth="2" />
    </svg>
  );
};
