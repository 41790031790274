import { IClassname } from "@/lib/types/classname";
import { FC } from "react";

export const InstaSmallIcon: FC<IClassname> = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
        <g id="Instagram Logo">
        <path id="Vector" d="M9.0012 11.9333C10.6212 11.9333 11.9345 10.62 11.9345 8.99998C11.9345 7.37995 10.6212 6.06665 9.0012 6.06665C7.38117 6.06665 6.06787 7.37995 6.06787 8.99998C6.06787 10.62 7.38117 11.9333 9.0012 11.9333Z" 
        stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_2" d="M11.9335 3.13306H6.06688C4.44684 3.13306 3.13354 4.44635 3.13354 6.06639V11.9331C3.13354 13.5531 4.44684 14.8664 6.06688 14.8664H11.9335C13.5536 14.8664 14.8669 13.5531 14.8669 11.9331V6.06639C14.8669 4.44635 13.5536 3.13306 11.9335 3.13306Z" 
        stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
        <g id="Group">
        <path id="Vector_3" d="M12.6667 6.06634C12.4642 6.06634 12.3 5.90218 12.3 5.69967C12.3 5.49717 12.4642 5.33301 12.6667 5.33301" 
        stroke="currentColor" strokeWidth="1.6"/>
        <path id="Vector_4" d="M12.6672 6.0661C12.8697 6.0661 13.0339 5.90193 13.0339 5.69943C13.0339 5.49693 12.8697 5.33276 12.6672 5.33276" 
        stroke="currentColor" strokeWidth="1.6"/>
        </g>
        </g>
    </svg>
  );
};