import { FC } from "react";

interface ILinearGradient {
  id: string;
  isDefaultGradient?: boolean;
  isDark?: boolean;
}
export const LinearGradient: FC<ILinearGradient> = ({ id, isDefaultGradient, isDark }) => {
  return (
    <linearGradient id={"blueGradient" + id} gradientUnits="userSpaceOnUse">
      <stop offset="5%" stopColor={isDefaultGradient ? "#7ea2bc" : isDark ? "#22364A" : "#848C98"} />
      <stop offset="95%" stopColor={isDefaultGradient ? "#4072a3" : isDark ? "#22364A" : "#848C98"} />
    </linearGradient>
  );
};
