import React from "react";
import { IValueLabel } from "@villa/types/mainPage";
import { useRouter } from "next/router";
import { CustomSelect, ICustomSelect } from "./CustomSelect";
import { useTranslation } from "next-i18next";
import { useWindowSize } from "@/lib/hooks/useWindowSize";

interface IProps extends Omit<ICustomSelect, "options"> {}

export const LanguageSelect = (props: IProps) => {
  const { i18n } = useTranslation();
  const { language: currentLocale } = i18n;
  const router = useRouter();
  const locales = router.locales ?? [currentLocale];
  const { isMobile } = useWindowSize(1199);

  const handleChange = (selectedOption: IValueLabel) => {
    router.push(
      {
        pathname: router.pathname,
        query: router.query,
      },
      router.asPath,
      { locale: selectedOption.value as string, scroll: false },
    );
  };

  const localeOptions = locales.reduce((a: { label: string; value: string }[], b) => {
    if (b === "default") {
      return a;
    }
    a.push({ value: b, label: b.toUpperCase() });
    return a;
  }, []);

  return (
    <div>
      <CustomSelect
        {...props}
        options={localeOptions}
        defaultValue={localeOptions.find((locale) => locale.value === currentLocale)}
        onChange={handleChange}
        isMobile={isMobile}
        isMenuSelect
      />
    </div>
  );
};

export default LanguageSelect;
