import { FC } from "react";
import { IClassname } from "@/lib/types/classname";

export const SvgGolf: FC<IClassname> = ({ className }) => {
  return (
    <svg
      className={className}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.7264 36.5459H17.4537M20.1809 36.5459H17.4537M17.4537 19.0913H18.4126C19.8942 19.0914 21.3151 19.68 22.3627 20.7277C23.4111 21.775 24.8315 22.364 26.3129 22.364H28.3627V14.7277H27.4038C25.9224 14.7277 24.5009 14.1386 23.4537 13.0913C22.4053 12.044 20.9849 11.455 19.5035 11.455H17.4537V36.5459M35.9991 31.0913C35.9991 31.0913 32.1809 26.7277 23.4537 26.7277C22.0573 26.7277 20.7853 26.839 19.6355 27.0277M10.9082 31.0913C10.9082 31.0913 12.2838 29.5193 15.2718 28.2615M27.2718 34.364C27.2718 34.9427 27.5017 35.4977 27.9109 35.9068C28.3201 36.316 28.875 36.5459 29.4537 36.5459C30.0323 36.5459 30.5873 36.316 30.9964 35.9068C31.4056 35.4977 31.6355 34.9427 31.6355 34.364C31.6355 33.7854 31.4056 33.2304 30.9964 32.8213C30.5873 32.4121 30.0323 32.1822 29.4537 32.1822C28.875 32.1822 28.3201 32.4121 27.9109 32.8213C27.5017 33.2304 27.2718 33.7854 27.2718 34.364Z"
        stroke="currentColor"
        strokeWidth="2"
      />
    </svg>
  );
};
