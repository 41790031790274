import React, { FC } from "react";
import { IClassname } from "../../types/classname";

export const BigPlayIcon: FC<IClassname & { onClick: () => void }> = ({ className, onClick }) => {
  return (
    <svg
      className={className}
      width="120"
      height="120"
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <circle
        cx="60"
        cy="60"
        r="58.0952"
        fill="white"
        fillOpacity="0.1"
        stroke="white"
        strokeWidth="3.80952"
      />
      <path
        d="M57.1351 51.6912C56.5455 51.3375 55.8213 51.3401 55.234 51.6979C54.6467 52.0558 54.2853 52.7148 54.2853 53.4277V69.4277C54.2853 70.1407 54.6467 70.7997 55.234 71.1576C55.8213 71.5154 56.5455 71.5179 57.1351 71.1642L70.4684 63.1642C71.0619 62.8081 71.4281 62.1454 71.4281 61.4277C71.4281 60.71 71.0619 60.0473 70.4684 59.6912L57.1351 51.6912Z"
        fill="white"
      />
    </svg>
  );
};
