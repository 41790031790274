export function compareArray(a?: any[], b?: any[]) {
  if (!a || !b) return false;
  if (a.length !== b.length) return false;

  a = [...a].sort();
  b = [...b].sort();

  for (let i = 0; i < a.length; i++) {
    if (a[i] !== b[i]) return false;
  }

  return true;
}
