import { FC } from 'react';
import { IClassname } from '@/lib/types/classname';

export const SvgLagoon: FC<IClassname> = ({ className }) => {
    return (
        <svg className={className} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13 8.52941C13 8.389 12.9473 8.25435 12.8536 8.15506C12.7598 8.05578 12.6326 8 12.5 8H4.5C4.36739 8 4.24021 8.05578 4.14645 8.15506C4.05268 8.25435 4 8.389 4 8.52941V25.4706C4 25.611 4.05268 25.7457 4.14645 25.8449C4.24021 25.9442 4.36739 26 4.5 26H13V8.52941Z" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M5 8V6.4C5 6.29391 5.05268 6.19217 5.14645 6.11716C5.24021 6.04214 5.36739 6 5.5 6H11.5C11.6326 6 11.7598 6.04214 11.8536 6.11716C11.9473 6.19217 12 6.29391 12 6.4V8" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M28 25.4643C28 25.6064 27.9473 25.7426 27.8536 25.8431C27.7598 25.9436 27.6326 26 27.5 26H21V11.5357C21 11.3936 21.0527 11.2574 21.1464 11.1569C21.2402 11.0564 21.3674 11 21.5 11H27.5C27.6326 11 27.7598 11.0564 27.8536 11.1569C27.9473 11.2574 28 11.3936 28 11.5357V25.4643Z" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M22 11V9.5C22 9.36739 22.0527 9.24021 22.1464 9.14645C22.2402 9.05268 22.3674 9 22.5 9H26.5C26.6326 9 26.7598 9.05268 26.8536 9.14645C26.9473 9.24021 27 9.36739 27 9.5V11" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M13 14H21" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M13 26H21" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M6 11H11" stroke="currentColor" strokeWidth="1.6" strokeLinecap="square" strokeLinejoin="round"/>
<path d="M6 14H11" stroke="currentColor" strokeWidth="1.6" strokeLinecap="square" strokeLinejoin="round"/>
<path d="M6 18H11" stroke="currentColor" strokeWidth="1.6" strokeLinecap="square" strokeLinejoin="round"/>
<path d="M23 14H26" stroke="currentColor" strokeWidth="1.6" strokeLinecap="square" strokeLinejoin="round"/>
<path d="M23 18H26" stroke="currentColor" strokeWidth="1.6" strokeLinecap="square" strokeLinejoin="round"/>
<path d="M6 21H11" stroke="currentColor" strokeWidth="1.6" strokeLinecap="square" strokeLinejoin="round"/>
<path d="M23 21H26" stroke="currentColor" strokeWidth="1.6" strokeLinecap="square" strokeLinejoin="round"/>
<path d="M15 18H19" stroke="currentColor" strokeWidth="1.6" strokeLinecap="square" strokeLinejoin="round"/>
<path d="M15 21H19" stroke="currentColor" strokeWidth="1.6" strokeLinecap="square" strokeLinejoin="round"/>
</svg>
)}
