import { IClassname } from "@/lib/types/classname";
import { FC, useId } from "react";
import { LinearGradient } from "./socials/LinearGradient";

export const HeadIcon: FC<IClassname & {isGradient?: boolean, isDark?: boolean}> = ({className, isGradient, isDark}) => {
  const id = useId();
  const stroke = isGradient ? `url('#blueGradient${id}')` : "currentColor";
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
        {isGradient && <LinearGradient id={id} isDark={isDark} />}
        <g id="Medical Condition Head Dizziness">
        <g id="Group">
        <path id="Vector" d="M12.6331 19.033C11.8724 20.6079 11.4777 22.3344 11.4783 24.0834C11.4783 27.9651 12.7166 31.3877 15.6522 33.5304V39.9999" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_2" d="M28.1739 40V35.8261H30.2609C30.7176 35.8261 31.1699 35.7362 31.5919 35.5614C32.0139 35.3866 32.3974 35.1304 32.7204 34.8074C33.3727 34.1551 33.7391 33.2704 33.7391 32.3479V27.4783H36.953C37.0631 27.4775 37.1713 27.4506 37.2689 27.3998C37.3665 27.349 37.4507 27.2758 37.5145 27.1862C37.5784 27.0966 37.62 26.9931 37.6361 26.8842C37.6521 26.7754 37.6422 26.6643 37.607 26.56C36.8802 24.1835 35.9924 21.8593 34.9496 19.6035" 
        stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_3" d="M11.9235 9.6001C9.90612 10.2957 8.69569 11.1862 8.69569 12.1462C8.69569 13.8436 12.3409 15.0401 15.6522 15.6523" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_4" d="M27.3392 8.09739C26.2679 8.02783 25.1549 8 24.0001 8C22.6088 8 21.2175 8 19.9375 8.15304" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_5" d="M39.1374 11.4783C38.0854 10.3203 36.6738 9.55077 35.1305 9.29395" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_6" d="M23.9166 16.292C27.1859 16.3233 30.4498 16.0156 33.6557 15.3738" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <g id="Group_2">
        <path id="Vector_7" d="M30.9566 10.7826V8" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_8" d="M32.3479 9.39136H29.5653" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <g id="Group_3">
        <path id="Vector_9" d="M16.3479 10.7826V8" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_10" d="M17.7392 9.39136H14.9566" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <g id="Group_4">
        <path id="Vector_11" d="M19.8261 17.7392V14.9565" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_12" d="M21.2174 16.3479H18.4348" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <g id="Group_5">
        <path id="Vector_13" d="M37.9131 16.3478V13.5652" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_14" d="M39.3044 14.9565H36.5218" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        </g>
        </g>
    </svg>
  );
};
