import { IClassname } from "@/lib/types/classname";
import { FC } from "react";

export const CloseSign: FC<IClassname> = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path d="M1 1L13 13M1 13L13 1" stroke="currentColor" strokeWidth="1.9" />
    </svg>
  );
};
