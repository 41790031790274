import React, { FC } from "react";

interface IPopupCloseButton {
  className: string;
  onClick: () => void;
}
export const PopupCloseButton: FC<IPopupCloseButton> = ({ className, onClick }) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_2090_1724)">
        <circle cx="21" cy="21" r="21" fill="white" fillOpacity="0.1" />
        <circle cx="21" cy="21" r="20.2" stroke="currentColor" strokeWidth="1.6" />
      </g>
      <path d="M15 27L27 15M27 27L15 15" stroke="currentColor" strokeWidth="1.6" />
      <defs>
        <filter
          id="filter0_b_2090_1724"
          x="-4"
          y="-4"
          width="50"
          height="50"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_2090_1724"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_2090_1724"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
