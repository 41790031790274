import { FC } from "react";
import { IClassname } from "@/lib/types/classname";

export const SvgHill: FC<IClassname> = ({ className }) => {
  return (
    <svg
      className={className}
      width="47"
      height="29"
      viewBox="0 0 47 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 25.0874L9 16C10.0417 15.1667 11.3359 14.7127 12.6699 14.7127C14.0039 14.7127 15.2981 15.1667 16.3398 16L27.4375 27.4374"
        stroke="white"
        strokeWidth="2"
      />
      <path
        d="M22 22L30.3319 17.2934C31.2383 16.8053 32.257 16.5639 33.286 16.5935C34.315 16.623 35.3182 16.9225 36.1951 17.4618L46.0416 23.5209"
        stroke="white"
        strokeWidth="2"
      />
      <path
        d="M24.5 5.89583C24.5 7.19429 25.0158 8.43956 25.934 9.35771C26.8521 10.2759 28.0974 10.7917 29.3958 10.7917C30.6943 10.7917 31.9396 10.2759 32.8577 9.35771C33.7759 8.43956 34.2917 7.19429 34.2917 5.89583C34.2917 4.59738 33.7759 3.3521 32.8577 2.43396C31.9396 1.51581 30.6943 1 29.3958 1C28.0974 1 26.8521 1.51581 25.934 2.43396C25.0158 3.3521 24.5 4.59738 24.5 5.89583Z"
        stroke="white"
        strokeWidth="2"
      />
    </svg>
  );
};
