import { FC } from "react";
import { IClassname } from "@/lib/types/classname";

export const SvgSwimming: FC<IClassname> = ({ className }) => {
  return (
    <svg
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.66669 26.666C5.41813 26.666 6.1388 26.3728 6.67016 25.851C7.20151 25.3292 7.50002 24.6214 7.50002 23.8834C7.50002 24.6214 7.79853 25.3292 8.32988 25.851C8.86124 26.3728 9.58191 26.666 10.3334 26.666C11.0848 26.666 11.8055 26.3728 12.3368 25.851C12.8682 25.3292 13.1667 24.6214 13.1667 23.8834C13.1667 24.6214 13.4652 25.3292 13.9966 25.851C14.5279 26.3728 15.2486 26.666 16 26.666C16.7515 26.666 17.4721 26.3728 18.0035 25.851C18.5348 25.3292 18.8334 24.6214 18.8334 23.8834C18.8334 24.6214 19.1319 25.3292 19.6632 25.851C20.1946 26.3728 20.9152 26.666 21.6667 26.666C22.4181 26.666 23.1388 26.3728 23.6702 25.851C24.2015 25.3292 24.5 24.6214 24.5 23.8834C24.5 24.6214 24.7985 25.3292 25.3299 25.851C25.8612 26.3728 26.5819 26.666 27.3334 26.666M7.50002 7.65152V7.18775C7.50002 6.69576 7.69903 6.22391 8.05326 5.87602C8.4075 5.52812 8.88794 5.33268 9.38891 5.33268C9.88987 5.33268 10.3703 5.52812 10.7246 5.87602C11.0788 6.22391 11.2778 6.69576 11.2778 7.18775V16.4631C11.2778 18.1206 11.6556 20.1491 12.7464 20.893C13.0033 21.0683 13.3263 21.1008 13.6389 21.1008M16.9445 7.65152V7.18775C16.9445 6.69576 17.1435 6.22391 17.4977 5.87602C17.8519 5.52812 18.3324 5.33268 18.8334 5.33268C19.3343 5.33268 19.8148 5.52812 20.169 5.87602C20.5232 6.22391 20.7222 6.69576 20.7222 7.18775V16.4631C20.7222 18.1206 21.1 20.1491 22.1909 20.893C22.4477 21.0683 22.7707 21.1008 23.0834 21.1008M11.2778 13.6805H20.7222M11.2778 9.97036H20.7222M11.3165 17.3907H20.761"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
