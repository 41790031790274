import { FC } from "react";
import { IClassname } from "@/lib/types/classname";

export const SvgKidsRooms: FC<IClassname> = ({ className }) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_651_1142)">
        <path
          d="M15.3359 32.4173C16.0222 30.6467 17.2044 29.1116 18.7409 27.9958C20.2774 26.8799 22.103 26.2307 23.999 26.126C25.8913 26.2118 27.7149 26.8604 29.2365 27.9887C30.758 29.1169 31.9082 30.6736 32.5399 32.4594"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.5974 31.2078C12.624 28.5867 14.2802 26.2586 16.4197 24.4291L14.496 17.3015L12.0522 18.1161C11.2975 18.2583 10.517 18.1122 9.86472 17.7069C9.21246 17.3015 8.73593 16.6663 8.52924 15.9267C8.32255 15.1871 8.40073 14.3968 8.74834 13.712C9.09595 13.0273 9.68772 12.4977 10.4068 12.228L14.7431 10.2703C15.399 9.97521 16.1453 9.95241 16.818 10.2069C17.4907 10.4615 18.035 10.9725 18.3313 11.628L20.6053 16.6227H27.3935C28.8338 16.6227 30.2151 17.1949 31.2335 18.2133C32.2519 19.2317 32.8241 20.613 32.8241 22.0533V25.6171C33.7011 25.9809 35.4959 28.7003 36.4028 31.2133"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M31.4414 18.4351L38.8202 22.6193"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.6035 16.6226L21.8254 21.5386C21.862 21.6855 21.9467 21.8159 22.0661 21.9092C22.1854 22.0024 22.3324 22.0531 22.4838 22.0531H26.1766C26.328 22.0531 26.4751 22.0024 26.5944 21.9092C26.7137 21.8159 26.7984 21.6855 26.8351 21.5386L28.057 16.6633"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24.0098 39.0317C19.256 39.039 14.5432 38.1525 10.117 36.4182C9.44516 36.155 8.90535 35.6357 8.61637 34.9745C8.3274 34.3133 8.31292 33.5643 8.57613 32.8924C8.83934 32.2206 9.35867 31.6807 10.0199 31.3918C10.6811 31.1028 11.43 31.0883 12.1019 31.3515C19.7564 34.3201 28.2438 34.3225 35.9 31.3583C36.5688 31.1097 37.3086 31.1337 37.9598 31.4254C38.6111 31.717 39.1217 32.2528 39.3817 32.9173C39.6417 33.5818 39.6302 34.3219 39.3497 34.978C39.0692 35.6341 38.5421 36.1538 37.8821 36.425C33.462 38.1549 28.7563 39.0391 24.0098 39.0317Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.0762 10.1524L17.414 9.21152C18.243 8.8799 19.1694 8.88832 19.9922 9.23495C20.8151 9.58157 21.4682 10.2385 21.8101 11.0633L24.118 16.6297"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.8179 14.2467C13.7279 14.2467 13.6416 14.2109 13.5779 14.1473C13.5143 14.0836 13.4785 13.9973 13.4785 13.9073C13.4785 13.8173 13.5143 13.7309 13.5779 13.6673C13.6416 13.6036 13.7279 13.5679 13.8179 13.5679"
          stroke="currentColor"
          strokeWidth="2"
        />
        <path
          d="M13.8164 14.2467C13.9064 14.2467 13.9928 14.2109 14.0564 14.1473C14.1201 14.0836 14.1558 13.9973 14.1558 13.9073C14.1558 13.8173 14.1201 13.7309 14.0564 13.6673C13.9928 13.6036 13.9064 13.5679 13.8164 13.5679"
          stroke="currentColor"
          strokeWidth="2"
        />
      </g>
      <defs>
        <clipPath id="clip0_651_1142">
          <rect width="34" height="34" fill="white" transform="translate(7 7)" />
        </clipPath>
      </defs>
    </svg>
  );
};
