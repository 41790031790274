import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { isDevelopment, isLocal } from "../api/global";

export const useTestAccessCookies = () => {
  const [cookie] = useCookies(["test_access"]);
  const [isAuth, setIsAuth] = useState(true);
  const test_access = cookie.test_access;
  const router = useRouter();

  useEffect(() => {
    if (!router.isReady || !isDevelopment || isLocal || router.pathname.includes('selection/pdf')) return;

    if (
      !test_access ||
      (test_access && test_access !== process.env.NEXT_PUBLIC_TEST_TOKEN)
    ) {
      setIsAuth(false);
      if (router.pathname !== "/access-denied") {
        router.push({ pathname: "/access-denied" }, undefined, {
          shallow: true,
          scroll: false,
        });
      }
    }
    if (router.pathname === "/get-cookie") {
      setTimeout(() => {
        router.push({ pathname: "/" }, undefined, {
          shallow: true,
          scroll: false,
        });
      }, 1000);
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.isReady, test_access]);

  return isAuth;
};
