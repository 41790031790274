/**
 * Пример использования:
 * 
 * const { t, language } = useTranslation(namespace)
 * 
 * const words = [t('символ'), t('символа'), t('символов')]
 *
 * getWordEnding(15, words, language)
 *
 * Значения заменить на те, которые используются в ключах перевода
 * */
export const getWordEnding = (num: number, words: string[], lang: "ru" | "en" | string) => {
  if (lang === "en") {
    return num > 1 ? words[1] : words[0];
  }
  const cases = [2, 0, 1, 1, 1, 2];
  return words[
    num % 100 > 4 && num % 100 < 20 ? 2 : cases[num % 10 < 5 ? num % 10 : 5]
  ];
};
