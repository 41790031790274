import { FC } from "react";
import { IClassname } from "@/lib/types/classname";

export const SvgOwning: FC<IClassname> = ({ className }) => {
  return (
    <svg
    className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
    >
      <path
        d="M13.1667 39H34.8333C36.0299 39 37 38.0406 37 36.8571V15.4286L30.5 9H13.1667C11.97 9 11 9.95939 11 11.1429V36.8571C11 38.0406 11.97 39 13.1667 39Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M23.5642 17.1582L17.7634 22.0241C17.6065 22.1722 17.5121 22.3762 17.5 22.5934V29.3049C17.5 29.4892 17.5721 29.666 17.7004 29.7964C17.8287 29.9268 18.0027 30 18.1842 30H22.2895V24.7866C22.2895 24.6944 22.3255 24.606 22.3897 24.5408C22.4538 24.4756 22.5408 24.439 22.6316 24.439H25.3684C25.4592 24.439 25.5462 24.4756 25.6103 24.5408C25.6745 24.606 25.7105 24.6944 25.7105 24.7866V30H29.8158C29.9973 30 30.1713 29.9268 30.2996 29.7964C30.4279 29.666 30.5 29.4892 30.5 29.3049V22.5955C30.4879 22.3783 30.3935 22.1743 30.2366 22.0262L24.4358 17.1603C24.3134 17.0571 24.1595 17.0004 24.0004 17C23.8413 16.9996 23.687 17.0556 23.5642 17.1582Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  );
};
