import { FC } from "react";
import { IClassname } from "@/lib/types/classname";

export const SvgView: FC<IClassname> = ({ className }) => {
  return (
    <svg
    className={className}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.2946 28.8152L24.9758 25.4157C23.9581 24.5051 22.6565 24.0039 21.3098 24.0039C19.9631 24.0039 18.6615 24.5051 17.6438 25.4157L11 32.2336"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M33.8237 24.7978C33.5282 24.3912 33.1451 24.0612 32.7047 23.8339C32.2644 23.6066 31.7788 23.4883 31.2864 23.4883C30.7941 23.4883 30.3085 23.6066 29.8681 23.8339C29.4277 24.0612 29.0446 24.3912 28.7491 24.7978L26.8848 27.3635"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M35.4706 39L12.5294 39C12.1238 39 11.7348 38.8336 11.448 38.5375C11.1611 38.2414 11 37.8398 11 37.4211V10.5789C11 10.1602 11.1611 9.75857 11.448 9.46246C11.7348 9.16635 12.1238 9 12.5294 9H35.4706C35.8762 9 36.2652 9.16635 36.552 9.46246C36.8389 9.75857 37 10.1602 37 10.5789V37.4211"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M11 32.6836L36.518 32.6836"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M25.2461 15.7386C25.2461 16.1519 25.3249 16.5611 25.4781 16.9429C25.6313 17.3247 25.8558 17.6716 26.1389 17.9638C26.4219 18.256 26.7579 18.4878 27.1277 18.6459C27.4976 18.8041 27.8939 18.8855 28.2942 18.8855C28.6945 18.8855 29.0909 18.8041 29.4607 18.6459C29.8305 18.4878 30.1665 18.256 30.4496 17.9638C30.7326 17.6716 30.9571 17.3247 31.1103 16.9429C31.2635 16.5611 31.3423 16.1519 31.3423 15.7386C31.3423 14.904 31.0212 14.1036 30.4496 13.5135C29.8779 12.9233 29.1026 12.5918 28.2942 12.5918C27.4858 12.5918 26.7105 12.9233 26.1389 13.5135C25.5672 14.1036 25.2461 14.904 25.2461 15.7386Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
