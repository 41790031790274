import { FC } from "react";
import { IClassname } from "@/lib/types/classname";

export const DownIcon: FC<IClassname> = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M10.1816 13.0908L16.1816 19.0908L22.1816 13.0908"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="square"
      />
    </svg>
  );
};
