import { FC } from "react";
import { IClassname } from "@/lib/types/classname";

export const SvgJacuzzi: FC<IClassname> = ({ className }) => {
  return (
    <svg
      className={className}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40.7137 19.6666H7.28516V23.3809H40.7137V19.6666Z"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.4758 23.3809H8.52344V36.9999H39.4758V23.3809Z"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.332 19.6666V17.8095C28.332 16.1009 29.7187 14.7142 31.4273 14.7142C33.1358 14.7142 34.5225 16.1009 34.5225 17.8095V19.6666"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.0469 19.6666V17.8095C32.0469 16.1009 33.4335 14.7142 35.1421 14.7142C36.8507 14.7142 38.2374 16.1009 38.2374 17.8095V19.6666"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.7617 34.5238V37"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.2852 34.5238V37"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="17" y="6" width="2" height="2" rx="1" fill="currentColor" />
      <rect
        x="10"
        y="8"
        width="4"
        height="4"
        rx="2"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="19"
        y="12"
        width="3"
        height="3"
        rx="1.5"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="24" y="8" width="2" height="2" rx="1" fill="currentColor" />
      <rect x="12" y="15" width="2" height="2" rx="1" fill="currentColor" />
    </svg>
  );
};
