import { RefObject, useEffect, useRef } from "react";

interface IUseObserverProps {
  ref?: RefObject<any>;
  isInterTrue?: () => void;
  isInterFalse?: () => void;
  isOnce?: boolean;
  options?: Partial<IntersectionObserverInit>;
}

export const useObserver = ({
  ref,
  isInterTrue,
  isInterFalse,
  isOnce,
  options,
}: IUseObserverProps) => {
  const observer = useRef<IntersectionObserver>();

  useEffect(() => {
    if (!ref) return;
    if (observer.current) observer.current.disconnect();

    const cb = function (
      entries: IntersectionObserverEntry[],
      observer: IntersectionObserver,
    ) {
      if (entries[0].isIntersecting) {
        if (isInterTrue) isInterTrue();
        if (isOnce) observer.disconnect();
      } else {
        if (isInterFalse) isInterFalse();
      }
    };
    observer.current = new IntersectionObserver(cb, options);
    if (ref.current instanceof Element) {
      observer.current.observe(ref.current as Element);
    }
  });
};
