import { FC } from "react";
import { IClassname } from "@/lib/types/classname";

export const SvgManagement: FC<IClassname> = ({ className }) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_651_1096)">
        <path
          d="M29.8555 30.8303C29.8555 31.3477 30.061 31.844 30.4269 32.2098C30.7928 32.5757 31.289 32.7812 31.8064 32.7812C32.3238 32.7812 32.82 32.5757 33.1859 32.2098C33.5518 31.844 33.7573 31.3477 33.7573 30.8303C33.7573 30.3129 33.5518 29.8167 33.1859 29.4508C32.82 29.0849 32.3238 28.8794 31.8064 28.8794C31.289 28.8794 30.7928 29.0849 30.4269 29.4508C30.061 29.8167 29.8555 30.3129 29.8555 30.8303Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M33.4647 23.2804L34.0403 25.1703C34.1347 25.4841 34.3448 25.7504 34.6282 25.915C34.9116 26.0796 35.2469 26.1303 35.5663 26.0568L37.4833 25.6129C37.8464 25.531 38.2261 25.5676 38.5668 25.7173C38.9076 25.867 39.1914 26.1219 39.3768 26.4446C39.5621 26.7673 39.6392 27.1409 39.5968 27.5107C39.5544 27.8804 39.3946 28.2269 39.141 28.4992L37.7996 29.9451C37.5783 30.1857 37.4554 30.5006 37.4554 30.8276C37.4554 31.1545 37.5783 31.4695 37.7996 31.71L39.141 33.1559C39.3946 33.4283 39.5544 33.7747 39.5968 34.1444C39.6392 34.5142 39.5621 34.8878 39.3768 35.2105C39.1914 35.5333 38.9076 35.7881 38.5668 35.9378C38.2261 36.0875 37.8464 36.1241 37.4833 36.0423L35.5663 35.5969C35.2471 35.5263 34.9129 35.5792 34.6311 35.745C34.3492 35.9107 34.1406 36.177 34.0471 36.4903L33.4715 38.3801C33.3643 38.7364 33.1452 39.0487 32.8466 39.2708C32.5481 39.4928 32.1859 39.6127 31.8138 39.6127C31.4417 39.6127 31.0795 39.4928 30.7809 39.2708C30.4824 39.0487 30.2633 38.7364 30.1561 38.3801L29.5737 36.4903C29.4788 36.1768 29.2685 35.911 28.9853 35.7464C28.7021 35.5819 28.367 35.5309 28.0477 35.6037L26.1307 36.049C25.7677 36.1309 25.3879 36.0943 25.0472 35.9446C24.7064 35.7949 24.4226 35.54 24.2372 35.2173C24.0519 34.8946 23.9748 34.521 24.0172 34.1512C24.0597 33.7815 24.2194 33.4351 24.473 33.1627L25.8144 31.7168C26.0357 31.4762 26.1586 31.1613 26.1586 30.8344C26.1586 30.5074 26.0357 30.1925 25.8144 29.9519L24.473 28.506C24.2194 28.2336 24.0597 27.8872 24.0172 27.5175C23.9748 27.1477 24.0519 26.7741 24.2372 26.4514C24.4226 26.1287 24.7064 25.8738 25.0472 25.7241C25.3879 25.5744 25.7677 25.5378 26.1307 25.6197L28.0477 26.0636C28.3671 26.1371 28.7024 26.0864 28.9858 25.9218C29.2692 25.7571 29.4793 25.4909 29.5737 25.1771L30.1493 23.2872C30.2557 22.9307 30.4742 22.6179 30.7723 22.3953C31.0705 22.1727 31.4324 22.052 31.8045 22.0513C32.1766 22.0505 32.539 22.1697 32.838 22.3911C33.1371 22.6125 33.3568 22.9243 33.4647 23.2804Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.123 14.1567C12.123 15.687 12.731 17.1546 13.813 18.2367C14.8951 19.3187 16.3627 19.9266 17.893 19.9266C19.4233 19.9266 20.8909 19.3187 21.973 18.2367C23.0551 17.1546 23.663 15.687 23.663 14.1567C23.663 12.6264 23.0551 11.1588 21.973 10.0767C20.8909 8.99462 19.4233 8.38672 17.893 8.38672C16.3627 8.38672 14.8951 8.99462 13.813 10.0767C12.731 11.1588 12.123 12.6264 12.123 14.1567Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.6055 31.4667H8.38672C8.38665 29.7603 8.84603 28.0854 9.71662 26.6178C10.5872 25.1502 11.8369 23.944 13.3344 23.126C14.832 22.308 16.5222 21.9083 18.2275 21.9689C19.9328 22.0294 21.5904 22.5481 23.0261 23.4702"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_651_1096">
          <rect width="34" height="34" fill="white" transform="translate(7 7)" />
        </clipPath>
      </defs>
    </svg>
  );
};
