import { FC, ReactNode, useRef, useState } from "react";
import styles from './QuestionFormModal.module.scss';
import { useObserver } from "@/lib/hooks/useObserver";
import { CircleButton } from "../Buttons";
import cn from 'classnames';
import { ArrowIcon, CloseSign } from "../../icons";
import { modal } from "@/lib/ui/components/Modal";


interface QuestionFormModalProps {
    content: ReactNode;
    button: ReactNode;
    withScroller?: boolean;
  }
  
  export const QuestionFormModal: FC<QuestionFormModalProps> = ({content, withScroller, button }) => {
    const [isScrollerVisible, setIsScrollerVisible] = useState<boolean>();
    const endRef = useRef<HTMLDivElement | null>(null);
    useObserver({
      ref: endRef,
      isInterTrue: () => {
        setIsScrollerVisible(false);
      },
      isInterFalse: () => {
        setIsScrollerVisible(true);
      },
    });
  
    const onScrollerClick = () => {
      if (endRef.current) {
        endRef.current.scrollIntoView({ behavior: "smooth" });
      }
    };
  
    return (
      <div className={styles.questionFormModal}>
        <div className={styles.questionFormModal__content}>
          <div className={styles.questionFormModal__header}>
            <CircleButton 
                as="button"
                theme="mixed"
                onClick={() => modal.close()}
                name="return button"
                content={<ArrowIcon />}
                className={styles.closeBtnMobile}
              />
            <CircleButton
                as="button"
                theme="light"
                className={styles.closeBtnDesctop}
                content={<CloseSign />}
                onClick={() => modal.close()}
            />
          </div>
          <div className={styles.questionFormModal__body}>
            {content}
            <div ref={endRef} />
            {withScroller && (
            <div
                onClick={onScrollerClick}
                className={cn(styles.scroller, !isScrollerVisible && styles.hidden)}
            />
            )}
          </div>
         <div className={styles.questionFormModal__footer}>
            {button}
         </div>
        </div>
      </div>
    );

  };