import { ChangeEvent, ReactNode, forwardRef } from "react";
import cl from "classnames";
import styles from "./Input.module.scss";
import { P2 } from "../Typography";
import { Button } from "../Buttons";
interface ICheckbox {
  isChecked?: boolean;
  disableInteraction?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  readOnly?: boolean;
  label?: ReactNode;
  name?: string;
  value?: string | number;
  isPaleUncheckedLabel?: boolean;
  isButton?: boolean;
  id?: string;
  className?: string;
  onMouseEnter?: (id: string) => void;
  onMouseLeave?: (id: string) => void;
  size?: "md" | "lg";
  theme?: "light" | "dark";
  isDisabled?: boolean;
  includeMediaText?: boolean;
}
export const Checkbox = forwardRef<HTMLInputElement, ICheckbox>(
  (
    {
      isChecked,
      onChange,
      disableInteraction,
      label,
      readOnly = true,
      value,
      name,
      isPaleUncheckedLabel,
      isButton,
      id,
      className,
      onMouseEnter,
      onMouseLeave,
      size = "lg",
      theme,
      isDisabled,
      includeMediaText = true,
    },
    ref,
  ) => {
    return isButton ? (
      <Button
        as="button"
        theme="grayBorder"
        label=""
        type="button"
        size="md"
        className={cl(
          styles.button,
          (isDisabled || disableInteraction) && styles["button--disabled"],
          isChecked && styles["button--active"],
          className,
        )}
        disabled={isDisabled}
        onMouseEnter={() => onMouseEnter && id && onMouseEnter(id)}
        onMouseLeave={() => onMouseLeave && id && onMouseLeave(id)}
      >
        <span
          className={cl(
            styles.checkbox__inputWrapper,
            styles[`checkbox__inputWrapper--${size}`],
          )}
        >
          <input
            type="checkbox"
            className={styles.checkbox__input}
            checked={isChecked}
            onChange={onChange}
            readOnly={readOnly}
            name={name}
            ref={ref}
            id={id}
            value={value}
          />
          <svg
            className={cl(
              styles.checkbox__checkmark,
              styles[`checkbox__checkmark--${theme}`],
            )}
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M5 9L8.42857 12L13 6" stroke="white" strokeWidth="2" />
          </svg>
        </span>
        {label && (
          <P2
            disabled={isDisabled}
            id={id}
            onMouseDown={(e) => e.preventDefault()}
            includeMediaText={includeMediaText}
            className={cl(
              styles.checkbox__label,
              isPaleUncheckedLabel && !isChecked && styles["checkbox__label--unchecked"],
            )}
          >
            {label}
          </P2>
        )}
      </Button>
    ) : (
      <label
        className={cl(
          styles.checkbox,
          disableInteraction && styles["checkbox--disabled"],
          className,
        )}
        onMouseEnter={() => onMouseEnter && id && onMouseEnter(id)}
        onMouseLeave={() => onMouseLeave && id && onMouseLeave(id)}
      >
        <span
          className={cl(
            styles.checkbox__inputWrapper,
            styles[`checkbox__inputWrapper--${size}`],
          )}
        >
          <input
            type="checkbox"
            className={styles.checkbox__input}
            checked={isChecked}
            onChange={onChange}
            readOnly={readOnly}
            name={name}
            ref={ref}
            id={id}
            value={value}
            disabled={isDisabled}
          />
          <svg
            className={cl(
              styles.checkbox__checkmark,
              styles[`checkbox__checkmark--${theme}`],
            )}
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M5 9L8.42857 12L13 6" stroke="white" strokeWidth="2" />
          </svg>
        </span>
        {label && (
          <P2
            disabled={isDisabled}
            id={id}
            includeMediaText={includeMediaText}
            onMouseDown={(e) => e.preventDefault()}
            className={cl(
              styles.checkbox__label,
              isPaleUncheckedLabel && !isChecked && styles["checkbox__label--unchecked"],
              isDisabled && styles["checkbox__label--disabled"],
            )}
          >
            {label}
          </P2>
        )}
      </label>
    );
  },
);

Checkbox.displayName = "Checkbox";
