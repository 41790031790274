import { FC } from "react";
import { IClassname } from "@/lib/types/classname";

export const SvgTennis: FC<IClassname> = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
    >
      <path
        d="M26.9594 27.9569C23.9362 28.2965 20.9512 28.9167 18.043 29.8094C18.9307 26.8964 19.5409 23.9059 19.8654 20.8779"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.0279 29.8101L13.2383 34.5996"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.2021 24.4779C32.7891 28.891 26.1168 29.373 22.2912 25.5473C18.4655 21.7217 18.9475 15.0042 23.3605 10.6364C27.7736 6.2685 34.491 5.74134 38.2715 9.56698C42.0519 13.3926 41.6152 20.0649 37.2021 24.4779Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.3477 8.69971L39.2454 20.5974"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.248 13.7988L34.1458 25.6966"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.996 7.93938L20.0176 22.9179"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.2444 12.5548L23.9473 27.8517"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.6236 40.5638C9.48358 40.7049 9.317 40.817 9.13346 40.8935C8.94992 40.9699 8.75306 41.0093 8.55423 41.0093C8.3554 41.0093 8.15853 40.9699 7.97499 40.8935C7.79145 40.817 7.62487 40.7049 7.48485 40.5638C7.20433 40.2816 7.04688 39.8998 7.04688 39.5019C7.04687 39.104 7.20433 38.7223 7.48485 38.4401L11.7473 34.1777C11.8145 34.1053 11.8962 34.0478 11.987 34.0089C12.0777 33.97 12.1757 33.9505 12.2744 33.9517C12.3755 33.9503 12.4757 33.9696 12.569 34.0084C12.6623 34.0473 12.7466 34.1049 12.8167 34.1777L13.871 35.232C13.9415 35.302 13.9976 35.3853 14.0358 35.477C14.074 35.5688 14.0937 35.6672 14.0937 35.7667C14.0937 35.8661 14.074 35.9645 14.0358 36.0563C13.9976 36.148 13.9415 36.2313 13.871 36.3013L9.6236 40.5638Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
